import { Button } from "@arco-design/web-react";
import { useNavigate } from "react-router-dom";


export function NavigationButton(props) {
  const navigate = useNavigate();
  let url;
  if (props.uuid) {
    url = `/${props.url}/${props.uuid}`;
  } else {
    url = props.url;
  }
  return(
    <Button
      onClick={() => {navigate(url, {state: {uuid: props.uuid}})}}
      type={props.type}
      status={props.status}
      size={props.size}
    >
      {props.text}
    </Button>
  );
}

