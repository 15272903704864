import React from 'react';
import { Steps, Card, Form, Divider, DatePicker, Input, InputNumber, Button, Layout, Grid, Message, Statistic } from "@arco-design/web-react";
import {IconLoading, IconUserGroup, IconUser, IconIdcard, IconClose, IconCheck, IconDashboard } from "@arco-design/web-react/icon";
import { PaymentMethodForm } from '../billing/PaymentMethodForm';
import { createTrainer } from '../../api/users';
import {loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { setPaymentMethod, setSubscriptionClients } from '../../api/billing';
import { NavigationButton } from '../common/Navigation';

const STATE_PENDING = "PENDING";
const STATE_PROCESSING = "PROCESSING";
const STATE_ERROR = "ERROR";
const STATE_COMPLETE = "COMPLETE";

const STAGE_ACCOUNT = 1;
const STAGE_PAYMENT = 2;
const STAGE_SUBSCRIPTION = 3;
const STAGE_PROFILE = 4;
const STAGE_SETUP_COMPLETE = 5;


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);



export default class SignUpPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStage: STAGE_ACCOUNT,
      accountSectionState: STATE_PENDING,
      paymentSectionState: STATE_PENDING,
      subscriptionSectionState: STATE_PENDING,
      profileSectionState: STATE_PENDING,

      numberOfClients: 1,
    };
    this.moveToNextStage = this.moveToNextStage.bind(this);
    this.moveToPreviousStage = this.moveToPreviousStage.bind(this);

    this.handleAccountStageSubmit = this.handleAccountStageSubmit.bind(this);
    this.handlePaymentSubmit = this.handlePaymentSubmit.bind(this);
    this.handleSubscriptionSubmit = this.handleSubscriptionSubmit.bind(this);
    this.handleProfileStageSubmit = this.handleProfileStageSubmit.bind(this);

    this.renderAccountForm = this.renderAccountForm.bind(this);
    this.renderProfileForm = this.renderProfileForm.bind(this);
    this.renderPaymentForm = this.renderPaymentForm.bind(this);
    this.renderSubscriptionForm = this.renderSubscriptionForm.bind(this);
    this.renderSignUpStage = this.renderSignUpStage.bind(this);
    this.renderSetupComplete = this.renderSetupComplete.bind(this);

    this.getStepIcon = this.getStepIcon.bind(this);
  }

  getCurrentStageStatus() {
    switch(this.state.currentStage) {
      case STAGE_ACCOUNT:
        return this.state.accountSectionStatus;
      case STAGE_PROFILE:
        return this.state.profileSectionStatus;
      case STAGE_PAYMENT:
        return this.state.paymentSectionStatus;
      default:
        return STATE_PENDING;
    }
  }

  moveToNextStage() {
    if (this.state.currentStage === STAGE_PAYMENT) {
      return;
    }
    let current = this.state.currentStage;
    current++;
    this.setState({currentStage: current});
  }

  moveToPreviousStage() {
    if (this.state.currentStage === STAGE_ACCOUNT) {
      return;
    }
    let current = this.state.currentStage;
    current--;
    this.setState({currentStage: current});
  }

  async handleAccountStageSubmit(formData) {
    this.setState({accountSectionState: STATE_PROCESSING});
    createTrainer(formData).then((response) => {
      if (!response.ok) {
        Message.error("Could not set up user account.");
        this.setState({currentStage: STAGE_ACCOUNT, accountSectionState: STATE_ERROR});
        return;
      } else {
        return response.json();
      }
    }).then((json) => {
      let token = json.token;
      localStorage.setItem('PG_API_TOKEN', token);
      Message.success("Account created successfully.");
      this.setState({currentStage: STAGE_PAYMENT, user: json, accountSectionState: STATE_COMPLETE});
    });

  }

  async handlePaymentSubmit(stripe, elements) {
    this.setState({paymentSectionState: STATE_PROCESSING});
    stripe.confirmSetup({
      elements,
      confirmParams: {return_url: "http://127.0.0.1/test"},
      redirect: "if_required",
    }).then(function(result) {
      if (result.error) {
        Message.error("Could not set up payment method.");
        this.setState({paymentSectionState: STATE_ERROR})
      } else {
        return setPaymentMethod({payment_method_id: result.setupIntent.payment_method});
      }
    }).then((response) => {
      if (!response.ok) {
        Message.error("Could not set up payment method.");
        this.setState({paymentSectionState: STATE_ERROR})
      } else {
        Message.success("Payment details recorded successfully.");
        this.setState({currentStage: STAGE_SUBSCRIPTION, paymentSectionState: STATE_COMPLETE});
      }
    });
  }

  async handleSubscriptionSubmit(formData) {
    this.setState({subscriptionSectionState: STATE_PROCESSING});

    setSubscriptionClients(formData).then((response) => {
      if (!response.ok) {
        Message.error("Could not set up subscription. Please log in and try again later");
        this.setState({subscriptionSectionState: STATE_ERROR, currentStage: STAGE_SETUP_COMPLETE});
      } else {
        Message.success("Subscription created successfully.")
        this.setState({subscriptionSectionState: STATE_COMPLETE, currentStage: STAGE_SETUP_COMPLETE});
      }
    });
  }

  handleProfileStageSubmit(formData) {
    console.log(formData);
  }

  renderAccountForm() {

    return (
      <Form layout="horizontal" onSubmit={(formData) => {this.handleAccountStageSubmit(formData)}}>
        <Form.Item label='Email' field='email' rules={[{ required: true }]}>
          <Input type="email" placeholder="Please enter your email"/>
        </Form.Item>
        <Form.Item label='Password' required style={{ marginBottom: 0 }}>
          <Grid.Row gutter={8}>
            <Grid.Col span={12}>
              <Form.Item field='password' rules={[{ required: true }]}>
                <Input type="password" placeholder='Password' />
              </Form.Item>
            </Grid.Col>
            <Grid.Col span={12}>
              <Form.Item field='password_confirm' rules={[{ required: true }]}>
                <Input type="password" placeholder='Confirm Password' />
              </Form.Item>
            </Grid.Col>
          </Grid.Row>
        </Form.Item>
        <Form.Item label='Name' required style={{ marginBottom: 0 }}>
          <Grid.Row gutter={8}>
            <Grid.Col span={12}>
              <Form.Item field='first_name' rules={[{ required: true }]}>
                <Input placeholder='First Name' />
              </Form.Item>
            </Grid.Col>
            <Grid.Col span={12}>
              <Form.Item field='last_name' rules={[{ required: true }]}>
                <Input placeholder='Last Name' />
              </Form.Item>
            </Grid.Col>
          </Grid.Row>
        </Form.Item>
        <Form.Item label='Phone' field='phone_number' rules={[{ required: false }]}>
          <Input type="text" placeholder="Phone number"/>
        </Form.Item>
        <Form.Item label='Date of Birth' field='date_of_birth' rules={[{ required: false }]}>
          <DatePicker style={{width: "100%"}} />
        </Form.Item>
        <Grid.Row>
          <Form.Item wrapperCol={{ offset: 20 }} style={{alignContent: "flex-end"}}>
            <Button type='primary' htmlType='submit' style={{ marginRight: 24 }} loading={this.state.accountSectionState === STATE_PROCESSING}>Submit</Button>
          </Form.Item>
        </Grid.Row>
      </Form>
    );

  }

  renderProfileForm() {
    return (
      <Form layout="horizontal" onSubmit={(formData) => {this.handleProfileStageSubmit(formData)}}>
        <Form.Item label='Introduction' field='introduction' rules={[{ required: true }]}>
          <Input type="text" placeholder="Please write a few sentences about you and your services."/>
        </Form.Item>
      </Form>
    );
  }

  renderPaymentForm() {
    if (!this.state.user) {
      return <p>Account section must be completed before completing payment details.</p>;
    }
    const stripeOptions = {
      clientSecret: this.state.user.client_secret,
      appearance: {
        theme: 'night',
        labels: 'floating',
        variables: {
          colorPrimary: '#5AAAFB', 
        }
      }
    };
    return (
      <Elements stripe={stripePromise} options={stripeOptions}>
        <PaymentMethodForm onSubmit={this.handlePaymentSubmit} processing={this.state.paymentSectionState === STATE_PROCESSING}/>
      </Elements>
    );
  }

  renderSubscriptionForm() {
    return (
      <Form layout="horizontal" onSubmit={(formData) => {this.handleSubscriptionSubmit(formData)}}>
          <Form.Item label='Clients' field='number_of_clients' rules={[{ required: true, min: 1 }]} help="You can always change this later at any time." initialValue={1}>
            <InputNumber mode="button" defaultValue={1} min={1} prefix={<IconUserGroup />} onChange={(v) => {this.setState({numberOfClients: v})}}/>
        </Form.Item>

        <Grid.Row>
          <Grid.Col span={24}>
            <p>Each client comes with 2 free plans. Additional plans can be purchased for a 1 time payment as needed at a price of 50p per plan as required.</p>
          </Grid.Col>
        </Grid.Row>
        <div><Divider /></div>
        <div align="right">
          <Statistic

            style={{marginTop: 10, marginBottom: 10}}
            title="Monthly Cost"
            value={this.state.numberOfClients}
            prefix="£"
            suffix="Per month"
            styleValue={{ color: '#5AAAFB' }}
          />
        </div>
        <Button type='primary' htmlType='submit' loading={this.state.subscriptionSectionState === STATE_PROCESSING}>Submit</Button>
      </Form>
    );
  }

  renderSetupComplete() {
    return (
      <div align="middle">
        <h3>Congratulations!</h3>
        <p>You're all set up!</p>
        <NavigationButton text="Go to dashboard" type="primary" url="/dashboard" uuid={null} />
      </div>
    );
  }

  renderSignUpStage() {
    switch(this.state.currentStage) {
      case STAGE_ACCOUNT:
        return this.renderAccountForm();
      case STAGE_PROFILE:
        return this.renderProfileForm();
      case STAGE_PAYMENT:
        return this.renderPaymentForm();
      case STAGE_SUBSCRIPTION:
        return this.renderSubscriptionForm();
      case STAGE_SETUP_COMPLETE:
          return this.renderSetupComplete();
      default:
        break;
    }
  }

  getStepIcon(sectionState, defaultIcon) {
    switch (sectionState) {
      case STATE_ERROR:
        return <IconClose />;
      case STATE_PROCESSING:
        return <IconLoading />
      case STATE_COMPLETE:
        return <IconCheck/>
      default:
        return defaultIcon;
    }
  }

  render() {
    let stepError = [this.state.accountSectionState, this.state.profileSectionState, this.state.paymentSectionState].includes(STATE_ERROR);

    return (
      <Layout>
        <Layout.Header>
          <Grid.Row style={{justifyContent: "center"}}>
            <h1>Sign Up</h1>
          </Grid.Row>
        </Layout.Header>
        <Layout.Content>
          <div>
            <Grid.Row style={{justifyContent: "center"}}>
              <div style={{width: "60%"}}>
                <Grid.Col>
                  <Steps current={this.state.currentStage} status={stepError ? "error": "process"}>
                    <Steps.Step icon={this.getStepIcon(this.state.accountSectionState, <IconUser />)} title='Account' description='Set up your account' />
                    <Steps.Step icon={this.getStepIcon(this.state.paymentSectionState, <IconIdcard />)} title='Payment Method' description='Set up your payment method' />
                    <Steps.Step icon={this.getStepIcon(this.state.subscriptionSectionState, <IconDashboard />)} title='Subscription' description='Configure your subsciption' />
                    {/* <Steps.Step icon={this.getStepIcon(this.state.profileSectionState, <IconInfoCircle />)} title='Profile' description='Tell us about yourself' /> */}
                    <Steps.Step icon={<IconCheck/>} status={this.state.currentStage === STAGE_SETUP_COMPLETE ? "finish" : "pending"} title="Setup Complete"/>

                  </Steps>
                </Grid.Col>
              </div>
            </Grid.Row>
            <br/>
          </div>
          <Grid.Row style={{justifyContent: "center"}}>
            <Card style={{width: "35%"}}>
              {this.renderSignUpStage()}
            </Card>
          </Grid.Row>
        </Layout.Content>
      </Layout>
    );
  }
}