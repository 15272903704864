import React from 'react';
import { useNavigate } from 'react-router';
import { Layout, Menu, Grid, Result, Spin, Button } from "@arco-design/web-react";
import { IconUserAdd, IconTool, IconBook, IconFile, IconHome, IconSettings, IconEye, IconBug, IconBulb, IconUserGroup, IconQuestion, IconUser } from "@arco-design/web-react/icon";


export function DefaultPageLayout(props) {
  let navigate = useNavigate();
  async function handleDashboardClick() { navigate("/dashboard"); }
  async function handleClientsClick() { navigate("/clients"); }
  async function handlePlansClick() { navigate("/plans"); }
  async function handleExercisesClick() { navigate("/exercises"); }
  async function handleAccountClick() { navigate("/account"); }
  return(
    <Layout>
      <Layout.Sider trigger={null} breakpoint='xl'>
        <Menu style={{height: "100vh"}} hasCollapseButton mode="pop">
          <Menu.Item key="dashboard" onClick={handleDashboardClick}>
            <IconHome />
            Dashboard
          </Menu.Item>
          <Menu.Item key="clients" onClick={handleClientsClick}>
            <IconUserGroup />
            Clients
          </Menu.Item>
          <Menu.Item key="plans" onClick={handlePlansClick}>
          <IconFile />
            Plans
          </Menu.Item>
          <Menu.Item key="exercises" onClick={handleExercisesClick}>
            <IconBook />
            Exercises
          </Menu.Item>
          <Menu.Item key="account" onClick={handleAccountClick}>
            <IconSettings />
            Account
          </Menu.Item>
        </Menu>
      </Layout.Sider>
      <Layout>
        <Layout.Header style={{paddingLeft: 10, backgroundColor: "var(--color-bg-4)"}}>
          <h2>{props.pageHeader}</h2>
        </Layout.Header>
        {props.children}
        {props.showHelp ? <Button type='outline' size="large" shape="circle"  style={{margin: 10, alignSelf: "flex-end"}}icon={<IconQuestion />} onClick={() => {if (props.onHelpClick !== null) {props.onHelpClick()}}}/> : <></>}
      </Layout>
    </Layout>
  );
}

export function LoadablePageContent(props) {
  if (props.loading) {
    return <Grid.Row style={{justifyContent: "center"}}> <Spin dot style={{ marginTop: 100 }}/> </Grid.Row>
  }
  if (props.loadingError) {
    let defaultMessage = 'Something went wrong. Please try again later';
    let message = props.loadingErrorMessage === null || props.loadingErrorMessage === undefined ? defaultMessage : props.loadingErrorMessage;
    return <Result status='error' title='Error' subTitle={message} />
  }
  return <Layout.Content style={{maxHeight: "90vh", overflowY: "auto"}}>
    {props.children}
  </Layout.Content>
}



export function BaseHelpPage(props) {
  let navigate = useNavigate();

  return (
    <Layout>
    <Layout.Sider trigger={null} breakpoint='xl'>
  <Menu
    style={{ width: 240, height: '100vh' }}
    defaultOpenKeys={['clients_group']}
    defaultSelectedKeys={['clients_index']}
  >
    <Menu.SubMenu
      key='clients_group'
      title={<><IconUserGroup />Clients</>}
      onClick={() => {navigate("/help/clients")}}
    >
      <Menu.Item key='clients_index' onClick={() => {navigate(("/help/clients"))}}><IconUser/>Clients Overview</Menu.Item>
      <Menu.Item key='clients_view' onClick={() => {navigate(("/help/clients/viewing"))}}><IconEye/>Viewing Clients</Menu.Item>
      <Menu.Item key='clients_add' onClick={() => {navigate("/help/clients/add")}}><IconUserAdd/>Add Client</Menu.Item>
      <Menu.Item key='clients_manage' onClick={() => {navigate("/help/clients/manage")}}><IconTool/>Managing a Client</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu key='1' title={<><IconBug />Training Plans</>}>
      <Menu.Item key='1_0'>Menu 1</Menu.Item>
      <Menu.Item key='1_1'>Menu 2</Menu.Item>
      <Menu.Item key='1_2'>Menu 3</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu key='2' title={<><IconBulb />Exercises</>}>
      <Menu.Item key='2_0'>Menu 1</Menu.Item>
      <Menu.Item key='2_1'>Menu 2</Menu.Item>
      <Menu.Item key='2_2'>Menu 3</Menu.Item>
    </Menu.SubMenu>
  </Menu>
  </Layout.Sider>
  <Layout>
    {props.children}
  </Layout>
  </Layout>
  )
}