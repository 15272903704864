import { BaseHelpPage } from "../../../common/PageLayout";
import { Link, Image, Grid, Typography } from "@arco-design/web-react";
import { IconLink } from "@arco-design/web-react/icon";


export function ClientsAddHelpPage(props) {
  return (
    <BaseHelpPage>
      <Grid.Row>
        <Grid.Col span={24} style={{margin: 10}}>
          <Typography.Title>Adding Clients</Typography.Title>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={24} style={{margin: 10}}>
          <Typography.Paragraph>
          To add a client, press the "Add" button at the top right of the clients list, this will open the "add client" form.
          Once the form is submitted, one of your available client slots will be used. If you need to add more clients but have no more available slots, more can be added to your subscription from the billing management page.
          </Typography.Paragraph>
        </Grid.Col>
        <Grid.Row>
          <Grid.Col span={8} style={{paddingLeft: 10}}>
            <Typography.Title heading={5}>Add Client Form:</Typography.Title>
            <Image src="/helpimages/add_client_form.png" width="100%" />
          </Grid.Col>
          <Grid.Col span={16} style={{paddingLeft: 10}}>
            <Typography.Title heading={5}>Field Details:</Typography.Title>
            <ul>
              <li style={{padding: 5}}><span style={{fontWeight: "bold", color: "#5AAAFB"}}>First Name:</span> The first name/names of your client.</li>
              <li style={{padding: 5}}><span style={{fontWeight: "bold", color: "#5AAAFB"}}>Last Name:</span> The last name/names of your client.</li>
              <li style={{padding: 5}}><span style={{fontWeight: "bold", color: "#5AAAFB"}}>Email:</span> Client's email. required for sending communications and differentiating between clients with the same name.</li>
              <li style={{padding: 5}}><span style={{fontWeight: "bold", color: "#5AAAFB"}}>Date of Birth:</span> Client's birthdate in dd/mm/yyyy format. A date selector should show when pressed.</li>
              <li style={{padding: 5}}><span style={{fontWeight: "bold", color: "#5AAAFB"}}>Height:</span> Client's height in meters.</li>
              <li style={{padding: 5}}><span style={{fontWeight: "bold", color: "#5AAAFB"}}>Bodyweight:</span> Client's bodyweight in kilograms. If entered, a new Bodyweight Record will be created with the client.</li>
              <li style={{padding: 5}}><span style={{fontWeight: "bold", color: "#5AAAFB"}}>Bodyfat Percentage:</span> Client's bodyfat percentage. If entered, a new Bodyfat Percentage record will be created with the client.</li>
              <li style={{padding: 5}}><span style={{fontWeight: "bold", color: "#5AAAFB"}}>Notes:</span> Freeform text for any notes relevant to the client.</li>
            </ul>
          </Grid.Col>
        </Grid.Row>

        <Grid.Col span={24} style={{margin: 10}}>
          <Typography.Title heading={4}>Related Topics:</Typography.Title>
          <Typography.Paragraph>
            <Link href='#' icon={<IconLink />}>Viewing Your Clients</Link><br/>
            <Link href='#' icon={<IconLink />}>Creating a Plan</Link><br/>
            <Link href='#' icon={<IconLink />}>Billing</Link><br/>
          </Typography.Paragraph>
        </Grid.Col>
      </Grid.Row>
    </BaseHelpPage>
  );
}