import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import LoginPage from './components/pages/LoginPage';
import './App.css';
import "@arco-design/web-react/dist/css/arco.css";
import { ConfigProvider } from '@arco-design/web-react';
import enUS from '@arco-design/web-react/es/locale/en-US';
import HomePage from './components/pages/HomePage';
import Dashboard from './components/pages/Dashboard';
import ClientListPage from './components/pages/ClientsList';
import ExerciseListPage from "./components/pages/ExerciseList";
import PlanListPage from "./components/pages/PlanList";
import PlanDetailPage from "./components/pages/PlanDetailPage";
import SignUpPage from "./components/pages/SignUpPage";
import ClientSessionListPage from "./components/pages/ClientSessionsList";
import { ClientDashboard } from "./components/pages/ClientDashboard";
import BodyCompositionPage from "./components/pages/BodyCompositionPage";
import PerformedSessionDetailPage from "./components/pages/PerformedSessionDetailPage";
import { ProgressPictureGalleryPage } from "./components/pages/ProgressPictureGallery";
import { PlannedSessionDetailPage } from "./components/pages/PlannedSessionDetailPage";
import AccountPage from "./components/pages/AccountPage";
import TrainersPage from "./components/pages/TrainersPage";
import ClientsPage from "./components/pages/CientsPage";
import GoalTrackingPage from "./components/pages/GoalTracking";
import { ClientsHelpPage } from './components/pages/help/ClientsHelpPage';
import { ClientsAddHelpPage } from './components/pages/help/ClientsAddHelpPage';
import { ClientsViewHelpPage } from './components/pages/help/ClientsViewHelpPage';
import { ClientsManageHelpPage } from './components/pages/help/ClientsManageHelpPage';


// TOOD: handle 401 responses from api -> redirect to login

function App() {

  let authToken = localStorage.getItem("PG_API_TOKEN");
  const publicRoutes = [
    <Route path="" exact={true} element={<HomePage/>} />,
    <Route path="/for-trainers" exact={true} element={<TrainersPage/>} />,
    <Route path="/for-clients" exact={true} element={<ClientsPage/>} />,
    <Route path="/signup" exact={true} element={<SignUpPage/>} />,
    <Route path="/login" exact={true} element={<LoginPage/>} />,
  ];
  const authenticatedRoutes = [
    <Route path="" exact={true} element={<HomePage/>} />,
    <Route path="/for-trainers" exact={true} element={<TrainersPage/>} />,
    <Route path="/for-clients" exact={true} element={<ClientsPage/>} />,
    <Route path="/signup" exact={true} element={<SignUpPage/>} />,
    <Route path="/login" exact={true} element={<LoginPage/>} />,
    <Route path="/dashboard" exact={true} element={<Dashboard/>} />,
    <Route path="/clients" exact={true} element={<ClientListPage/>} />,
    <Route path="/clients/:uuid" exact={true} element={<ClientDashboard/>} />,
    <Route path="/clients/:uuid/bodycomposition" exact={true} element={<BodyCompositionPage/>} />,
    <Route path="/clients/:uuid/sessions" exact={true} element={<ClientSessionListPage/>} />,
    <Route path="/clients/:uuid/goals" exact={true} element={<GoalTrackingPage/>} />,
    <Route path="/clients/:uuid/progresspictures" exact={true} element={<ProgressPictureGalleryPage/>} />,
    <Route path="/exercises" exact={true} element={<ExerciseListPage/>} />,
    <Route path="/plans" exact={true} element={<PlanListPage/>} />,
    <Route path="/plans/:uuid" exact={true} element={<PlanDetailPage/>} />,
    <Route path="/performedsessions/:uuid" exact={true} element={<PerformedSessionDetailPage/>} />,
    <Route path="/plannedsessions/:uuid" exact={true} element={<PlannedSessionDetailPage/>} />,
    <Route path="/account" exact={true} element={<AccountPage/>} />,
    <Route path="/help/clients" exact={true} element={<ClientsHelpPage/>} />,
    <Route path="/help/clients/add" exact={true} element={<ClientsAddHelpPage/>} />,
    <Route path="/help/clients/viewing" exact={true} element={<ClientsViewHelpPage/>} />,
    <Route path="/help/clients/manage" exact={true} element={<ClientsManageHelpPage/>} />,
  ]

  let routes = authToken === null ? publicRoutes : authenticatedRoutes;
  return (
    <ConfigProvider locale={enUS}>
      <div className="App">
        <Router>
          <Routes>
            {routes}
          </Routes>
        </Router>
      </div>
    </ConfigProvider>
  )
}

export default App;

// TODO: auth routes