import React from 'react';
import { Grid, Button, Form, Modal, Input } from '@arco-design/web-react';


export function ClientModal(props) {

  const [form] = Form.useForm();

  let instance = props.instance;
  let instanceExists = instance !== null && instance !== undefined;

  let initialValues = {
    first_name: null,
    last_name: null,
    email: null,
    date_of_birth: null,
    height: null,
    notes: null,
  };

  if (instanceExists) {
    initialValues.first_name = instance.first_name
    initialValues.last_name = instance.last_name
    initialValues.email = instance.email
    initialValues.date_of_birth = instance.date_of_birth
    initialValues.height = instance.height
    initialValues.notes = instance.notes
    form.setFieldsValue(initialValues);
  }

  return (
    <Modal
    title={props.title}
    visible={props.visible}
    onCancel={() => {props.onCancel()}}
    footer={null}
    style={{width: "80vh"}}
    >
      <Form form={form} onSubmit={(formData) => {props.handleSubmit(formData)}}>
        <Form.Item label="First Name" field="first_name" rules={[{required: true}]}>
          <Input type="text" placeholder="First Name" />
        </Form.Item>
        <Form.Item label="Last Name" field="last_name" rules={[{required: true}]}>
          <Input type="text" placeholder="Last Name" />
        </Form.Item>
        <Form.Item label='Email' field='email' rules={[{ required: true }]}>
          <Input type="email" placeholder="Email" />
        </Form.Item>
        <Form.Item label='Date of Birth' field='date_of_birth'>
          <Input type="date" placeholder="Date of Birth" />
        </Form.Item>
        <Form.Item label='Height (m)' field='height'>
          <Input type="number" placeholder="Hight" />
        </Form.Item>
        <Form.Item label='Bodyweight (kg)' field='current_bodyweight'>
          <Input type="number" placeholder="Bodyweight" />
        </Form.Item>
        <Form.Item label='Bodyfat (%)' field='current_bodyfat'>
          <Input type="number" placeholder="Bodyfat Percentage" />
        </Form.Item>
        <Form.Item label="Notes" field="notes">
          <Input.TextArea
            placeholder='Enter notes'
            autoSize={{ minRows: 2, maxRows: 6 }}
            style={{ width: 350 }}
          />
        </Form.Item>
        <Grid.Row>
          <Grid.Col span={20}>
            <Form.Item><Button type='secondary' onClick={() => {props.onCancel()}}>Cancel</Button></Form.Item>
          </Grid.Col>
          <Grid.Col span={4}>
            <Form.Item><Button loading={props.adding} type='primary' htmlType='submit'>Submit</Button></Form.Item>
          </Grid.Col>
        </Grid.Row>
      </Form>
    </Modal>
  );
}