import React from 'react';
import { DefaultPageLayout, LoadablePageContent } from '../../common/PageLayout';
import { Pagination, Statistic, Spin, Message, Result, Button, Table, Card, Grid } from "@arco-design/web-react";
import { createGoal, deleteGoal, listGoals, retrieveGoalStateCounts, updateGoal } from '../../api/goals';
import { NavigationButton } from '../common/Navigation';
import { StandardTableOperations } from '../common/StandardTableOperations';
import { GoalModal } from '../modals/GoalModal';
import { listAllExercisesNew } from '../../api/exercises';
import { extractUUIDFromObjectList } from '../../utils/forms';
import { IconCloseCircle, IconMore, IconCheckCircle } from '@arco-design/web-react/icon';
import { DeleteModal } from '../modals/common';


export default class GoalTrackingPage extends React.Component {
  constructor(props) {
    super(props);
    
    let uuid = window.location.pathname.split("/")[2];
    this.state = {
      clientUUID: uuid,
      loading: true,
      loadingError: false,

      goals: [],
      selectedGoalPage: 1,
      totalGoals: 0,
      loadingGoals: true,
      goalsError: false,

      exercises: [],
      exerciseNames: [],

      goalStateCounts: null,
      loadingCounts: true,
      countsError: false,

      adding: false,
      addGoalModalVisible: false,
      
      editing: false,
      selectedGoal: null,
      editGoalModalVisible: false,

      deleteGoalModalVisible: false,
      
    };


    this.loadPage = this.loadPage.bind(this);
    this.confirmDeleteGoal = this.confirmDeleteGoal.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleAddGoalSubmit = this.handleAddGoalSubmit.bind(this);
    this.handleEditGoalSubmit = this.handleEditGoalSubmit.bind(this);
    this.renderGoalCounts = this.renderGoalCounts.bind(this);
    this.renderPageContent = this.renderPageContent.bind(this);
  }

  async componentDidMount() {
    listGoals({page: this.state.selectedGoalPage, client_slot: this.state.clientUUID}).then((response) => {
      if (!response.ok) {
        Message.error("Could not retrieve goals.");
        this.setState({loading: false, loadingError: true, loadingGoals: false, goalsError: true});
        return;
      }
      return response.json();
    }).then((json) => {
      this.setState({
        loading: false,
        loadingError: false,
        loadingGoals: false,
        goalsError: false,
        goals: json.results,
        totalGoals: json.count,
      });
    });

    retrieveGoalStateCounts(this.state.clientUUID).then((response) => {
      if (!response.ok) {
        Message.warning("Could not retrieve state counts.")
        this.setState({loadingCounts: false, countsError: true});
        return;
      }
      return response.json();
    }).then((json) => {
      this.setState({loadingCounts: false, countsError: false, goalStateCounts: json});
    });

    listAllExercisesNew({}).then((json) => {
      let exerciseNames = [];
      for (let i = 0; i < json.length; i++) {
        exerciseNames.push(json[i].name);
      }
      this.setState({exercises: json, exerciseNames: exerciseNames});
    });
  }

  loadPage(page) {
    this.setState({loadingGoals: true});
    listGoals({client_slot: this.state.clientUUID}).then((response) => {
      if (!response.ok) {
        Message.error("Could not retrieve goals.");
        this.setState({loading: false, loadingError: true, loadingGoals: false, goalsError: true});
        return;
      }
      return response.json();
    }).then((json) => {
      this.setState({
        loadingGoals: false,
        goalsError: false,
        goals: json.results,
        totalGoals: json.count,
        selectedPage: page,
      });
    });
  }

  handlePageChange(page) {
    this.loadPage(page);
  }


  async handleAddGoalSubmit(formData) {
    formData.client_slot = this.state.clientUUID;
    let exerciseUUID = extractUUIDFromObjectList(formData.exercise, this.state.exercises, "name");
    formData.exercise = exerciseUUID;
    formData.exercise_type = formData.exercise.toUpperCase();

    this.setState({adding: true});

    createGoal(formData).then((response) => {
      if (!response.ok) {
        Message.error("Could not create new goal.");
        this.setState({adding: false, addGoalModalVisible: false});
        return;
      }
      return response.json();
    }).then((json) => {
      Message.success("Goal added successfully");
      let newGoalList = structuredClone(this.state.goals);
      newGoalList.push(json);
      this.setState({adding: false, addGoalModalVisible: false, goals: newGoalList});
    });
  }

  async handleEditGoalSubmit(formData) {
    this.setState({editing: true});
    formData.client = this.state.clientUUID;
    let exerciseUUID = extractUUIDFromObjectList(formData.exercise, this.state.exercises, "name");
    formData.exercise = exerciseUUID;
    
    updateGoal(formData, this.state.selectedGoal.uuid).then((response) => {
      if (!response.ok) {
        Message.error("Could not update goal.");
        this.setState({editing: false, selectedGoal: null, editGoalModalVisible: false});
        return;
      }
      return response.json();
    }).then((json) => {
      Message.success("Goal updated successfully.");
      let newGoalList = [];
      for (let i = 0; i < this.state.goals.length; i++) {
        let currentGoal = this.state.goals[i];
        if (currentGoal.uuid !== json.uuid) {
          newGoalList.push(currentGoal);
        } else {
          newGoalList.push(json);
        }
      }
      this.setState({editing: false, selectedGoal: null, editGoalModalVisible: false, goals: newGoalList});
    });
  }

  async confirmDeleteGoal() {
    deleteGoal(this.state.selectedGoal.uuid).then((response) => {
      if (!response.ok) {
        Message.error("Could not delete goal");
        this.setState({selectedGoal: null, deleteGoalModalVisible: false});
        return;
      }
      let newGoals = [];
      for (let i = 0; i < this.state.goals.length; i++) {
        let currentGoal = this.state.goals[i];
        if (currentGoal.uuid !== this.state.selectedGoal.uuid) {
          newGoals.push(currentGoal);
        }
      }
      this.setState({deleteGoalModalVisible: false, selectedGoal: null, goals: newGoals});
    });
  }


  renderGoalCounts() {
    if (this.state.loadingCounts) {
      return <Grid.Row style={{justifyContent: "center"}}><Spin dot /></Grid.Row>
    }
    if (this.state.loadingCountsError) {
      return (
        <Result
          status='error'
          title='Error'
          subTitle='Something went wrong. Please try again later.'
        />
      );
    }

    let countStats = [];
    for (let i = 0; i < this.state.goalStateCounts.length; i++) {
      let planCount = this.state.goalStateCounts[i];
      countStats.push(
        <Grid.Col span={24/this.state.goalStateCounts.length}>
          <Statistic
            title={planCount.label}
            value={planCount.count}
          />
        </Grid.Col>
      );
    }

    return (
      <Grid.Row>{countStats}</Grid.Row>
    )
  }

  renderPageContent() {
    return (
      <>
        <Grid.Row>
          <Grid.Col span={24}>
            <Card title="Goals Overview" style={{margin: 10}}>
              {this.renderGoalCounts()}
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={24}>
            <Card
              title="Goals"
              extra={<Button type="outline" status="primary" size="mini" onClick={() => {this.setState({addGoalModalVisible: true})}}>Add</Button>}
              style={{margin: 10, marginTop: 0}}
              bodyStyle={{padding: 5}}
            >
              <Table
                size="mini"
                loading={this.state.loadingGoals}
                data={this.state.goals}
                pagination={false}
                columns={[
                  {title: "Status", dataIndex: "status", render: (_, record) => {
                    if (record.in_progress) {
                      return <div style={{color: "#929293"}}><IconMore fontSize={20}/></div>
                    }
                    if (record.missed_deadline) {
                      return <div style={{color: "#F76965"}}><IconCloseCircle fontSize={20}/></div>
                    }
                    if (record.completed) {
                      return <div style={{color: "#27C346"}}><IconCheckCircle fontSize={20}/></div>
                    }

                  }},
                  {title: "Exercise", dataIndex: "exercise.name"},
                  {title: "Goal", dataIndex: "goal_display"},
                  {title: "Deadline", dataIndex: "deadline"},
                  {title: "Completed", dataIndex: "completed_at", render: (_, record) => record.completed_at === null ? "-" : record.completed_at},
                  {title: "View Progress", dataIndex: "view", render: (_, record) => {return <NavigationButton text="View" size="mini" type="default" url="plans" uuid={record.uuid} />}},
                  {title: "Operations", dataIndex: "op", align: "right", render: (_, record) => {
                    return <StandardTableOperations
                      record={record}
                      editLabel="Edit Goal"
                      showEdit={true}
                      deleteLabel="Delete Set"
                      showDelete={true}
                      onEdit={(record) => { this.setState({editGoalModalVisible: true, selectedGoal: record}); }}
                      onDelete={(record) => {
                          this.setState({deleteGoalModalVisible: true, selectedGoal: record});
                        }
                      }
                    />
                  }},
                ]}
                rowKey={(record) => {return record.uuid}}
              />
              <Grid.Row>
                <Grid.Col span={24} style={{marginTop: 10, marginBottom: 10}}>
                  <Pagination
                    simple={true}
                    total={this.state.totalGoals}
                    pageSize={20}
                    current={this.state.selectedPage}
                    onChange={(page) => {this.handlePageChange(page)}}
                    style={{float: 'right'}}
                  />
                </Grid.Col>
              </Grid.Row>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </>
    );
  }

  render() {
    let pageContent = this.renderPageContent();
    return(
      <DefaultPageLayout pageHeader="Goals">
        <LoadablePageContent loading={this.state.loading} loadingError={this.state.loadingError}>
          <GoalModal
            title="Add Exercise Goal"
            visible={this.state.addGoalModalVisible}
            handleCancel={() => {this.setState({addGoalModalVisible: false})}}
            exerciseOptions={this.state.exerciseNames}
            handleSubmit={this.handleAddGoalSubmit}
            adding={this.state.adding}
            instance={this.state.selectedGoal}
          />
          <GoalModal
            title="Edit Goal"
            instance={this.state.selectedGoal}
            visible={this.state.editGoalModalVisible}
            handleCancel={() => {this.setState({editGoalModalVisible: false})}}
            exerciseOptions={this.state.exerciseNames}
            handleSubmit={this.handleEditGoalSubmit}
            working={this.state.editing}
          />
          <DeleteModal
            visible={this.state.deleteGoalModalVisible}
            title="Delete Goal"
            message="Are you sure? This cannot be undone."
            handleConfirm={() => {this.confirmDeleteGoal()}}
            onCancel={() => {this.setState({deleteGoalModalVisible: false})}}
          />
          {pageContent}
        </LoadablePageContent>
      </DefaultPageLayout>
    );
  }
}

// TODO: check "working/adding" on all operation modals