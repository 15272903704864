import {apiDeleteRequest, apiGetRequest, apiPostRequest } from "./apiRequest";

import { BODYWEIGHT_URL } from "./conf";


export async function createBodyweight(data) {
  return apiPostRequest(BODYWEIGHT_URL, data);
}

export async function deleteBodyweight(uuid) {
  return await apiDeleteRequest(`${BODYWEIGHT_URL}${uuid}/`);
}

export async function listBodyweights(params) {
  return apiGetRequest(BODYWEIGHT_URL, params);
}

