import { Grid, Button, Form, Modal, Select, DatePicker } from '@arco-design/web-react';


export function PerformedSessionModal(props) {
  const [form] = Form.useForm();
  
  let instance = props.instance;
  let instanceExists = instance !== null && instance !== undefined;

  let initialValues = {
    performed_at: null,
    planned_session: null,
    completed_at: null,
  };
  if (instanceExists) {
    initialValues.performed_at = instance.performed_at;
    if (instance.planned_session) {
      initialValues.planned_session = `${instance.planned_session.plan_name}: ${instance.planned_session.name}`;
    }
    
    initialValues.completed_at = instance.completed_at;
    form.setFieldsValue(initialValues);
  }


  return(
    <Modal title={props.title} visible={props.visible} onCancel={() => {props.onCancel();}} footer={null} style={{width: "50%"}}>
      <Form form={form} layout="horizontal" onSubmit={(formData) => {props.handleSubmit(formData);}} initialValues={props.initialValues}>
        <Form.Item label="Planned Session" field="planned_session" rules={[{required: true}]}>
          <Select options={props.plannedSessionOptions}/>
        </Form.Item>
        <Form.Item label='Performed At' field='performed_at' rules={[{required: true}]}>
          <DatePicker showTime={true} style={{width: "100%"}} />
        </Form.Item>
        <Form.Item label='Completed At' field='completed_at'>
          <DatePicker showTime={true} style={{width: "100%"}} />
        </Form.Item>
        <Grid.Row>
          <Grid.Col span={20}>
            <Form.Item><Button type='secondary' onClick={() => {props.onCancel();}}>Cancel</Button></Form.Item>
          </Grid.Col>
          <Grid.Col span={4}>
            <Form.Item><Button type='primary' htmlType='submit'>Submit</Button></Form.Item>
          </Grid.Col>
        </Grid.Row>
      </Form>
    </Modal>
  );
}