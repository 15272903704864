import { Grid, Button, Typography } from "@arco-design/web-react";
import '../../App.css';

export default function TrainersPage(props) {
// style={{fontSize: 100, letterSpacing: "10px"}}
  return (
    <div style={{overflowY: "auto", maxHeight: "100vh"}}>
      <Grid.Row>
        <Grid.Col span={24} style={{justifyContent: "space-around", alignItems: "center", alignContent: "center", paddingLeft: 20}}>
          <Typography.Title className="page-title">Trainers</Typography.Title>
          <Typography.Title className="page-subtitle" heading={2}>Gain unprecidented insights.</Typography.Title>
          <span style={{display: "inline"}}>
            <a href="/signup"><Button type="outline" status="primary" shape="round" style={{display: "inline", marginRight: 50}}>Sign Up</Button></a>
            <a href="/login"><Button type="outline" status="primary" shape="round" style={{display: "inline", marginRight: 50}}>Log In</Button></a>
          </span>
        </Grid.Col>
      </Grid.Row>
      <div style={{margin: 75}}>
        <div className="flex-grid-thirds">
          <div className="flex-col" align="middle">
            <img className="feature-icon" src="note_blue.png" width="20%" alt="plans icon"/>
            <h2>Plans</h2>
            <p>Create and tailor plans to suit all of your clients' needs. Plan sessions with for your clients building up workouts from your own, custom database of exercises.</p>
          </div>
          <div className="flex-col" align="middle">
            <img className="feature-icon" src="heart_blue.png" width="20%"  alt="body composition icon"/>
            <h2>Body Composition</h2>
            <p>Track bodyweight and bodyfat percentages of all of your clients throughout their plans to see how your plans are improving their physiques and health.</p>
          </div>
          <div className="flex-col" align="middle">
            <img className="feature-icon" src="meter_blue.png" width="20%" alt="workout performance icon"/>
            <h2>Track Workout Performance</h2>
            <p>Performance of each session is automatically measured and tracked as workouts are recorded.</p>
          </div>
        </div>
        <div className="flex-grid-thirds" style={{marginTop: 20}}>
          <div className="flex-col" align="middle">
            <img className="feature-icon" src="pointer_blue.png" width="20%" alt="goals icon"/>
            <h2>Goals</h2>
            <p>Set client goals and track their progress towards them. PRs are automatically flagged so you spend less time looking through sets and more time planning the big picture.</p>
          </div>
          <div className="flex-col" align="middle">
            <img className="feature-icon" src="mobile_blue.png" width="20%" alt="mobile app icon"/>
            <h2>Mobile App (coming soon)</h2>
            <p>No more pen and paper! No more spreadsheets! Invite your clients to use our mobile app to record their workouts and see their data appear in your dashboard as it happens.</p>
          </div>
          <div className="flex-col" align="middle">
            <img className="feature-icon" src="loan_blue.png" width="20%" alt="pricing icon"/>
            <h2>Pricing</h2>
            <p>Personal Gainer is targetted towards independent trainers with afforable, flexible subscriptions. Only pay for what you use and scale your subscription up as your clientbase grows.</p>
          </div>
        </div>
      </div>
    </div>
  )
}