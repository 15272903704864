

export function cleanNonRequiredFields(formData) {
  let data = {};
  for (let x in formData) {
    let value = formData[x];
    if (value !== undefined || value !== null) {data[x] = value}
  }
  return data;
}


export function extractUUIDFromObjectList(value, objectArray, key) {
  let uuid = null;
  for (let i = 0; i < objectArray.length; i++) {
    let obj = objectArray[i];
    if (obj[key] === value) {
      uuid = obj.uuid;
      break;
    }

  }
  return uuid;
}

export function getInitialValues(instance, fieldNames) {
  // TODO: finish or remove?
  const instanceExists = instance !== null && instance !== undefined;
  if (!instanceExists) {return}

  let initialValues = {};
  for (let name in fieldNames) {
    initialValues[name] = instance[name];
  }

  return initialValues;

}