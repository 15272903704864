import React from 'react';
import { DefaultPageLayout, LoadablePageContent } from '../../common/PageLayout';
import { Tooltip, Spin, Result, Button, Link, Table, Image, Card, Grid, Layout, Descriptions, Empty, Message } from "@arco-design/web-react";
import { retrieveClient } from '../../api/clients';
import { valueOrPlaceholder } from '../../utils/dataDisplay';
import { IconInfoCircle } from '@arco-design/web-react/icon';
import { TrendStatistic } from '../common/TrendStatistic';
import { listPerformedSessions } from '../../api/performedSessions';
import { convertDateToFilterString } from '../../utils/data';
import { RecentSessionsTable } from '../common/RecentSessionsTable';
import { ProgressPicture } from '../common/ProgressPicture';
import { listGoals } from '../../api/goals';


export class ClientDashboard extends React.Component {
  constructor(props) {
    super(props);
    let uuid = window.location.pathname.split("/")[2];
    this.state = {
      clientUUID: uuid,
      
      loading: true,
      loadingError: false,
      client: null,
      
      recentSessions: [],
      loadingRecentSessions: true,
      recentSessionsError: false,

      goals: [],
      loadingGoals: true,
      goalsError: false,
      goalsPage: 1,
      totalGoals: null,
    }

    this.getClient = this.getClient.bind(this);
    this.hasCurentPlan = this.hasCurentPlan.bind(this);

    this.renderCurrentPlanDescription = this.renderCurrentPlanDescription.bind(this);
    this.renderProgressPicture = this.renderProgressPicture.bind(this);
    this.renderBodyweightStatistic = this.renderBodyweightStatistic.bind(this);
    this.renderBodyfatStatistic = this.renderBodyfatStatistic.bind(this);
    this.renderPageContent = this.renderPageContent.bind(this);
  }

  async componentDidMount() {
    await this.getClient();
  }

  hasCurentPlan() {
    if (this.state.client === null) {
      return false;
    }
    return ![null, undefined].includes(this.state.client.current_plan);
  }


  async getClient() {

    let client;
    retrieveClient(this.state.clientUUID).then((response) => {
      if (!response.ok) {
        Message.error("Could not retrieve client details.");
        this.setState({loading: false, loadingError: true});
        return;
      }
      return response.json();
    }).then((json) => {
      client = json;
      if (client.current_plan === null) {
        let error = new Error("NO_CURRENT_PLAN");
        error.name = "NO_CURRENT_PLAN";
        throw error;
      }
      console.log("asdasdasdasdasd", client.current_plan);
      return listGoals({
        client_slot: this.state.clientUUID,
        deadline__gte: client.current_plan.start_date,
        deadline__lte: client.current_plan.end_date,
      });
    }).then((goalsResponse) => {
      if (!goalsResponse.ok) {
        Message.warning("Could not retrieve goals.");
        this.setState({client_slot: client, loadingGoals: false, goalsError: true});
        return;
      }
      return goalsResponse.json();
    }).then((goalsJson) => {
      this.setState({
        client: client,
        goals: goalsJson.results,
        totalGoals: goalsJson.count,
        loadingGoals: false,
        goalsError: false,
        loading: false,
        error: false,
      });
    }).catch((error) => {
      console.log(error);
      switch (error.name) {
        case "NO_CURRENT_PLAN":
          console.log("ASD");
          this.setState({client: client, loading: false, loadingGoals: false, goalsError: false});
          return;

        default:
          this.setState({loaing: false, loadingError: true});

      }
    });

    let lowerDateRange = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    let dateRangeString = convertDateToFilterString(lowerDateRange);

    listPerformedSessions({performed_at__gte: dateRangeString, client_slot: this.state.clientUUID}).then((response) => {
      if (!response.ok) {
        Message.warning("Could not load recent sessions.");
        this.setState(({loadingRecentSessions: false, recentSessionsError: true}));
        return;
      }
      return response.json();
    }).then((json) => {
      this.setState({recentSessions: json.results, loadingRecentSessions: false, recentSessionsError: false});
    });

  }

  renderCurrentPlanDescription() {
    let currentPlan = this.state.client.current_plan;
    
    if (!this.hasCurentPlan()) {
      return <Result title="No Plan Set" status="warning" extra={<Button href="/plans" type='default'>Add Plan</Button>}/>;
    }

    let planData = [
      {label: "Name", value: valueOrPlaceholder(currentPlan.name, "N/A")},
      {label: "Start Date", value: valueOrPlaceholder(currentPlan.start_date, "N/A")},
      {label: "End Date", value: valueOrPlaceholder(currentPlan.end_date, "N/A")},
      {label: "Notes", value: valueOrPlaceholder(currentPlan.notes, "N/A")},
    ];
    return <Descriptions colon=': ' layout='inline-horizontal' data={planData} />
  }

  renderProgressPicture() {
    if (this.state.client.progress_pictures.length === 0) {
      return <Empty/>
    }

    const progressPicture = this.state.client.progress_pictures[0];
    let pictureURL = progressPicture.picture.slice(1);
    let fullURL = `${process.env.REACT_APP_BASE_API_URL}${pictureURL}`;

    let notes = (
      <div>
        <p><b>Client Notes:</b></p>
        <p>{progressPicture.client_notes}</p>
        <hr/>
        <p><b>Trainer Notes:</b></p>‰
        <p>{progressPicture.trainer_notes}</p>
      </div>
    )
    return(
      <Image
        width={"100%"}
        height={"100%"}
        src={fullURL}
        style={{width: "100%", height: "100%"}}
        previewProps={{
          actions: [
            {
              key: 'info',
              content: <IconInfoCircle />,
              name: 'Info',
              getContainer: (action) => {
                return <Tooltip content={notes}>
                  {action}
                </Tooltip>;
              },
            },
          ]
        }}
      />
    )
  }

  renderBodyweightStatistic() {
    let currentBodyweight = this.state.client.current_bodyweight;
    let previousBodyweight = this.state.client.recorded_bodyweights.length > 1 ? this.state.client.recorded_bodyweights[1].weight : null;
    let bodyweightGoal = this.state.client.current_bodyweight_goal;

    let currentWeight = currentBodyweight !== null ? currentBodyweight.weight : null;
    let goalWeight = bodyweightGoal !== null ? bodyweightGoal.weight : null;

    let difference = currentWeight - previousBodyweight;
    return <TrendStatistic
      title="Bodyweight"
      value={currentWeight}
      difference={difference}
      goalValue={goalWeight}
      units="kgs"
    />
  }

  renderBodyfatStatistic() {
    let currentBodyfatPercentage = this.state.client.current_bodyfat_percentage;
    let previousBodyfatPercentage = this.state.client.recorded_bodyfat_percentages.length > 1 ? this.state.client.recorded_bodyfat_percentages[1].percentage : null;
    let bodyfatPercentageGoal = this.state.client.current_bodyfat_percentage_goal;

    let currentPercentage = currentBodyfatPercentage !== null ? currentBodyfatPercentage.percentage : null;
    let goalPercentage = bodyfatPercentageGoal !== null ? bodyfatPercentageGoal.percentage : null;
    
    let difference = currentPercentage - previousBodyfatPercentage;
    return <TrendStatistic
      title="Bodyfat"
      value={currentPercentage}
      difference={difference}
      goalValue={goalPercentage}
      units="%"
    />
  }

  renderPageContent() {
    if (this.state.loading) {
      return (
        <Grid.Row style={{justifyContent: "center"}}>
          <Spin dot style={{ marginTop: 100 }}/>
        </Grid.Row>
      );
    }
    if (this.state.error) {
      return (
        <Result
          status='error'
          title='Error'
          subTitle='Something went wrong. Please try again later.'
        />
      );
    }

    console.log(this.state.client);
    let clientData = [
      {label: "Name", value: valueOrPlaceholder(this.state.client.name, "N/A")},
      {label: "Number", value: valueOrPlaceholder(this.state.client.phone_number, "N/A")},
      {label: "Client Linked", value: this.state.client.accepted ? <Button size="mini" type="outline" status="success">Yes</Button> : <Button size="mini" type="outline" status="warning">No</Button>},
      {label: "App Invite Code", value: <span style={{letterSpacing: 2, fontWeight: "bold"}}>{this.state.client.app_invite_code}</span>},
      {label: "Email", value: valueOrPlaceholder(this.state.client.email, "N/A")},
      {label: "Height (m)", value: valueOrPlaceholder(this.state.client.height, "N/A")},
      {label: "Date of Birth", value: valueOrPlaceholder(this.state.client.date_of_birth, "N/A")},
      {label: "Notes", value: valueOrPlaceholder(this.state.client.notes, "N/A")},
    ];
    
    return(
      <Layout.Content>
        <Grid.Row>
          <Grid.Col span={24} style={{padding: 10, paddingBottom: 0}}>
            <Card title="Personal Details" style={{minHeight: "210px"}}>
              <Descriptions colon=': ' layout='inline-horizontal' data={clientData} />
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={12} style={{paddingLeft: 10, paddingRight: 5, paddingTop: 10}}>
            <Card title="Current Plan" actions={[
              <Link
                disabled={!this.hasCurentPlan()}
                href={this.hasCurentPlan() ? `/plans/${this.state.client.current_plan.uuid}` : "#"}
                hoverable={false}>
                  View Details
                </Link>
              ]}
            >
              {this.renderCurrentPlanDescription()}
            </Card>
          </Grid.Col>
          <Grid.Col span={12} style={{paddingLeft: 5, paddingTop: 10, paddingRight: 10}}>
            <Card title="Body Composition" actions={[<Link href={`/clients/${this.state.clientUUID}/bodycomposition`} hoverable={false}>View Details</Link>]}>
              {this.renderBodyweightStatistic()}
              {this.renderBodyfatStatistic()}
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={8} style={{paddingLeft: 10, paddingRight: 5, paddingTop: 10}}>
            <Card title="Latest Progress Picture" actions={[<Link href={this.state.client !== null ? `/clients/${this.state.client.uuid}/progresspictures` : "#"} hoverable={false}>View All</Link>]}>
              {this.state.client.progress_pictures.length === 0 ? <Empty/> : <ProgressPicture progressPicture={this.state.client.progress_pictures[0]}/>}
            </Card>
          </Grid.Col>
          <Grid.Col span={16} style={{paddingLeft: 5, paddingRight: 10, paddingTop: 10}}>
            <Card title="Recent Sessions" actions={[<Link disabled={!this.hasCurentPlan()} href={`/clients/${this.state.clientUUID}/sessions`} hoverable={false}>View All</Link>]} bodyStyle={{padding: 5}}>
              <RecentSessionsTable sessions={this.state.recentSessions} />
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={24} style={{padding: 10}}>
            <Card title="Goals in Current Plan" actions={[<Link href={`/clients/${this.state.clientUUID}/goals/`} hoverable={false}>View All</Link>]} bodyStyle={{padding: 5}}>
              <Table
                size="mini"
                pagination={false}
                columns={[
                  {title: "Goal", dataIndex: "name"},
                  {title: "Deadline", dataIndex: "deadline"},
                  {title: "Completed", dataIndex: "completed"},
                  {title: "Completed At", dataIndex: "completed_at"},
                  {title: "Operations", dataIndex: "op", align: "right"},
                ]}
              />
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Layout.Content>
    );
  }

  render() {
    let pageContent = this.renderPageContent();
    return(
      <DefaultPageLayout pageHeader="Clients">
        <LoadablePageContent loading={this.state.loading} loadingError={this.state.loadingError}>
          {pageContent}
        </LoadablePageContent>
      </DefaultPageLayout>
    );
  }
}