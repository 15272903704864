import { Popover } from '@arco-design/web-react';
import { IconCopy, IconEdit, IconDelete, IconPlusCircle, IconUpload } from '@arco-design/web-react/icon';


export function StandardTableOperations(props) {

  let operations = [];

  if (props.showAdd) {
    operations.push(
      <Popover trigger='hover' content={<p style={{margin: 0}}>{props.addLabel}</p>}>
        <IconPlusCircle style={{cursor: "pointer", color: "#27C346", marginRight: 10}} fontSize={props.iconSize} onClick={() => {props.onAdd(props.record)}}/>
      </Popover>
    );
  }
  if (props.showEdit) {
    operations.push(
      <Popover trigger='hover' content={<p style={{margin: 0}}>{props.editLabel}</p>}>
        <IconEdit style={{cursor: "pointer", color: "#FF9626", marginRight: 10}} fontSize={props.iconSize} onClick={() => {props.onEdit(props.record)}}/>
      </Popover>
    );
  }
  if (props.showDelete) {
    operations.push(
      <Popover trigger='hover' content={<p style={{margin: 0}}>{props.deleteLabel}</p>}>
        <IconDelete style={{cursor: "pointer", color: "#F76965"}} fontSize={props.iconSize} onClick={() => {props.onDelete(props.record)}}/>
      </Popover>
    );
  }
  if (props.showUpload) {
    operations.push(
      <Popover trigger='hover' content={<p style={{margin: 0}}>{props.uploadLabel}</p>}>
        <IconUpload style={{cursor: "pointer", color: "#3491FA", marginLeft: 10}} fontSize={props.iconSize} onClick={() => {props.onUpload(props.record)}}/>
      </Popover>
    );
  }
  if (props.showCopy) {
    operations.push(
      <Popover trigger='hover' content={<p style={{margin: 0}}>{props.copyLabel}</p>}>
        <IconCopy style={{cursor: "pointer", color: "#3491FA", marginLeft: 10}} fontSize={props.iconSize} onClick={() => {props.onCopy(props.record)}}/>
      </Popover>
    );
  }
  return(
    <div>{operations}</div>
  )
}