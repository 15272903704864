import { Popover } from '@arco-design/web-react';
import { IconEdit, IconDelete, IconPlusCircle } from '@arco-design/web-react/icon';


export const EXERCISE_TYPE_FLAG_TO_DISPLAY_MAP = Object.freeze({
  "FREE_WEIGHT": "Free Weight",
  "MACHINE": "Weight Machine",
  "BODYWEIGHT": "Bodyweight",
  "ISOMETRIC": "Isometric",
  "WEIGHTED_ISOMETRIC": "Weighted Isometric",
  "CARDIO": "Cardio",
});


export const EXERCISE_TYPE_DISPLAY_TO_FLAG_MAP = Object.freeze({
  "Free Weight": "FREE_WEIGHT",
  "Weight Machine": "MACHINE",
  "Bodyweight": "BODYWEIGHT",
  "Isometric": "ISOMETRIC",
  "Weighted Isometric": "WEIGHTED_ISOMETRIC",
  "Cardio": "CARDIO",
});


export function valueOrPlaceholder(value, placeholder) {
  if (value !== null && value !== undefined && value !== "") {
    return value;
  }
  return placeholder;
}


export function PlannedSetOperations(props) {
  let isSet = !props.record.hasOwnProperty("planned_set");
  
  let setOperations = (
    <div style={{alignItems: "flex-end"}}>
      <Popover trigger='hover' content={<p style={{margin: 0}}>Add Superset</p>}>
        <IconPlusCircle style={{cursor: "pointer", color: "#27C346", marginRight: 10}} fontSize={20} onClick={() => {props.onAdd(props.record)}}/>
      </Popover>
      <Popover trigger='hover' content={<p style={{margin: 0}}>Edit Set</p>}>
        <IconEdit style={{cursor: "pointer", color: "#FF9626", marginRight: 10}} fontSize={20} onClick={() => {props.onEdit(props.record)}}/>
      </Popover>
      <Popover trigger='hover' content={<p style={{margin: 0}}>Delete Set</p>}>
        <IconDelete style={{cursor: "pointer", color: "#F76965"}} fontSize={20} onClick={() => {props.onDelete(props.record)}}/>
      </Popover>
    </div>
  );
  let supersetOperations = (
    <div>
      <Popover trigger='hover' content={<p style={{margin: 0}}>Edit Superset</p>}>
        <IconEdit style={{cursor: "pointer", color: "#FF9626", marginRight: 10}} fontSize={20} onClick={() => {props.onEdit(props.record)}}/>
      </Popover>
      <Popover trigger='hover' content={<p style={{margin: 0}}>Delete Superset</p>}>
        <IconDelete style={{cursor: "pointer", color: "#F76965"}} fontSize={20} onClick={() => {props.onDelete(props.record)}}/>
      </Popover>
    </div>
  );

  let content = isSet ? setOperations : supersetOperations;
  return content;
}

export function displayDate(date) {
  let dateString = new Date(date).toLocaleDateString();
  let timeString = new Date(date).toLocaleTimeString()
  return `${dateString} ${timeString}`;
}