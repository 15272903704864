import { Grid, Button, Form, Modal, Input, DatePicker } from '@arco-design/web-react';


export function BodyweightModal(props) {
  return(
    <Modal title={props.title} visible={props.visible} onCancel={() => {props.onCancel();}} footer={null} style={{width: "40%"}}>
      <Form layout="horizontal" onSubmit={(formData) => {props.handleSubmit(formData);}}>
        <Form.Item label="Recorded At" field="recorded_at" rules={[{required: true}]}>
          <DatePicker style={{width: "100%"}} showTime={true}/>
        </Form.Item>
        <Form.Item label='Weight (kg)' field='weight' rules={[{required: true}]}>
          <Input type="number" placeholder="Weight" />
        </Form.Item>
        <Grid.Row>
          <Grid.Col span={20}>
            <Form.Item><Button type='secondary' onClick={() => {props.onCancel();}}>Cancel</Button></Form.Item>
          </Grid.Col>
          <Grid.Col span={4}>
            <Form.Item><Button type='primary' htmlType='submit'>Submit</Button></Form.Item>
          </Grid.Col>
        </Grid.Row>
      </Form>
    </Modal>
  );
}