import React from 'react';
import { Statistic, Button, Pagination, Layout, Spin, Grid, Result, Table, Card, Message} from "@arco-design/web-react";
import { DefaultPageLayout, LoadablePageContent } from '../../common/PageLayout';
import { NavigationButton } from '../common/Navigation';
import { cleanNonRequiredFields, extractUUIDFromObjectList } from '../../utils/forms';
import { createPlan, listPlans, retrievePlanStateCounts } from '../../api/plans';
import { listClients } from '../../api/clients';
import { PlanModal } from '../modals/PlanModal';


export default class PlanListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      planCounts: null,
      loadingCounts: true,
      loadingCountsError: false,

      selectedPage: 1,
      totalPlans: 0,
      loading: true,
      adding: false,
      loadingError: false,
      addModalVisible: false,
      addPlanModalVisible: false,
      creatingPlan: false,
      planTableColumns: [
        {title: "Name", dataIndex: "name"},
        {title: "Client", dataIndex: "client.name"},
        {title: "Start Date", dataIndex: "start_date"},
        {title: "End Date", dataIndex: "end_date"},
        {title: "Operations", align: "right", dataIndex: "op", render: (_, record) => {
          return <NavigationButton text="View" size="mini" type="primary" url="plans" uuid={record.uuid} />
        }}
      ],
      plans: [],
      clients: [],
      clientEmails: [],
    }
    
    // load data methods
    this.loadPlans = this.loadPlans.bind(this);
    this.loadAllClients = this.loadAllClients.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);

    // logic methods
    this.handleAddModalConfirm = this.handleAddModalConfirm.bind(this);

    // render methods
    this.renderPlanCounts = this.renderPlanCounts.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }
  
  async componentDidMount() {
    this.loadPlans(1);
    retrievePlanStateCounts().then((response) => {
      if (!response.ok) {
        Message.error("Could not retrieve plan counts");
        this.setState({loadingCounts: false, loadingCountsError: true});
      }
      return response.json();
    }).then((json) => {
      this.setState({loadingCounts: false, loadingCountsError: false, planCounts: json});
    });

    await this.loadAllClients();
  }

  async loadPlans(page) {

    listPlans({page: page}).then((response) => {
      if (!response.ok) {
        Message.error(`Could not load page ${page} of plans.`)
        this.setState({loading: false, loadingError: true});
        return;
      }
      return response.json();
    }).then((json) => {
      console.log(json);
      this.setState({
        loading: false,
        loadingError: false,
        plans: json.results,
        totalPlans: json.count,
        selectedPage: page,
      });
      return;
    });

  }

  async loadAllClients() {
    this.setState({loading: true, loadingError: false});

    let hasNextPage = true;
    let currentPage = 1;
    let allClients = [];

    while (hasNextPage) {
      let clientResponse = await listClients({page: currentPage});
      if (!clientResponse.ok) {
        Message.error("Could not load clients");
        break;
      }
      let json = await clientResponse.json();
      for (let i = 0; i < json.results.length; i++) {
        allClients.push(json.results[i]);
      }
      hasNextPage = json.next !== null;
      currentPage++;
    }

    let clientEmails = [];
    for (let i = 0; i < allClients.length; i++) {
      clientEmails.push(allClients[i].email);
    }
    this.setState({clients: allClients, clientEmails: clientEmails, loading: false, loadingError: false});
  }

  async handleAddModalConfirm(formData) {
    this.setState({adding: true});

    let clientUUID = extractUUIDFromObjectList(formData.client, this.state.clients, "email");
    formData.client_slot = clientUUID;
    let postData = cleanNonRequiredFields(formData);
    createPlan(postData).then((response) => {
      if (!response.ok) {
        Message.error("Could not create new plan");
        this.setState({addPlanModalVisible: false, adding: false});
        return;
      }
      return response.json();
    }).then((json) => {
      Message.success(`Plan created successfully`);
      let plans = structuredClone(this.state.plans);
      plans.push(json);
      console.log(json);
      this.setState({plans: plans, adding: false, addPlanModalVisible: false});
    });

  }

  async handlePageChange(page) {
    await this.loadPlans(page);
  }

  renderPlanCounts() {
    if (this.state.loadingCounts) {
      return <Grid.Row style={{justifyContent: "center"}}><Spin dot /></Grid.Row>
    }

    if (this.state.loadingCountsError) {
      return (
        <Result
          status='error'
          title='Error'
          subTitle='Something went wrong. Please try again later.'
        />
      );
    }

    let countStats = [];
    for (let i = 0; i < this.state.planCounts.length; i++) {
      let planCount = this.state.planCounts[i];
      countStats.push(
        <Grid.Col span={24/this.state.planCounts.length}>
          <Statistic
            title={planCount.label}
            value={planCount.count}
          />
        </Grid.Col>
      );
    }

    return (
      <Grid.Row>{countStats}</Grid.Row>
    )
  }

  renderContent() {
    return (
      <Layout.Content>
        <Grid.Row>
          <Grid.Col span={24}>
            <Card title="Plan Counts" style={{margin: 10}}>
              {this.renderPlanCounts()}
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={24}>
            <Card
              title="Plan List"
              extra={<Button type="outline" status="primary" size="mini" onClick={() => {this.setState({addPlanModalVisible: true})}}>Add</Button>}
              style={{margin: 10, marginTop: 0}}
              bodyStyle={{padding: 5}}
            >
              <Table
                size="small"
                rowKey={(record) => {return record.uuid}}
                columns={[
                  {title: "Name", dataIndex: "name"},
                  {title: "Client", dataIndex: "client_name"},
                  {title: "State", dataIndex: "state"},
                  {title: "Start Date", dataIndex: "start_date"},
                  {title: "End Date", dataIndex: "end_date"},
                  {title: "Operations", align: "right", dataIndex: "op", render: (_, record) => {
                    return <NavigationButton text="View" size="mini" type="default" url="plans" uuid={record.uuid} />
                  }}
                ]}
                data={this.state.plans}
                pagination={false}
              />
              <Grid.Row>
                <Grid.Col span={24} style={{marginTop: 10, marginBottom: 10}}>
                  <Pagination
                    simple={true}
                    total={this.state.totalPlans}
                    pageSize={20}
                    current={this.state.selectedPage}
                    onChange={(page) => {this.handlePageChange(page)}}
                    style={{float: 'right'}}
                  />
                </Grid.Col>
              </Grid.Row>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Layout.Content>
    );
  }

  render() {
    let pageContent = this.renderContent();
    return <DefaultPageLayout pageHeader="Plans">
      <PlanModal
        title="Add Plan"
        visible={this.state.addPlanModalVisible}
        handleSubmit={this.handleAddModalConfirm}
        onCancel={() => {this.setState({addPlanModalVisible: false})}}
        clientOptions={this.state.clientEmails}
      />
      <LoadablePageContent loading={this.state.loading} loadingError={this.state.loadingError}>
        {pageContent}
      </LoadablePageContent>
    </DefaultPageLayout>
  }
}