
export const REQUEST_TYPES = Object.freeze({
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
});


const BASE_API_URL = `${process.env.REACT_APP_BASE_API_URL}api/v1/`;
export const AUTH_URL = `${BASE_API_URL}auth/obtain-token/`;
export const LOGOUT_URL = `${BASE_API_URL}auth/logout/`;

export const USERS_URL = `${BASE_API_URL}users/`;
export const CLIENT_SLOTS_URL = `${BASE_API_URL}clientslots/`;
export const CREATE_CLIENT_URL = `${BASE_API_URL}users/create_client_slot/`;
export const CREATE_TRAINER_URL = `${BASE_API_URL}users/create_trainer/`;
export const PLANS_URL = `${BASE_API_URL}plans/`;
export const EXERCISES_URL = `${BASE_API_URL}exercises/`;
export const PLANNED_SESSION_URL = `${BASE_API_URL}plannedsessions/`;
export const PERFORMED_SESSION_URL = `${BASE_API_URL}performedsessions/`;
export const WEIGHT_RANGES_URL = `${BASE_API_URL}weightranges/`;
export const PLANNED_SET_URL = `${BASE_API_URL}plannedsets/`;
export const PLANNED_SUPERSET_URL = `${BASE_API_URL}plannedsupersets/`;

export const BODYWEIGHT_URL = `${BASE_API_URL}bodyweights/`;
export const BODYFAT_PERCENTAGES_URL = `${BASE_API_URL}bodyfatpercentages/`;
export const SESSIONS_URL = `${BASE_API_URL}performedsessions/`;
export const PERFORMED_SETS_URL = `${BASE_API_URL}performedexercisesets/`;
export const PROGRESS_PICTURES_URL = `${BASE_API_URL}progresspictures/`;
export const GOALS_URL = `${BASE_API_URL}goals/`;
export const METRICS_URL = `${BASE_API_URL}metrics/`;