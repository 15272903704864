import React from 'react';
import { Grid, Result, Spin } from "@arco-design/web-react";

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let intensity = "N/A";
    let volume = "N/A";
    for (let i = 0; i < payload.length; i++) {
      let element = payload[i];
      if (element.name === "volume") {
        volume = parseFloat(element.value.toFixed(4));
      }
      if (element.name === "intensity") {
        intensity = parseFloat(element.value.toFixed(4));
      }
    }
    return (
      <div className="custom-tooltip" style={{backgroundColor: "#2e2e30", paddingLeft: 5, paddingRight: 5, color: "#dfdfdf", borderRadius: 5}}>
        <p style={{fontWeight: "bold", letterSpacing: 0.5}}>{payload[0].payload.name}</p>
        <p><b>Volume:</b> {volume}</p>
        <p><b>Intensity:</b> {intensity}</p>
      </div>
    );
  }
}


export function PlanVolumeGraph(props) {

  let graphData = [];
  for (let i = 0; i < props.data.length; i++) {
    let dataPoint = props.data[i];
    dataPoint.name = dataPoint.planned_session.name;
    graphData.push(dataPoint);
  }

  if (props.loading) {
    return (
      <Grid.Row style={{justifyContent: "center"}}>
        <Spin dot style={{ marginTop: 100 }}/>
      </Grid.Row>
    );
  }
  if (props.error) {
    return (
      <Result
        status='error'
        title='Error'
        subTitle='Something went wrong. Please try again later.'
      />
    );
  }

  return(
    <ResponsiveContainer width="100%" height={330}>
      <BarChart data={graphData}>
      <CartesianGrid strokeDasharray="3 3" stroke="#484849"/>
      <XAxis />
      <Tooltip cursor={{fill: "rgba(100, 100, 100, 0.5)"}}  content={<CustomTooltip />} />
      <YAxis
          yAxisId={1}
          orientation="left"
          label={{ value: 'Volume', angle: -90, position: 'insideLeft', fill: "#3491FA"}}
        />
        <YAxis
          yAxisId={2}
          orientation="right"
          domain={[0, 1]}
          label={{ value: 'Intensity', angle: -90, position: "insideRight", dy: -75, fill: "#F5319D"}}
        />
      <Bar yAxisId={1} type="monotone" dataKey="volume" fill="#3491FA" />
      <Bar yAxisId={2} type="monotone"  dataKey="intensity" fill="#F5319D" />
      </BarChart>
    </ResponsiveContainer>
  );
}