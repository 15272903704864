import { apiGetRequest, apiPatchRequest, apiPostRequest, listAllObjects, makeGetRequest, uploadFile } from "./apiRequest";
import { EXERCISES_URL } from "./conf";


export async function listExercisesOld(params) {
  return await makeGetRequest(EXERCISES_URL, params);
}

export async function retrieveExercise(uuid) {
  let url = `${EXERCISES_URL}${uuid}`;
  return await makeGetRequest(url, null);
}

export async function uploadExerciseCSV(data) {
  return await uploadFile(`${EXERCISES_URL}upload/`, data);
}


export async function listAllExercises() {
  let page = 1;
  let exercisesError = false;
  let exercises = [];
  let hasNextPage = true;
  while (hasNextPage) {
    let exercisesResponse = await listExercisesOld({page: page});
    if (!exercisesResponse.successful) {
      exercisesError = true;
      break;
    }
    const exerciseResults = exercisesResponse.data.results;
    if (exerciseResults) {
      exercises.push(...exerciseResults);
    }
    hasNextPage = exercisesResponse.data.next;
    page++;
  }

  if (exercisesError) {
    return [];
  }

  return exercises;
}


export async function retrieveExerciseTypeCounts() {
  return apiGetRequest(`${EXERCISES_URL}type_counts/`);
}

export async function listExercises(params) {
  return apiGetRequest(EXERCISES_URL, params);
}

export async function listAllExercisesNew(params) {
  return listAllObjects(EXERCISES_URL, params);
}

export async function createExercise(data) {
  return apiPostRequest(EXERCISES_URL, data);
}

export async function updateExercise(uuid, data) {
  return apiPatchRequest(`${EXERCISES_URL}${uuid}/`, data);
}