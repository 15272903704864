import React from 'react';
import { EXERCISES_URL } from '../../api/conf';
import { Upload, Button, Modal } from '@arco-design/web-react';



export function UploadModal(props) {
  const uploadRef = React.useRef();
  const [disabled, setDisabled] = React.useState(false);
  const [fileList, setFileList] = React.useState([]);

  const onSubmit = (e, isFirst) => {
    e.stopPropagation();
    const file = isFirst ? fileList.filter((x) => x.status === 'init')[0] : null;
    uploadRef.current && uploadRef.current.submit(file);
  };

  const onChange = (files) => {
    setFileList(files);
    setDisabled(!files.some((x) => x.status === 'init'));
  };

  const onProgress = (file) => {
    setFileList((files) => {
      return files.map((x) => (x.uid === file.uid ? file : x));
    });
  };

  let token = localStorage.getItem("PG_API_TOKEN");

  return (
    <Modal
      title={props.title}
      visible={props.visible}
      onCancel={() => {props.onCancel()}}
      footer={null}
    >
      <Upload
        ref={uploadRef}
        multiple
        autoUpload={false}
        headers={{'Authorization': `Token ${token}`}}
        action={`${EXERCISES_URL}upload/`}
        onChange={onChange}
        onProgress={onProgress}
        fileList={fileList}
      >
        <Button>Select file</Button>
        <Button type='primary' onClick={onSubmit} disabled={disabled}>
          Start upload
        </Button>
      </Upload>
    </Modal>
  );
}
