import React from 'react';
import { Grid, Button, Form, Modal, Input, AutoComplete } from '@arco-design/web-react';


export function PlanModal(props) {

  const [form] = Form.useForm();

  let instance = props.instance;
  let instanceExists = instance !== null && instance !== undefined;

  let initialValues = {
    name: null,
    start_date: null,
    end_date: null,
  };

  if (instanceExists) {
    initialValues.name = instance.name;
    initialValues.start_date = instance.start_date;
    initialValues.end_date = instance.end_date;
    form.setFieldsValue(initialValues);
  }

  let clientSelector;
  if (!instanceExists) {
    clientSelector = (
      <Form.Item label='Client' field='client' rules={[{required: true}]}>
        <AutoComplete
          placeholder='Client email...'
          onSearch={() => {}}
          data={props.clientOptions}
        />
      </Form.Item>
    );
  }

  return (
    <Modal
      title={props.title}
      visible={props.visible}
      onCancel={() => {props.onCancel()}}
      footer={null}
    >
    <Form form={form} onSubmit={(formData) => {props.handleSubmit(formData)}}>
      <Form.Item label="Name" field="name" rules={[{required: true}]}>
        <Input placeholder="Please enter plan name" />
      </Form.Item>
      { clientSelector }
      <Form.Item label='Start Date' field='start_date'>
        <Input type="date" placeholder="Start date" />
      </Form.Item>
        <Form.Item label='Start Date' field='end_date'>
      <Input type="date" placeholder="Start date" />
      </Form.Item>
      <Grid.Row>
        <Grid.Col span={20}>
          <Form.Item><Button type='secondary' onClick={() => {props.onCancel()}}>Cancel</Button></Form.Item>
        </Grid.Col>
        <Grid.Col span={4}>
          <Form.Item><Button type='primary' loading={props.adding} htmlType='submit'>Submit</Button></Form.Item>
        </Grid.Col>
      </Grid.Row>
    </Form>
    </Modal>
  );
}