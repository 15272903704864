import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';

import { Button } from "@arco-design/web-react";
import {loadStripe } from '@stripe/stripe-js';


loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);



export const PaymentMethodForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }


    props.onSubmit(stripe, elements);

  };

  let button;
  if (props.processing) {
    button = <Button type='primary' htmlType='submit' loading style={{ marginTop: 10 }}>Processing...</Button>
  } else {
    button = <Button type='primary' htmlType='submit' style={{ marginTop: 10 }}>Submit</Button>
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div align="right">
        {button}
      </div>
    </form>
  );
}