import React, { useState } from 'react';
import { DatePicker, Grid, Button, Form, Modal, Input, Select, AutoComplete } from '@arco-design/web-react';



export function GoalModal(props) {
  const [form] = Form.useForm();
  const [type, setType] = useState("");
  const [typeChanged, setTypeChanged] = useState(false);
  let instance = props.instance;
  let instanceExists = instance !== null && instance !== undefined;

  let initialValues = {
    exercise: null,
    reps: null,
    duration: null,
    distance: null,
    weight: null,
    deadline: null,
    type: null,
  };

  if (instanceExists) {
    initialValues.exercise = instance.exercise.name;
    initialValues.reps = instance.reps;
    initialValues.duration = instance.duration;
    initialValues.distance = instance.distance;
    initialValues.weight = instance.weight;
    initialValues.deadline = instance.deadline;
    initialValues.type = instance.type;
    form.setFieldsValue(initialValues);
  };

  let metricWidget;

  if (typeChanged === false && instanceExists) {
    switch (initialValues.goal_type) {
      case "Reps":
        metricWidget = <>
          <Form.Item label='Reps' field='reps'><Input type="number" placeholder="Reps" /></Form.Item>
          <Form.Item label='Weight (kg)' field='weight'><Input type="number" placeholder="Weight" /></Form.Item>
        </>
        break;
      case "Duration":
        metricWidget = <Form.Item label='Duration (s)' field='duration'><Input type="number" placeholder="Duration" /></Form.Item>
        break;
      case "Distance":
        metricWidget = <Form.Item label='Distance (m)' field='distance'><Input type="number" placeholder="Distance" /></Form.Item>
        break;
      case "Weight":
        metricWidget = <Form.Item label='Weight (kg)' field='weight'><Input type="number" placeholder="Weight" /></Form.Item>
        break;
      case "Speed":
        metricWidget = <Form.Item label='Weight (kg)' field='weight'><Input type="number" placeholder="Weight" /></Form.Item>
          break;
      default:
        metricWidget = <></>
        break;
    }
  } else {
    switch (type) {
      case "Reps":
        metricWidget = <>
          <Form.Item label='Reps' field='reps'><Input type="number" placeholder="Reps" /></Form.Item>
          <Form.Item label='Weight (kg)' field='weight'><Input type="number" placeholder="Weight" /></Form.Item>
        </>
        break;
      case "Duration":
        metricWidget = <Form.Item label='Duration (s)' field='duration'><Input type="number" placeholder="Duration" /></Form.Item>
        break;
      case "Distance":
        metricWidget = <Form.Item label='Distance (m)' field='distance'><Input type="number" placeholder="Distance" /></Form.Item>
        break;
      case "Weight":
        metricWidget = <Form.Item label='Weight (kg)' field='weight'><Input type="number" placeholder="Weight" /></Form.Item>
        break;
      case "Speed":
        metricWidget = <>
          <Form.Item label='Duration (s)' field='duration'><Input type="number" placeholder="Duration" /></Form.Item>
          <Form.Item label='Distance (m)' field='distance'><Input type="number" placeholder="Distance" /></Form.Item>
        </>
        break;
      default:
        metricWidget = <></>
        break;
    }
  }

  return (
    <Modal title={props.title} visible={props.visible} onCancel={() => {props.handleCancel();}} footer={null}>
      <Form form={form} layout="horizontal" onSubmit={(formData) => {props.handleSubmit(formData);}}>
        <Form.Item label="Goal Type" rules={[{required: true}]} field="exercise_type">
          <Select options={["Reps", "Weight", "Duration", "Distance", "Speed"]} onChange={(value) => {setType(value); setTypeChanged(true)}}/>
        </Form.Item>
        <Form.Item label='Exercise' field='exercise' rules={[{required: true}]}>
          <AutoComplete
            placeholder='Start typing...'
            onSearch={() => {}}
            data={props.exerciseOptions}
          />
        </Form.Item>
        {metricWidget}
        <Form.Item label='Deadline' field='deadline'>
          <DatePicker style={{width: "100%"}} showTime={true}/>
        </Form.Item>
        <Grid.Row>
          <Grid.Col span={20}>
            <Form.Item><Button type='secondary' onClick={() => {props.handleCancel();}}>Cancel</Button></Form.Item>
          </Grid.Col>
          <Grid.Col span={4}>
            <Form.Item><Button type='primary' htmlType='submit'>Submit</Button></Form.Item>
          </Grid.Col>
        </Grid.Row>
      </Form>
      <p style={{letterSpacing: 0.5}}>Metric goals coming soon (e.g. bodyfat percentages)</p>
    </Modal>
  );

}