import React from 'react';
import { DefaultPageLayout, LoadablePageContent } from '../../common/PageLayout';
import { Table, Pagination, Spin, Message, Result, Button, Card, Grid, Layout } from "@arco-design/web-react";
import { BodyCompositionGraph } from '../graphs/BodyCompositionGraph';
import { retrieveBodyCompositionTimeSeries } from '../../api/clients';
import { BodyweightModal } from '../modals/BodyweightModals';
import { BodyfatModal } from '../modals/BodyfatModals';
import { createBodyweight, deleteBodyweight, listBodyweights } from '../../api/bodyweights';
import { DeleteModal } from '../modals/common';
import { createBodyfatPercentage, deleteBodyfatPercentage, listBodyfatPercentages } from '../../api/bodyfats';
import { StandardTableOperations } from '../common/StandardTableOperations';


export default class BodyCompositionPage extends React.Component {
  constructor(props) {
    super(props);
    
    let uuid = window.location.pathname.split("/")[2];
    this.state = {
      loading: false,
      loadingError: false,
      adding: false,
      
      loadingGraph: true,
      graphError: false,
      graphData: null,
      
      bodyweights: [],
      bodyweightPage: 1,
      totalBodyweights: 0,
      loadingBodyweights: true,
      loadingBodyweightsError: false,
      addBodyweightModalVisible: false,
      editBodyweightModalVisible: false,
      deleteBodyweightModalVisible: false,

      bodyfats: [],
      bodyfatPage: 1,
      totalBodyfats: 0,
      loadingBodyfats: true,
      loadingBodyfatsError: false,
      addBodyfatModalVisible: false,
      editBodyfatModalVisible: false,
      deleteBodyfatModalVisible: false,
      
      selectedBodyweightUUID: null,
      selectedBodyfatUUID: null,
      clientUUID: uuid,

    }

    this.loadBodyweightPage = this.loadBodyweightPage.bind(this);
    this.loadBodyfatPage = this.loadBodyfatPage.bind(this);
    this.loadGraphData = this.loadGraphData.bind(this);

    this.submitAddBodyweightForm = this.submitAddBodyweightForm.bind(this);
    this.confirmBodyweightDelete = this.confirmBodyweightDelete.bind(this);
    
    this.submitAddBodyfatForm = this.submitAddBodyfatForm.bind(this);
    this.confirmBodyfatDelete = this.confirmBodyfatDelete.bind(this);

    // page elements
    this.renderContent = this.renderContent.bind(this);
  }

  async componentDidMount() {
    this.loadBodyweightPage(1);
    this.loadBodyfatPage(1);
    this.loadGraphData();
  }

  async loadBodyweightPage(page) {
    this.setState({loadingBodyweights: true});
    listBodyweights({client: this.state.clientUUID, page: page}).then((response) => {
      if (!response.ok) {
        throw new Error("LOADING_BODYWEIGHTS_ERROR");
      }
      return response.json();
    }).then((json) => {
      this.setState({
        loadingBodyweights: false,
        loadingBodyweightsError: true,
        bodyweightPage: page,
        bodyweights: json.results,
        totalBodyweights: json.count,
      })
      return;
    }).catch((error) => {
      Message.error("Could not load bodyweights");
      this.setState({loadingBodyweights: false, loadingBodyweightsError: true, bodyweightPage: page});
    });
  }

  async loadBodyfatPage(page) {
    this.setState({loadingBodyfats: true});
    listBodyfatPercentages({client: this.state.clientUUID, page: page}).then((response) => {
      if (!response.ok) {
        throw new Error("LOADING_BODYFATS_ERROR");
      }
      return response.json();
    }).then((json) => {
      this.setState({
        loadingBodyfats: false,
        loadingBodyfatsError: true,
        bodyfatPage: page,
        bodyfats: json.results,
        totalBodyfats: json.count,
      })
      return;
    }).catch((error) => {
      Message.error("Could not load bodyfats");
      this.setState({loadingBodyfats: false, loadingBodyfatsError: true, bodyfatPage: page});
    });
  }

  async loadGraphData() {
    this.setState({loadingGraph: true});
    retrieveBodyCompositionTimeSeries(this.state.clientUUID).then((response) => {
      if (!response.ok) {
        throw new Error("LOADING_GRAPH_ERROR");
      }
      return response.json();
    }).then((json) => {
      this.setState({graphData: json, loadingGraph: false, graphError: false});
    }).catch((error) => {
      this.setState({graphLoading: false, graphError: true});
    });
  }

  async submitAddBodyweightForm(formData) {
    formData.client = this.state.clientUUID;

    createBodyweight(formData).then((response) => {
      if (!response.ok) {
        Message.error("Could not add bodyweight");
        return;
      }
      return response.json();
    }).then((json) => {
      let bodyweights = structuredClone(this.state.bodyweights);
      bodyweights.push(json);
      this.setState({addBodyweightModalVisible: false, bodyweights: bodyweights});
    });
  }

  async confirmBodyweightDelete() {
    deleteBodyweight(this.state.selectedBodyweightUUID).then((response) => {
      if (!response.ok) {
        throw new Error("DELETE_BODYWEIGHT_ERROR");
      }
      Message.success("Bodyweight record deleted successfully.");
      let newBodyweights = [];
      for (let i = 0; i < this.state.bodyweights.length; i++) {
        let bodyweight = this.state.bodyweights[i];
        if (bodyweight.uuid !== this.state.selectedBodyweightUUID) {
          newBodyweights.push(bodyweight);
        }
      }
      this.setState({deleteBodyweightModalVisible: false, bodyweights: newBodyweights});
    }).catch((error) => {
      Message.error("Could not delete selected bodyweight.");
      this.setState({deleteBodyweightModalVisible: false});
    });

  }

  async submitAddBodyfatForm(formData) {
    
    formData.client = this.state.clientUUID;
    createBodyfatPercentage(formData).then((response) => {
      if (!response.ok) {
        throw new Error("CREATE_BODYFAT_PERCENTAGE_ERROR");
      }
      return response.json();
    }).then((json) => {
      Message.success("Bodyfat percentage record added successfully");
      let bodyfats = structuredClone(this.state.bodyfats);
      bodyfats.push(json);
      this.setState({addBodyfatModalVisible: false, bodyfats: bodyfats});
    }).catch((error) => {
      Message.error("Could not create new bodyfat record.");
      this.setState({addBodyfatModalVisible: false});
    });

  }

  async confirmBodyfatDelete() {

    deleteBodyfatPercentage(this.state.selectedBodyfatUUID).then((response) => {
      if (!response.ok) {
        throw new Error("DELETE_BODYFAT_ERROR");
      }
      Message.success("Bodyfat record deleted successfully.");
      let newBodyfats = [];
      for (let i = 0; i < this.state.bodyfats.length; i++) {
        let bodyfat = this.state.bodyfats[i];
        if (bodyfat.uuid !== this.state.selectedBodyfatUUID) {
          newBodyfats.push(bodyfat);
        }
      }
      this.setState({deleteBodyfatModalVisible: false, bodyfats: newBodyfats});
    }).catch((error) => {
      Message.error("Could not delete selected bodyfat.");
      this.setState({deleteBodyfatModalVisible: false});
    });

  }

  renderContent() {
    if (this.state.loading) {
      return(
        <Grid.Row style={{justifyContent: "center"}}>
          <Spin dot style={{ marginTop: 100 }}/>
        </Grid.Row>
      )
    }
    if (this.state.loadingError) {
      return (
        <Result
          status='error'
          title='Error'
          subTitle='Something went wrong. Please try again later.'
        />
      );
    }

    return (
      <Layout.Content>
        <BodyweightModal
          title="Add Bodyweight"
          visible={this.state.addBodyweightModalVisible}
          handleSubmit={(formData) => this.submitAddBodyweightForm(formData)}
          onCancel={() => {this.setState({addBodyweightModalVisible: false});}}
        />
        <BodyfatModal
          title="Add Bodyfat Percentage"
          visible={this.state.addBodyfatModalVisible}
          handleSubmit={(formData) => this.submitAddBodyfatForm(formData)}
          onCancel={() => {this.setState({addBodyfatModalVisible: false});}}
        />
        <DeleteModal
          visible={this.state.deleteBodyweightModalVisible}
          title="Delete Bodyweight"
          message="Are you sure? This cannot be undone."
          handleConfirm={() => {this.confirmBodyweightDelete()}}
          onCancel={() => {this.setState({deleteBodyweightModalVisible: false})}}
        />
        <DeleteModal
          visible={this.state.deleteBodyfatModalVisible}
          title="Delete Bodyfat Percentage"
          message="Are you sure? This cannot be undone."
          handleConfirm={() => {this.confirmBodyfatDelete()}}
          onCancel={() => {this.setState({deleteBodyfatModalVisible: false})}}
        />
        <Grid.Row>
          <Grid.Col span={24} style={{padding: 10}}>
            <Card
              title="Body Composition Over Time"
              style={{height: "400px"}}
              extra={<Button type="outline" status="primary" size="mini" onClick={() => {this.loadGraphData()}}>Refresh</Button>}
            >
              {this.state.graphError ? <Result title="Could not load graph"/> : <BodyCompositionGraph loading={this.state.graphLoading} error={this.state.graphError} uuid={this.state.clientUUID} data={this.state.graphData} />}
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={12} style={{paddingLeft: 10, paddingRight: 5}}>
            <Card
              title="Recorded Bodyweights"
              extra={
                <Button
                  type="outline"
                  status="primary"
                  size="mini"
                  onClick={() => {this.setState({addBodyweightModalVisible: true})}}
                  >
                    Add
                </Button>
              }
            >
              <div style={{maxHeight: 400, overflowY: "auto"}}>
                <Table
                  loading={this.state.loadingBodyweights}
                  data={this.state.bodyweights}
                  columns={
                    [
                      {title: "Recorded At", dataIndex: "recorded_at", render: (_, record) => {
                        let d = new Date(record.recorded_at);
                        return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`
                      }},
                      {title: "Bodyweight", dataIndex: "weight", render: (_, record) => (`${record.weight}kg`)},
                      {title: "Operations", dataIndex: "op", align: "right", render: (_, record) => (
                        <StandardTableOperations
                          record={record}
                          showAdd={false}
                          showDelete={true}
                          iconSize={20}
                          deleteLabel={"Delete"}
                          onDelete={(record) => {this.setState({deleteBodyweightModalVisible: true, selectedBodyweightUUID: record.uuid})}}
                        />
                      )}
                    ]
                  }
                  pagination={false}
                  rowKey={(record) => {return record.uuid}}
                />
                <Grid.Row>
                  <Grid.Col span={24} style={{marginTop: 10}}>
                    <Pagination simple={true} pageSize={20} total={this.state.totalBodyweights} onChange={(page) => {this.loadBodyweightPage(page)}} />
                  </Grid.Col>
                </Grid.Row>
              </div>
            </Card>
          </Grid.Col>
          <Grid.Col span={12} style={{paddingLeft: 5, paddingRight: 10}}>
            <Card title="Recorded Bodyfat Percentages" extra={<Button type="outline" status="primary" size="mini" onClick={() => {this.setState({addBodyfatModalVisible: true})}}>Add</Button>}>
              <div style={{maxHeight: 400, overflowY: "auto"}}>
              <Table
                loading={this.state.loadingBodyfats}
                data={this.state.bodyfats}
                columns={[
                  {title: "Recorded At", dataIndex: "recorded_at", render: (_, record) => {
                    let d = new Date(record.recorded_at);
                    return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`
                  }},
                  {title: "Bodyfat", dataIndex: "percentage", render: (_, record) => (`${record.percentage}%`)},
                  {title: "Operations", dataIndex: "op", align: "right", render: (_, record) => (
                    <StandardTableOperations
                      record={record}
                      showAdd={false}
                      showEdit={false}
                      showDelete={true}
                      iconSize={20}
                      deleteLabel={"Delete"}
                      onDelete={(record) => {this.setState({deleteBodyfatModalVisible: true, selectedBodyfatUUID: record.uuid})}}
                    />
                  )}
                ]}
                pagination={false}
                rowKey={(record) => {return record.uuid}}
              />
              <Grid.Row>
                <Grid.Col span={24} style={{marginTop: 10}}>
                  <Pagination simple={true} pageSize={20} total={this.state.totalBodyfats} onChange={(page) => {this.loadBodyfatPage(page)}} />
                </Grid.Col>
              </Grid.Row>
              </div>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <br/>
      </Layout.Content>
    );
  }

  render() {
    let pageContent = this.renderContent();
    return <DefaultPageLayout pageHeader={"Client Body Composition"}>
      <LoadablePageContent loading={this.state.loading} loadingError={this.state.loadingError}>
        {pageContent}
      </LoadablePageContent>
    </DefaultPageLayout>
  }
}