import React, { useCallback, useState } from 'react';
import { Grid, Button, Radio, Form, Modal, Input, Select, AutoComplete } from '@arco-design/web-react';




const WEIGHT_RANGE_FLAG = "WEIGHT_RANGE";
const FIXED_WEIGHT_FLAG = "FIXED_WEIGHT";

// TODO: ensure unique exercise names on FE
export function PlannedSetModal(props) {
  const [form] = Form.useForm();
  const [weightType, setWeightType] = useState("");
  const [exerciseType, setExerciseType] = useState("");

  const onWeightTypeSelection = useCallback((event) => {
    setWeightType(event.target.value);
  }, []);

  const onExerciseSelection = useCallback((selectedName) => {
    for (let i = 0; i < props.exercises.length; i++) {
      let currentExercise = props.exercises[i];
      if (currentExercise.name === selectedName) {
        setExerciseType(currentExercise.exercise_type);
        break;
      }
    }
  }, [props.exercises]);

  const displayWeightTypeSelector = useCallback((exerciseType) => {
    let selectedExerciseType;
    if (props.instance) {
      selectedExerciseType = props.instance.exercise.exercise_type;
    } else {
      selectedExerciseType = exerciseType;
    }
    return !["", "CARDIO", "BODYWEIGHT", "ISOMETRIC"].includes(selectedExerciseType);
  }, [props.instance]);


  let instance = props.instance;
  let instanceExists = instance !== null && instance !== undefined;

  let initialValues = {
    exercise: null,
    weight_range: null,
    reps: null,
    duration: null,
    distance: null,
    weight: null,
  };

  if (instanceExists) { 
    initialValues.exercise = instance.exercise.name;
    initialValues.weight_range = [null, undefined].includes(instance.weight_range) ? null : instance.weight_range.name;
    initialValues.reps = instance.reps;
    initialValues.duration = instance.duration;
    initialValues.distance = instance.distance;
    initialValues.weight = instance.weight;
    form.setFieldsValue(initialValues);
  };

  return (
    <Modal title={props.title} visible={props.visible} onCancel={() => {props.onCancel();}} footer={null} style={{width: "50vw"}}>
      <Form form={form} layout="horizontal" onSubmit={(formData) => {props.handleSubmit(formData)}} initialValues={props.initialValues}>
        <Form.Item label='Exercise' field='exercise' rules={[{required: true}]}>
          <AutoComplete
            placeholder='Start typing...'
            onSearch={() => {}}
            data={props.exerciseOptions}
            onSelect={onExerciseSelection}
          />
        </Form.Item>
        {displayWeightTypeSelector(exerciseType) ?
        <Form.Item label="Weight Type" rules={[{required: true}]} onChange={onWeightTypeSelection}>
          <Radio.Group type="button" defaultValue="WEGIHT_RANGE">
            <Radio value={WEIGHT_RANGE_FLAG}>Weight Range</Radio>
            <Radio value={FIXED_WEIGHT_FLAG}>Fixed Weight</Radio>
          </Radio.Group>
        </Form.Item>: <></>}
        {weightType === WEIGHT_RANGE_FLAG && !["BODYWEIGHT", "ISOMETRIC", "CARDIO"].includes(exerciseType) ? <Form.Item label="Weight Range" field="weight_range">
          <Select options={props.weightRangeOptions}/>
        </Form.Item> : <></>}
        {weightType === FIXED_WEIGHT_FLAG && !["BODYWEIGHT", "ISOMETRIC", "CARDIO"].includes(exerciseType) ? <Form.Item label='Weight (kg)' field='weight'>
          <Input type="number" placeholder="Weight" />
        </Form.Item> : <></>}

{/* 
FREE_WEIGHT
MACHINE
BODYWEIGHT
ISOMETRIC
WEIGHTED_ISOMETRIC
CARDIO
*/}
        {!["CARDIO", ""].includes(exerciseType) ? <Form.Item label='Reps' field='reps'>
          <Input type="number" placeholder="Reps" />
        </Form.Item> : <></>}
        {["ISOMETRIC", "WEIGHTED_ISOMETRIC", "CARDIO"].includes(exerciseType) ? <Form.Item label='Duration (s)' field='duration'>
          <Input type="number" placeholder="Duration" />
        </Form.Item> : <></>}
        {exerciseType === "CARDIO" ? <Form.Item label='Distance (m)' field='distance'>
          <Input type="number" placeholder="Distance" />
        </Form.Item> : <></>}
        <Grid.Row>
          <Grid.Col span={20}>
            <Form.Item><Button type='secondary' onClick={() => {props.onCancel();}}>Cancel</Button></Form.Item>
          </Grid.Col>
          <Grid.Col span={4}>
            <Form.Item><Button type='primary' htmlType='submit'>Submit</Button></Form.Item>
          </Grid.Col>
        </Grid.Row>
      </Form>
    </Modal>
  );
}
