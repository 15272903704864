import React from 'react';
import { Layout, Spin, Grid, Result, Empty} from "@arco-design/web-react";
import { DefaultPageLayout } from '../../common/PageLayout';
import { IconTool } from '@arco-design/web-react/icon';


export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuCollapsed: false,
      loading: false,
      numberOfClients: null,
      numberOfPlans: null,
      numberOfSessions: null,
      loadingError: false
    }

    this.renderContent = this.renderContent.bind(this);

  }

  renderContent() {
    if (this.state.loading) {
      return(
        <Grid.Row style={{justifyContent: "center"}}>
          <Spin dot style={{ marginTop: 100 }}/>
        </Grid.Row>
      )
    }
    if (this.state.loadingError) {
      return (
        <Result
          status='error'
          title='Error'
          subTitle='Something went wrong. Please try again later.'
        />
      );
    }

    return (
      <Layout.Content>
        <Empty
          icon={<IconTool/>}
          description="Dashboard coming soon..."
        />
        {/* <Grid.Row>
          <Grid.Col span={8} style={{padding: 10}}>
            <Card title="Clients"></Card>
          </Grid.Col>
          <Grid.Col span={8} style={{padding: 10}}>
            <Card title="Plans"></Card>
          </Grid.Col>
          <Grid.Col span={8} style={{padding: 10}}>
            <Card title="Recorded Sessions"></Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={24} style={{paddingTop: 10}}>
            <Card title="Activity"></Card>
          </Grid.Col>
        </Grid.Row> */}
      </Layout.Content>
    );
  }

  render() {
    let pageContent = this.renderContent();
    return <DefaultPageLayout pageHeader="Dashboard">{pageContent}</DefaultPageLayout>
  }
}