import React from 'react';
import { Grid, Button, Form, Modal, Input } from '@arco-design/web-react';



export function PlannedSessionModal(props) {
  const [form] = Form.useForm();

  let instance = props.instance;
  let instanceExists = instance !== null && instance !== undefined;

  let initialValues = {
    name: null,
    notes: null,
  };
  
  if (instanceExists) {
    initialValues.name = instance.name;
    initialValues.notes = instance.notes;
    form.setFieldsValue(initialValues);
  }

  return (
    <Modal title={props.title} visible={props.visible} onCancel={() => {props.onCancel();}} footer={null} style={{width: "40%"}}>
      <Form form={form} layout="horizontal" onSubmit={(formData) => {props.handleSubmit(formData)}} initialValues={props.initialValues}>
        <Form.Item label='Name' field='name' rules={[{required: true}]}>
          <Input type="text" placeholder="Enter name..." />
        </Form.Item>
        <Form.Item label='Notes' field='notes'>
          <Input.TextArea
            placeholder='Enter notes'
            autoSize={{ minRows: 2, maxRows: 6 }}
            style={{ width: 350 }}
          />
        </Form.Item>
        <Grid.Row>
          <Grid.Col span={20}>
            <Form.Item><Button type='secondary' onClick={() => {props.onCancel();}}>Cancel</Button></Form.Item>
          </Grid.Col>
          <Grid.Col span={4}>
            <Form.Item><Button type='primary' htmlType='submit'>Submit</Button></Form.Item>
          </Grid.Col>
        </Grid.Row>
      </Form>
    </Modal>
  );
}