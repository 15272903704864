import { BaseHelpPage } from "../../../common/PageLayout";
import { Link, Grid, Typography } from "@arco-design/web-react";
import { IconLink } from "@arco-design/web-react/icon";


export function ClientsManageHelpPage(props) {
  return (
    <BaseHelpPage>
      <Grid.Row>
        <Grid.Col span={24} style={{margin: 10}}>
          <Typography.Title>Managing a Client</Typography.Title>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={24} style={{margin: 10}}>
          <Typography.Paragraph>
          To add a client, press the "Add" button at the top right of the clients list, this will open the "add client" form.
          Once the form is submitted, one of your available client slots will be used. If you need to add more clients but have no more available slots, more can be added to your subscription from the billing management page.
          </Typography.Paragraph>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={24} style={{margin: 10}}>
          <Typography.Title heading={4}>Related Topics:</Typography.Title>
          <Typography.Paragraph>
            <Link href='#' icon={<IconLink />}>Viewing Your Clients</Link><br/>
            <Link href='#' icon={<IconLink />}>Creating a Plan</Link><br/>
            <Link href='#' icon={<IconLink />}>Managing Body Composition</Link><br/>
          </Typography.Paragraph>
        </Grid.Col>
      </Grid.Row>
    </BaseHelpPage>
  );
}