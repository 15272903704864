import React from 'react';

import { Table } from "@arco-design/web-react";
import { NavigationButton } from './Navigation';


export function RecentSessionsTable(props) {

  return(
    <div style={{minHeight: "100%", maxHeight: "100%", overflowY: "auto"}}>
      <Table
        size="mini"
        columns={[
          {title: "Name", dataIndex: "_", render: (_, record) => [null, undefined].includes(record.planned_session) ? "-" : `${record.planned_session.plan_name}: ${record.planned_session.name}`},
          {title: "Performed At", dataIndex: "performed_at"},
          {title: "Options", dataIndex: "op", align: "right", render: (_, record) => <NavigationButton text="View" size="mini" status="primary" url="performedsessions" uuid={record.uuid} />}
        ]}
        data={props.sessions}
        pagination={false}
        rowKey={(record) => {return record.uuid}}
      />
    </div>
  );
}