import { apiPostRequest } from "./apiRequest";
import { USERS_URL } from "./conf";


export async function setPaymentMethod(data) {
  return await apiPostRequest(`${USERS_URL}set_payment_method/`, data);
}

export async function setSubscriptionClients(data) {
  return await apiPostRequest(`${USERS_URL}set_subscription/`, data);
}