import React from 'react';
import { Navigate } from 'react-router-dom';
import { Button, Form, Input, Grid, Card, Message, Spin} from "@arco-design/web-react";
import { AUTH_URL, REQUEST_TYPES} from '../../api/conf';


export default class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      loading: false,
      success: false
    }
    this.renderForm = this.renderForm.bind(this);
  }

  async handleSubmit(values) {
    this.setState({loading: true});
    let response = await fetch(
      AUTH_URL,
      {
        method: REQUEST_TYPES.POST,
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({username: this.state.email, password: this.state.password})
      }
    );
    
    try {
      if (response.status >= 400 && response.status < 500) {
        throw new Error("Could not log in with the provided credentials.");
      } else if (response.status >= 500) {
        throw new Error("Something went wrong. Please try again later.");
      } else if (response.status === 200) {
        let json = await response.json();
        let token = json.token;
        localStorage.setItem('PG_API_TOKEN', token);
        Message.success("Login successful");
        this.setState({success: true});
      } else {
        throw new Error("Unhandled response. Please try again later.");
      }
    } catch (err) {
      this.setState({loading: false});
      Message.error(err.message);
    }

  }


  renderForm() {
    if (this.state.success) {
      return <Navigate to="/dashboard" replace={true} />
    }
    if (this.state.loading) {
      return <Grid.Row style={{alignItems: "center", justifyContent: "center"}}> <div><Spin dot /></div></Grid.Row>
    }
    return (
      <Form layout="horizontal" onSubmit={(values) => {this.handleSubmit(values)}}>
        <Form.Item label='Email' field='username' rules={[{ required: true }]}>
          <Input type="email" placeholder="Please enter your email" onChange={(text) => {this.setState({email: text});}}/>
        </Form.Item>
        <Form.Item label='Password' field='password' rules={[{ required: true }]}>
          <Input type="password" placeholder="Please enter your password" onChange={(text) => {this.setState({password: text});}}/>
        </Form.Item>
        {/* <Form.Item>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item> */}
        <div align="right">
          <Form.Item style={{justifyContent: "end"}}><Button type='primary' htmlType='submit'>Submit</Button></Form.Item>
        </div>
      </Form>
    );
  }

  render() {
    return (
      <div>
        <Grid.Row style={{justifyContent: "center"}}>
          <Grid.Col span={12} style={{alignItems: "center"}}>
            <Card title="Login" style={{ margin: 20 }}>
              {this.renderForm()}
            </Card>
          </Grid.Col>
        </Grid.Row>
      </div>
    )
  }
}