

export function convertDateToString(date, delimiter="/") {
  return `${date.getFullYear()}${delimiter}${date.getMonth()}${delimiter}${date.getDate()}`;
}

export function convertDateToFilterString(date) {
  let calendarMonth = date.getMonth() + 1;
  let calendarDay = date.getDate();
  let monthString = calendarMonth < 10 ? `0${calendarMonth}` : calendarMonth.toString();
  let dayString = calendarDay < 10 ? `0${calendarDay}` : calendarDay.toString();
  return `${date.getFullYear()}-${monthString}-${dayString}`;
}