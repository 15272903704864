import React from 'react';
import { IconRight, IconDown, IconDragDotVertical } from '@arco-design/web-react/icon';
import { SortableElement, SortableContainer, SortableHandle } from 'react-sortable-hoc';
import { StandardTableOperations } from '../common/StandardTableOperations';
import { Table } from "@arco-design/web-react";


const arrayMoveMutate = (array, from, to) => {
  const startIndex = to < 0 ? array.length + to : to;

  if (startIndex >= 0 && startIndex < array.length) {
    const item = array.splice(from, 1)[0];
    array.splice(startIndex, 0, item);
  }
};


const arrayMove = (array, from, to) => {
  array = [...array];
  arrayMoveMutate(array, from, to);
  return array;
};


const DragHandle = SortableHandle(() => (
  <IconDragDotVertical
    style={{
      cursor: 'move',
      color: '#555',
    }}
  />
));


const SortableWrapper = SortableContainer((props) => {
  return <tbody {...props} />;
});


const SortableItem = SortableElement((props) => {
  return <tr {...props} />;
});






export function SortablePlannedSetTable(props) {

  let tableColumns = [
    {title: "Exercise", dataIndex: "exercise.name"},
    {title: "Weight", dataIndex: "weight", render: (_, record) => {
      if (!record.weight_range && !record.weight) {
        return "-";
      }
      if (record.weight_range) {
        return record.weight_range.name;
      }
      return `${record.weight}kg`;
      }
    },
    {title: "Reps", dataIndex: "reps", render: (_, record) => record.reps !== null ? record.reps : "-"},
    {title: "Duration", dataIndex: "duration", render: (_, record) => record.duration !== null ? `${record.duration}s` : "-"},
    {title: "Distance", dataIndex: "distance", render: (_, record) => record.distance !== null ? `${record.distance}m` : "-"},
    {title: "Operations", dataIndex: "op", align: "right", render: (_, record) => (
      <StandardTableOperations
        record={record}
        showAdd={record.hasOwnProperty("planned_session")}
        addLabel={"Add Superset"}
        editLabel="Edit Set"
        showEdit={true}
        deleteLabel="Delete Set"
        showDelete={true}
        showCopy={true}
        copyLabel="Duplicate Set"
        onAdd={(record) => {props.onAddPressed(record)}}
        onCopy={(record) => {props.onCopyPressed(record)}}
        onEdit={(record) => {props.onEditPressed(record)}}
        onDelete={(record) => {props.onDeletePressed(record)}
        }
      />
    )},
  ];


  function onSortEnd({ oldIndex, newIndex }) {
    
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(props.plannedSets), oldIndex, newIndex).filter((el) => !!el);
      props.onSort(newData);
    }
  }

  const DraggableContainer = (props) => (
    <SortableWrapper
      useDragHandle
      onSortEnd={onSortEnd}
      helperContainer={() => document.querySelector('.arco-drag-table-container-2 table tbody')}
      updateBeforeSortStart={({ node }) => {
        const tds = node.querySelectorAll('td');
        tds.forEach((td) => {
          td.style.width = td.clientWidth + 'px';
        });
      }}
      {...props}
    />
  );

  const DraggableRow = (props) => {
    const { record, index, ...rest } = props;
    return <SortableItem index={index} {...rest} />;
  };

  const components = {
    header: {
      operations: ({ selectionNode, expandNode }) => [
        {node: <th />, width: 40},
        {name: 'expandNode', node: expandNode},
        {name: 'selectionNode', node: selectionNode},
      ],
    },
    body: {
      operations: ({ selectionNode, expandNode }) => [
        {
          node: (
            <td>
              <div className='arco-table-cell'>
                <DragHandle />
              </div>
            </td>
          ),
          width: 40,
        },
        {name: 'expandNode', node: expandNode},
        {name: 'selectionNode', node: selectionNode},
      ],
      tbody: DraggableContainer,
      row: DraggableRow,
    },
  };
  return (
    <Table
      className='arco-drag-table-container-2'
      size="small"
      data={props.plannedSets}
      components={components}
      pagination={false}
      rowKey={(record) => {return record.uuid}}
      columns={tableColumns}
      expandedRowRender={(record) => <Table size="mini" columns={tableColumns} data={record.supersets} pagination={false} style={{marginLeft: 80}}/>}
      expandProps={{
        icon: ({ expanded, record, ...restProps }) => {
          if (record.supersets.length === 0) { return }
          return expanded ? ( <button {...restProps}><IconDown /></button> ) : (<button {...restProps}><IconRight /></button>);
        },
        rowExpandable: (record) => record.hasOwnProperty("supersets") && record.supersets.length > 0,
        columnTitle: "Supersets",
        width: 50
      }}
    />
  );
}
