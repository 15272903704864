import { apiGetRequest, apiPostRequest, makeGetRequest } from "./apiRequest";
import { USERS_URL, CLIENT_SLOTS_URL} from "./conf";


export async function listClients(params) {
  return apiGetRequest(CLIENT_SLOTS_URL, params);
}

export async function createClient(data) {
  return apiPostRequest(`${USERS_URL}create_client_slot/`, data);
}

export async function retrieveClient(uuid) {
  return await apiGetRequest(`${CLIENT_SLOTS_URL}${uuid}/`, null);
}

export async function getBodyCompositionData(uuid) {
  return await makeGetRequest(`${USERS_URL}${uuid}/bodycomposition/`, null);
}

export async function listPerformedExercises(uuid) {
  return await makeGetRequest(`${USERS_URL}${uuid}/performedexercises/`);
}

export async function getExercisePerformance(userUUID, exerciseUUID) {
  return await makeGetRequest(`${USERS_URL}${userUUID}/exerciseperformance/`, {exercise: exerciseUUID});
}

export async function listPlannedSessionNames(uuid) {
  return await makeGetRequest(`${USERS_URL}${uuid}/sessionnames/`);
}

// export async function listAllClients() {
//   let hasNextPage = true;
//   let currentPage = 1;
//   let allClients = [];
//   while (hasNextPage) {

//     listClients({page: currentPage}).then((response) => {
//       if (!response.ok) {
//         return;
//       }
//       return response.json();
//     }).then((json) => {
//       allClients.push(json.results);
//       if (json.next !== null) {
//         currentPage++;
//       } else {
//         hasNextPage = false;
//       }
//     })
//   }

//   return allClients;
// }


export async function retrieveBodyCompositionTimeSeries(uuid) {
  return apiGetRequest(`${USERS_URL}${uuid}/bodycomposition/`, null);
}
