import { apiDeleteRequest, apiGetRequest, apiPatchRequest, apiPostRequest } from "./apiRequest";
import { GOALS_URL } from "./conf";


export async function listGoals(params) {
  return apiGetRequest(GOALS_URL, params);
}

export async function retrieveGoalStateCounts(clientUUID) {
  return apiGetRequest(`${GOALS_URL}state_counts/`, {client_slot: clientUUID});
}

export async function createGoal(data) {
  return apiPostRequest(GOALS_URL, data);
}

export async function updateGoal(data, uuid) {
  return apiPatchRequest(`${GOALS_URL}${uuid}/`, data);
}

export async function deleteGoal(uuid) {
  return apiDeleteRequest(`${GOALS_URL}${uuid}/`);
}