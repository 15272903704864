import { Card, Grid, Image } from '@arco-design/web-react';
import React from 'react';
import { listProgressPictures } from '../../api/progressPictures';
import { DefaultPageLayout, LoadablePageContent } from '../../common/PageLayout';



export class ProgressPictureGalleryPage extends React.Component {
  constructor(props) {
    
    let uuid = window.location.pathname.split("/")[2];
    super(props);
    this.state = {
      clientUUID: uuid,
      progressPictures: [],
      loading: true,
      loadingError: false,
      currentPage: 1,
    };

    this.renderPageContent = this.renderPageContent.bind(this);
  }

  async componentDidMount() {
    let listPictureResponse = await listProgressPictures({page: this.state.currentPage});
    console.log(listPictureResponse);
    if (!listPictureResponse.successful) {
      this.setState({loading: false, loadingError: true});
    }
    this.setState({loading: false, loadingError: false, progressPictures: listPictureResponse.data.results});
  }


  renderPageContent() {

    let gallery = [];

    for (let i = 0; i < this.state.progressPictures.length; i++) {
      let progressPicture = this.state.progressPictures[i];
      gallery.push(
          <Grid.Col span={4}>
            <Card title={progressPicture.taken_at}>
            <Image
              // width={"100%"}
              // height={"100%"}
              src={`${process.env.REACT_APP_BASE_API_URL}${progressPicture.thumbnail.slice(1)}`}
              style={{width: "100%", height: "100%", cursor: "zoom-in"}}
            />
            {/* <p>{progressPicture.taken_at}</p> */}
            </Card>
          </Grid.Col>
      );
    }
    return <div>
      <Grid.Row>
        {gallery}
      </Grid.Row>
    </div>
  }

  render() {
    let pageContent = this.renderPageContent();
    return(
      <DefaultPageLayout pageHeader="Progress Pictures">
        <LoadablePageContent loading={this.state.loading} loadingError={this.state.loadingError}>
          {pageContent}
        </LoadablePageContent>
      </DefaultPageLayout>
    );
  }
}