import { BaseHelpPage } from "../../../common/PageLayout";
import { Link, Grid, Typography } from "@arco-design/web-react";
import { IconLink } from "@arco-design/web-react/icon";


export function ClientsHelpPage(props) {
  return (
    <BaseHelpPage>
      <Grid.Row>
        <Grid.Col span={24} style={{padding: 10}}>
          <Typography.Title>Clients Overview</Typography.Title>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={24} style={{padding: 10}}>
          <Typography.Text>If you're a personal trainer, you likely have people you're training. In Personal Gainer, these are called <span style={{fontWeight: "bold"}}>Clients</span>.</Typography.Text>
          <br/>
          <Typography.Text>Data for each client can be recorded including Plans, Workouts, Goals and Metrics.</Typography.Text>
          <Typography.Title heading={4}>Related Topics:</Typography.Title>
          <Typography.Paragraph>
            <Link href='/help/clients/viewing' icon={<IconLink />}>Viewing Your Clients</Link><br/>
            <Link href='/help/clients/add' icon={<IconLink />}>Adding a Client</Link><br/>
            <Link href='/help/clients/manage' icon={<IconLink />}>Managing a Client</Link><br/>
          </Typography.Paragraph>
        </Grid.Col>
      </Grid.Row>
    </BaseHelpPage>
  );
}