import { apiPostRequest } from "./apiRequest";
import { AUTH_URL, LOGOUT_URL } from "./conf";



export async function login(email, password) {
  console.log({username: email, password: password});
  return apiPostRequest(AUTH_URL, {username: email, password: password});
}


export async function logout() {
  return apiPostRequest(LOGOUT_URL, {});
}