import { makeDeleteRequest, makePatchRequest, makePostRequest } from "./apiRequest";
import { PLANNED_SUPERSET_URL } from "./conf";


export async function createPlannedSuperset(data) {
  return await makePostRequest(PLANNED_SUPERSET_URL, data);
}

export async function updatePlannedSuperset(uuid, data) {
  return await makePatchRequest(`${PLANNED_SUPERSET_URL}${uuid}/`, data);
}

export async function deletePlannedSuperset(uuid) {
  return await makeDeleteRequest(`${PLANNED_SUPERSET_URL}${uuid}/`);
}