import { apiDeleteRequest, apiGetRequest, apiPostRequest } from "./apiRequest";
import { BODYFAT_PERCENTAGES_URL } from "./conf";


export async function createBodyfatPercentage(data) {
  return await apiPostRequest(BODYFAT_PERCENTAGES_URL, data);
}

export async function deleteBodyfatPercentage(uuid) {
  return await apiDeleteRequest(`${BODYFAT_PERCENTAGES_URL}${uuid}`);
}

export async function listBodyfatPercentages(params) {
  return await apiGetRequest(BODYFAT_PERCENTAGES_URL, params);
}