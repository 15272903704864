import React from 'react';
import { Grid, Button, Typography } from "@arco-design/web-react";

export default function HomePage(props) {
  return(
    <div className="bg">
      <Grid.Row>
        <Grid.Col span={24} style={{justifyContent: "space-around", alignItems: "center", alignContent: "center", paddingLeft: 20}}>
          <Typography.Title style={{fontSize: "7vw", letterSpacing: "10px", whiteSpace: "pre-wrap", wordBreak: "break-word"}}>PERSONAL GAINER</Typography.Title>
          <Typography.Title heading={2} style={{fontSize: "3vw", whiteSpace: "pre-wrap", wordBreak: "break-word"}}>Trainer Harder. Train Smarter.</Typography.Title>
          <span style={{display: "inline"}}>
            <a href="/for-trainers"><Button type="outline" status="primary" shape="round" style={{display: "inline", marginRight: 50}}>For trainers</Button></a>
            <a href="/for-clients"><Button type="outline" status="primary" shape="round" style={{display: "inline"}}>For clients</Button></a>
          </span>
        </Grid.Col>
      </Grid.Row>
    </div>
  );

}
