import { Statistic } from "@arco-design/web-react";
import { IconArrowRise, IconArrowFall, IconMinus } from '@arco-design/web-react/icon';

const TREND_INCREASING = "UP";
const TREND_DECREASING = "DOWN";
const TREND_LEVEL = "LEVEL";

const TYPE_POSITIVE = "POSITIVE";
const TYPE_NEGATIVE = "NEGATIVE";
const TYPE_FLAT = "FLAT";

const TYPE_COLOR_MAP = {
  "POSITIVE": "#00B42A",
  "NEGATIVE": "#F53F3F",
  "FLAT": "#ababac",
};



export function TrendStatistic(props) {

  if (props.value === null) {
    return (
      <Statistic
        title={props.title}
        value="N/A"
        suffix="(No records found)"
        style={{ marginRight: 60, marginBottom: 20 }}
      />
    );
  }

  let trendDirection;
  if (props.difference > 0) {
    trendDirection = TREND_INCREASING;
  } else if (props.difference < 0) {
    trendDirection = TREND_DECREASING;
  } else {
    trendDirection = TREND_LEVEL;
  }

  const noGoal = props.goalValue === null || props.goalValue === undefined;
  let trendType;
  if (noGoal) {
    if (trendDirection === TREND_INCREASING) {
      trendType = TYPE_NEGATIVE;
    } else if (trendDirection === TREND_DECREASING) {
      trendType = TYPE_POSITIVE;
    } else {
      trendType = TYPE_FLAT;
    }
  } else {
    if (props.goalValue > props.value) {
      if (trendDirection === TREND_INCREASING) {
        trendType = TYPE_POSITIVE;
      } else if (trendDirection === TREND_DECREASING) {
        trendType = TYPE_NEGATIVE;
      } else {
        trendType = TYPE_FLAT;
      }
    } else if (props.goalValue < props.value) {
      if (trendDirection === TREND_INCREASING) {
        trendType = TYPE_NEGATIVE;
      } else if (trendDirection === TREND_DECREASING) {
        trendType = TYPE_POSITIVE;
      } else {
        trendType = TYPE_FLAT;
      }
    } else {
      if (props.difference === 0) {
        trendType = TYPE_FLAT;
      } else {
        trendType = TYPE_POSITIVE;
      }
    }
  }

  let trendColor = TYPE_COLOR_MAP[trendType];

  let icon;
  switch (trendDirection) {
    case TREND_INCREASING:
      icon = <IconArrowRise color={trendColor}/>;
      break;
    case TREND_DECREASING:
      icon = <IconArrowFall color={trendColor}/>;
      break;
    case TREND_LEVEL:
      icon = <IconMinus color={trendColor}/>;
      break;
    default:
      icon = <IconMinus color={trendColor}/>;
      break;
  }

  let suffixText;
  if (props.difference === null || props.difference === undefined) {
    suffixText = "";
  } else {
    suffixText = `${props.difference}${props.units}`;
  }
  let suffix = <span style={{color: trendColor}}>{icon}{suffixText}</span>

  return(
    <Statistic
      title={props.title}
      value={`${props.value}${props.units}`}
      suffix={suffix}
      style={{ marginRight: 60, marginBottom: 20 }}
    />
  );
}