import React from 'react';
import { Empty, Statistic, Button, Message, Table, Card, Grid, Descriptions } from "@arco-design/web-react";
import { retrievePlan } from '../../api/plans';
import { DefaultPageLayout, LoadablePageContent } from '../../common/PageLayout';
import { valueOrPlaceholder } from '../../utils/dataDisplay';
import { PerformedSessionModal} from '../modals/PerformedSessionModal';
import { NavigationButton } from '../common/Navigation';
import { PlanVolumeGraph } from '../graphs/PlanVolumeGraph';
import { PlannedSessionModal } from '../modals/PlannedSessionModal';
import { createPlannedSession } from '../../api/plannedSessions';
import { extractUUIDFromObjectList } from '../../utils/forms';
import { createPerformedSession } from '../../api/performedSessions';


export default class PlanDetailPage extends React.Component {
  constructor(props) {
    super(props);
    let uuid = window.location.pathname.split("/")[2];
    this.state = {
      loading: true,
      loadingError: false,
      loadingErrorMessage: null,
      planUUID: uuid,
      plan: null,
      goals: [],
      performedSessions: [],
      plannedSessions: [],
      adding: false,

      addPlannedSessionModalVisible: false,
      addPerformedSessionModalVisible: false,
      deleteSessionModalVisible: false,
    };

    this.handleAddPlannedSession = this.handleAddPlannedSession.bind(this);
    this.handleAddPerformedsession = this.handleAddPerformedsession.bind(this);
    
    this.getDescriptionData = this.getDescriptionData.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  async componentDidMount() {

    retrievePlan(this.state.planUUID).then((response) => {
      if (!response.ok) {
        this.setState({loading: false, loadingError: true});
      }
      return response.json();
    }).then((json) => {
      console.log(json);
      if (json.client_slot === null || json.client_slot === undefined) {
        this.setState({loading: false, loadingError: true, loadingErrorMessage: "No client set for plan."})
        return;
      }
      this.setState({
        loading: false,
        loadingError: false,
        plan: json,
        plannedSessions: json.planned_sessions,
        performedSessions: json.performed_sessions,
        goals: json.goals,
      });
    });

  }

  getDescriptionData() {
    if (this.state.plan === null) {
      return null;
    }

    let startDate = this.state.plan.start_date !== null ? new Date(this.state.plan.start_date).toUTCString() : null;
    let endDate = this.state.plan.end_date !== null ? new Date(this.state.plan.end_date).toUTCString() : null;
    let data = [
      {label: "Name", value: valueOrPlaceholder(this.state.plan.name, "N/A")},
      {label: "Client", value: valueOrPlaceholder(this.state.plan.client_slot.name, "N/A")},
      {label: "Notes", value: valueOrPlaceholder(this.state.plan.notes, "N/A")},
      {label: "Start Date", value: valueOrPlaceholder(startDate, "N/A")},
      {label: "End Date", value: valueOrPlaceholder(endDate, "N/A")},
    ];

    return data;
  }


  async handleAddPlannedSession(formData) {
    formData.client_slot = this.state.plan.client_slot.uuid;
    formData.plan = this.state.plan.uuid;
    this.setState({adding: true});
    createPlannedSession(formData).then((response) => {
      if (!response.ok) {
        Message.error("Could not add planned session.");
        this.setState({adding: false, addPlannedSessionModalVisible: false});
        return;
      }
      return response.json();
    }).then((json) => {
      Message.success("Session created successfully.");
      let sessions = structuredClone(this.state.plannedSessions);
      sessions.push(json);
      this.setState({addPlannedSessionModalVisible: false, plannedSessions: sessions, adding: false});
    });
  }


  async handleAddPerformedsession(formData) {
    formData.client_slot = this.state.plan.client_slot.uuid;
    formData.plan = this.state.plan.uuid;
    if (formData.planned_session !== null && formData.planned_session !== undefined) {
      if (formData.planned_session === "N/A") {
        formData.planned_session = null;
      } else {
        formData.planned_session = extractUUIDFromObjectList(formData.planned_session, this.state.plannedSessions, "name");
      }
    }

    console.log("FD", formData);

    this.setState({adding: true});
    createPerformedSession(formData).then((response) => {
      if (!response.ok) {
        Message.error("Could not add performed session.");
        this.setState({adding: false, addPerformedSessionModalVisible: false});
        return;
      }
      return response.json();
    }).then((json) => {
      Message.success("Performed session created successfully.");
      let performedSessions = structuredClone(this.state.performedSessions);
      performedSessions.push(json);
      console.log(json);
      this.setState({performedSessions: performedSessions, adding: false, addPerformedSessionModalVisible: false});
    });
  }

  renderContent() {
    return (
      <>
        <Grid.Row>
          <Grid.Col span={16}>
            <Card title="Overview" style={{margin: 10, marginRight: 5}}>
              <Descriptions colon=": " layout="inline-horizontal" data={this.getDescriptionData()} />
            </Card>
          </Grid.Col>
          <Grid.Col span={8}>
            <Card
              title="Planned Sessions"
              style={{margin: 10, marginLeft: 5}}
              extra={<Button type="outline" status="primary" size="mini" onClick={() => {this.setState({addPlannedSessionModalVisible: true})}}>Add</Button>}
              bodyStyle={{padding: 5}}
            >
              <Table
                data={this.state.plannedSessions}
                size="mini"
                pagination={false}
                hover={false}
                columns={[
                  {title: "Name", dataIndex: "name"},
                  {
                    title: "Operations",
                    align: "right",
                    dataIndex: "op",
                    render: (_, record) => <NavigationButton text="View" size="mini" status="primary" url="plannedsessions" uuid={record.uuid}/>,
                  }
                ]}
              />
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <Card title="Performance Overview" style={{marginLeft: 10, marginRight: 10, marginBottom: 10}}>
              <Grid.Row>
                <Grid.Col span={4}>
                  <Statistic
                    title="Sessions Completed"
                    value={this.state.plan.performed_sessions.length}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Statistic
                    title="PRs Recorded"
                    value={this.state.plan.pr_count}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Statistic
                    title="Goals Reached"
                    value={this.state.plan.goals.filter((x) => {return x.completed}).length}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Statistic
                    title="Total Volume"
                    value={this.state.plan.total_volume}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Statistic
                    title="Average Intensity"
                    value={this.state.plan.average_intensity === null ? "N/A" : this.state.plan.average_intensity}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Statistic
                    title="Average Session Duration"
                    value="-"
                  />
                </Grid.Col>
              </Grid.Row>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={24}>
            <Card title="Performance Graph" style={{marginRight: 10, marginLeft: 10, marginBottom: 10}}>
              {this.state.plan.performed_sessions.length > 0 ? <PlanVolumeGraph data={this.state.plan.performed_sessions} /> : <Empty description="No sessions performed."/>}
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={24}>
            <Card
              title="Performed Sessions"
              style={{marginRight: 10, marginLeft: 10, marginBottom: 10, minHeight: 400, maxHeight: 400, overflowY: "auto"}}
              extra={<Button type="outline" status="primary" size="mini" onClick={() => {this.setState({addPerformedSessionModalVisible: true})}}>Add</Button>}
              bodyStyle={{padding: 5}}
            >
              <Table
                size="mini"
                data={this.state.performedSessions}
                pagination={false}
                hover={true}
                noDataElement={<Empty description="No sessions performed."/>}
                columns={[
                  {title: "Name", dataIndex: "planned_session.name", render: (_, record) => record.planned_session === null ? "N/A" : record.planned_session.name},
                  {title: "Started At", dataIndex: "performed_at", render: (_, record) => {return new Date(record.performed_at).toUTCString()}},
                  {title: "Completed At", dataIndex: "completed_at"},
                  {title: "Volume", dataIndex: "volume", render: (_, record) => {return record.volume !== null && record.volume !== undefined ? parseFloat(record.volume.toFixed(4)): "-"}},
                  {title: "Intensity", dataIndex: "intensity", render: (_, record) => {return record.intensity !== null && record.intensity !== undefined ? parseFloat(record.intensity.toFixed(4)): "-"}},
                  {title: "Operations", align: "right", dataIndex: "op", render: (_, record) => <NavigationButton text="View" size="mini" status="primary" url="performedsessions" uuid={record.uuid}/>}
                ]}
              />
            </Card>
          </Grid.Col>
        </Grid.Row>
      </>
    );
  }

  render() {
    if (this.state.plan === null) {return;}
    let plannedSessionOptions = [];
    for (let i = 0; i < this.state.plannedSessions.length; i++) {
      const currentSession = this.state.plannedSessions[i];
      plannedSessionOptions.push(currentSession.name);
    }
    let pageContent = this.renderContent();
    return (
      <DefaultPageLayout pageHeader="Plan Details">
        <LoadablePageContent loading={this.state.loading} loadingError={this.state.loadingError} loadingErrorMessage={this.state.loadingErrorMessage}>
          <PerformedSessionModal
            title="Add Performed Session"
            visible={this.state.addPerformedSessionModalVisible}
            plannedSessionOptions={plannedSessionOptions}
            onCancel={() => {this.setState({addPerformedSessionModalVisible: false})}}
            handleSubmit={(formData) => {this.handleAddPerformedsession(formData)}}
            adding={this.state.adding}
          />
          <PlannedSessionModal
            title="Add Planned Session"
            visible={this.state.addPlannedSessionModalVisible}
            handleSubmit={(formData) => this.handleAddPlannedSession(formData)}
            onCancel={() => {this.setState({addPlannedSessionModalVisible: false})}}
            adding={this.state.adding}
          />
          {pageContent}
        </LoadablePageContent>
      </DefaultPageLayout>
    );
  }
}