import { apiGetRequest, apiPostRequest, makePatchRequest } from "./apiRequest";
import { PERFORMED_SESSION_URL } from "./conf";



export async function listPerformedSessions(params) {
  return apiGetRequest(PERFORMED_SESSION_URL, params);
}


export async function retrievePerformedSession(uuid) {
  return apiGetRequest(`${PERFORMED_SESSION_URL}${uuid}/`);
}


export async function updatePerformedSession(uuid, data) {
  return await makePatchRequest(`${PERFORMED_SESSION_URL}${uuid}/`, data);
}


export async function createPerformedSession(data) {
  return apiPostRequest(PERFORMED_SESSION_URL, data);
}