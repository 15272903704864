import { makeDeleteRequest, makeGetRequest, makePatchRequest, makePostRequest, apiPostRequest } from "./apiRequest";
import { PLANNED_SET_URL } from "./conf";


export async function retrievePlannedSet(uuid) {
  return await makeGetRequest(`${PLANNED_SET_URL}${uuid}/`, null);
}

export async function createPlannedSet(data) {
  return await makePostRequest(PLANNED_SET_URL, data);
}

export async function deletePlannedSet(uuid) {
  return await makeDeleteRequest(`${PLANNED_SET_URL}${uuid}/`);
}

export async function updatePlannedSet(uuid, data) {
  return await makePatchRequest(`${PLANNED_SET_URL}${uuid}/`, data);
}

export async function updateOrdering(orderingData) {
  return await apiPostRequest(`${PLANNED_SET_URL}set_order/`, orderingData);
}