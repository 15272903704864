import { BaseHelpPage } from "../../../common/PageLayout";
import { Link, Grid, Typography } from "@arco-design/web-react";
import { IconLink } from "@arco-design/web-react/icon";


export function ClientsViewHelpPage(props) {
  return (
    <BaseHelpPage>
      <Grid.Row>
        <Grid.Col span={24} style={{margin: 10}}>
          <Typography.Title>Viewing Clients</Typography.Title>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={24} style={{margin: 10}}>
          <Typography.Paragraph style={{whiteSpace: "pre-wrap", wordBreak: "break-word"}}>
            All of your clients can be viewed from the clients page in your dashboard. The view is split into two parts, an overview and a list view.
            The overview shows the total number of clients, the number of available slots from your subscription plan, and the number of clients that have linked their mobile app to your training plan.
            The list section displays basic information about each of your clients and provides a link to their detail page as well as the add button to set up more clients.

            <Typography.Paragraph blockquote style={{width: "75%", whiteSpace: "pre-wrap", wordBreak: "break-word", marginTop: 20, padding: 10, borderColor: "#5AAAFB"}}>
            It is not required that a client has the app for your to manage them via this platform. If this is the case, the only difference to your workflow is that you will have to enter their workout data instead of it being synced from their app.
            </Typography.Paragraph>
          </Typography.Paragraph>
        </Grid.Col>
        <Grid.Row>
        </Grid.Row>

        <Grid.Col span={24} style={{margin: 10}}>
          <Typography.Title heading={4}>Related Topics:</Typography.Title>
          <Typography.Paragraph>
            <Link href='#' icon={<IconLink />}>Viewing Your Clients</Link><br/>
            <Link href='#' icon={<IconLink />}>Creating a Plan</Link><br/>
            <Link href='#' icon={<IconLink />}>Billing</Link><br/>
          </Typography.Paragraph>
        </Grid.Col>
      </Grid.Row>
    </BaseHelpPage>
  );
}