import React from 'react';
import { Grid, Button, Form, Modal, Input, AutoComplete } from '@arco-design/web-react';


export function PerformedSetModal(props) {
  const [form] = Form.useForm();
  
  let instance = props.instance;
  let instanceExists = instance !== null && instance !== undefined;

  let initialValues = {
    exercise: null,
    reps: null,
    duration: null,
    distance: null,
    weight: null,
  }
  
  if (instanceExists) {
    initialValues.exercise = instance.exercise.name;
    initialValues.reps = instance.reps;
    initialValues.duration = instance.duration;
    initialValues.distance = instance.distance;
    initialValues.weight = instance.weight;
    form.setFieldsValue(initialValues);
  };

  return(
    <Modal title={props.title} visible={props.visible} onCancel={() => {props.handleCancel();}} footer={null}>
      <Form form={form} layout="horizontal" onSubmit={(formData) => {props.handleSubmit(formData);}}>
        <Form.Item label='Exercise' field='exercise' rules={[{required: true}]}>
          <AutoComplete
            placeholder='Start typing...'
            onSearch={() => {}}
            data={props.exerciseOptions}
          />
        </Form.Item>
        <Form.Item label='Reps' field='reps'>
          <Input type="number" placeholder="Reps" />
        </Form.Item>
        <Form.Item label='Duration (s)' field='duration'>
          <Input type="number" placeholder="Duration" />
        </Form.Item>
        <Form.Item label='Distance (s)' field='distance'>
          <Input type="number" placeholder="Distance" />
        </Form.Item>
        <Form.Item label='Weight (kg)' field='weight'>
          <Input type="number" placeholder="Weight" />
        </Form.Item>
        <Grid.Row>
          <Grid.Col span={20}>
            <Form.Item><Button type='secondary' onClick={() => {props.handleCancel();}}>Cancel</Button></Form.Item>
          </Grid.Col>
          <Grid.Col span={4}>
            <Form.Item><Button type='primary' htmlType='submit'>Submit</Button></Form.Item>
          </Grid.Col>
        </Grid.Row>
      </Form>
    </Modal>
  );
}