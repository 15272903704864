import { apiGetRequest, apiPostRequest, makeGetRequest, makePatchRequest } from "./apiRequest";
import { CREATE_TRAINER_URL, USERS_URL } from "./conf";


export async function getSessionsForMonth(uuid, month) {
  return await makeGetRequest(`${USERS_URL}${uuid}/sessionsformonth/`, {"month": month});
}

export async function createTrainer(data) {
  return apiPostRequest(CREATE_TRAINER_URL, data);
}


export async function updateUser(uuid, data) {
  return await makePatchRequest(`${USERS_URL}${uuid}/`, data);
}


export async function getCurrentUserDetails() {
  return apiGetRequest(`${USERS_URL}me/`);
}

export async function getPaymentDetails() {
  return apiGetRequest(`${USERS_URL}payment_method/`);
}

export async function getSubscriptionDetails() {
  return apiGetRequest(`${USERS_URL}subscription_details/`);
}

export async function getInvoices() {
  return apiGetRequest(`${USERS_URL}invoices/`);
}