import React from 'react';
import { Statistic, Card, Layout, Upload, Grid, Modal, Form, Table, Button, Pagination, Message, Spin, Result} from "@arco-design/web-react";

import { DefaultPageLayout, LoadablePageContent } from '../../common/PageLayout';
import { EXERCISE_TYPE_DISPLAY_TO_FLAG_MAP } from '../../utils/dataDisplay';
import { StandardTableOperations } from '../common/StandardTableOperations';
import { createExercise, listExercises, retrieveExerciseTypeCounts, updateExercise, uploadExerciseCSV } from '../../api/exercises';
import { ExerciseModal } from '../modals/ExerciseModal';
import { UploadModal } from '../modals/UploadModal';


export default class ExerciseListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingError: false,
      exercises: [],
      totalExercises: 0,
      selectedPage: 1,
      hasNextPage: false,
      hasPreviousPage: false,
      
      exerciseCounts: [],
      loadingCounts: true,
      loadingCountsError: false,
      
      addModalVisible: false,
      adding: false,
      editModalVisible: false,
      editing: false,
      selectedExercise: null,

      uploadModalVisible: false,
      uploadedFile: null,

    }
    
    this.loadExercises = this.loadExercises.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    
    this.handleAddExerciseConfirm = this.handleAddExerciseConfirm.bind(this);
    this.handleEditExerciseConfirm = this.handleEditExerciseConfirm.bind(this);
    
    this.handleUploadFormConfim = this.handleUploadFormConfim.bind(this);

    this.renderContent = this.renderContent.bind(this);
    this.renderUploadModal = this.renderUploadModal.bind(this);

    this.renderExerciseCounts = this.renderExerciseCounts.bind(this);

    this.regenerateCounts = this.regenerateCounts.bind(this)
  }

  regenerateCounts(initialType, newType) {
    let newCounts = [];
    if (initialType !== newType) {
      for (let i = 0; i < this.state.exerciseCounts.length; i++) {
        let currentCount = this.state.exerciseCounts[i];
        if (currentCount.exercise_type_value === initialType) {
          currentCount.count--;
        } else if (currentCount.exercise_type_value === newType) {
          currentCount.count++;
        }
        newCounts.push(currentCount)
      }
    } else {
      newCounts = this.state.exerciseCounts;
    }
    return newCounts;
  }

  async componentDidMount() {

    retrieveExerciseTypeCounts().then((response) => {
      if (!response.ok) {
        Message.error("Could not retrieve exercise counts.")
        return;
      }
      return response.json();
    }).then((json) => {
      this.setState({loadingCounts: false, loadingCountsError: false, exerciseCounts: json.counts});
    });

    listExercises({page: 1}).then((response) => {
      if (!response.ok) {
        Message.error("Could not load exercises");
        this.setState({loading: false, loadingError: true});
        return;
      }
      return response.json()
    }).then((json) => {
      this.setState({
        exercises: json.results,
        totalExercises: json.count,
        loading: false,
        loadingError: false,
        hasNextPage: json.next !== null,
        hasPreviousPage: json.previous !== null,
      });
    })

  }

  async handleEditExerciseConfirm(formData) {
    this.setState({editing: true});
    let typeValue = formData.exercise_type;
    formData.exercise_type = EXERCISE_TYPE_DISPLAY_TO_FLAG_MAP[typeValue];
    let initialExerciseType = this.state.selectedExercise.exercise_type;
    updateExercise(this.state.selectedExercise.uuid, formData).then((response) => {
      if (!response.ok) {
        Message.error("Could not update exercise");
        this.setState({selectedExercise: null, editing: false, editModalVisible: false});
      }
      return response.json();
    }).then((json) => {
      Message.success("Exercise updated successfully.");
      let exercises = structuredClone(this.state.exercises);
      let newList = [];
      for (let i = 0; i < exercises.length; i++) {
        let exercise = exercises[i];
        if (exercise.uuid === json.uuid) {
          newList.push(json);
        } else {
          newList.push(exercise);
        }
      }

      let newCounts = this.regenerateCounts(initialExerciseType, json.exercise_type);

      this.setState({editing: false, editModalVisible: false, exercises: newList, exerciseCounts: newCounts});
    });
  }

  async handleUploadFormConfim(formData) {
    let origin = formData.uploaded_file.originFile;
    formData.uploaded_file = origin;
    console.log(formData);
    let response = await uploadExerciseCSV(formData);
    console.log(response.data);
  }

  async loadExercises(page) {
    listExercises({page: page}).then((response) => {
      if (!response.ok) {
        Message.error("Could not load exercises");
        this.setState({loading: false, loadingError: true});
        return;
      }
      return response.json()
    }).then((json) => {
      this.setState({
        exercises: json.results,
        totalExercises: json.count,
        selectedPage: page,
        loading: false,
        loadingError: false,
        hasNextPage: json.next !== null,
        hasPreviousPage: json.previous !== null,
      });
    })

  }

  async handlePageChange(page) {
    await this.loadExercises(page);
  }

  async handleAddExerciseConfirm(formData) {
    this.setState({adding: true});
    let typeValue = formData.exercise_type;
    formData.exercise_type = EXERCISE_TYPE_DISPLAY_TO_FLAG_MAP[typeValue];
    createExercise(formData).then((response) => {
      
      if (!response.ok) {
        Message.error("Could not create exercise");
        this.setState({addModalVisible: false, adding: false});
        return;
      }
      return response.json();
    }).then((json) => {
      Message.success(`Exercise ${formData.name} added successfully.`);
      let exercises = structuredClone(this.state.exercises);
      exercises.push(json);

      let exerciseCounts = structuredClone(this.state.exerciseCounts);
      for (let i = 0; i < exerciseCounts.length; i++) {
        if (exerciseCounts[i].exercise_type_value === json.exercise_type) {
          exerciseCounts[i].count++;
        }
      }
      this.setState({
        adding: false,
        addModalVisible: false,
        exercises: exercises,
        exerciseCounts: exerciseCounts,
      });
    });
  }

  renderUploadModal() {
    if (this.state.loading) {
      return;
    }
    return (
      <Modal
        visible={this.state.uploadModalVisible} title="Upload Exercises" footer={null}
        onCancel={() => {this.hideUploadModal()}}
      >
        <Form onSubmit={(formData) => {this.handleUploadFormConfim(formData)}}>
          <Upload
            drag
            multiple={false}
            accept='.csv'
            onChange={(e) => {
              this.setState({uploadedFile: e.originFile});
            }}
            onDrop={(e) => {
              let uploadFile = e.dataTransfer.files[0]
              this.setState({uploadedFile: uploadFile});
            }}
            tip='Files must be of CSV format.'
          />
          <br/>
          <Grid.Row>
            <Grid.Col span={20}>
              <Form.Item><Button type='secondary' onClick={this.hideUploadModal}>Cancel</Button></Form.Item>
            </Grid.Col>
            <Grid.Col span={4}>
              <Form.Item><Button type='primary' htmlType='submit'>Submit</Button></Form.Item>
            </Grid.Col>
          </Grid.Row>
        </Form>
      </Modal>
    );
  }


  renderExerciseCounts() {
    if (this.state.loadingCounts) {
      return <Grid.Row style={{justifyContent: "center"}}><Spin dot /></Grid.Row>
    }

    if (this.state.loadingCountsError) {
      return (
        <Result
          status='error'
          title='Error'
          subTitle='Something went wrong. Please try again later.'
        />
      );
    }

    let countStats = [];
    for (let i = 0; i < this.state.exerciseCounts.length; i++) {
      let exerciseCount = this.state.exerciseCounts[i]
      countStats.push(
        <Grid.Col span={4}>
          <Statistic
            title={exerciseCount.exercise_type}
            value={exerciseCount.count}
          />
        </Grid.Col>        
      );
    }

    return (
      <Grid.Row>{countStats}</Grid.Row>
    )
  }


  renderContent() {

    return (
      <Layout.Content>
        <Grid.Row>
          <Grid.Col span={24}>
            <Card title="Exercise Type Counts" style={{margin: 10}}>
              {this.renderExerciseCounts()}
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={24}>
            <Card
              title="Exercise List"
              extra={
                <>
                <Button type="outline" status="primary" size="mini" style={{marginRight: 10}} onClick={() => {this.setState({addModalVisible: true})}}>Add</Button>
                <Button type="outline" status="primary" size="mini" onClick={() => {this.setState({uploadModalVisible: true})}}>Upload</Button>
                </>
              }
              style={{margin: 10, marginTop: 0}}
              bodyStyle={{padding: 5}}
            >
              <Table
                size="small"
                rowKey={(record) => {return record.uuid}}
                data={this.state.exercises}
                columns={[
                  {title: "name", dataIndex: "name"},
                  {title: "Type", dataIndex: "exercise_type_display"},
                  {
                    title: "Operations",
                    align: "right",
                    dataIndex: "op",
                    render: (_, record) => (
                      <StandardTableOperations
                        record={record}
                        showEdit={true}
                        editLabel="Edit exercise"
                        onEdit={(record) => {this.setState({selectedExercise: record, editModalVisible: true})}}
                      />
                    )
                  }
                ]}
                pagination={false}
              />
              <Grid.Row>
                <Grid.Col span={24} style={{marginTop: 10, marginBottom: 10}}>
                  <Pagination
                    simple={true}
                    total={this.state.totalExercises}
                    pageSize={20}
                    current={this.state.selectedPage}
                    onChange={(page) => {this.handlePageChange(page)}}
                    style={{float: 'right'}}
                  />
                </Grid.Col>
              </Grid.Row>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Layout.Content>
    );
  }

  render() {
    let pageContent = this.renderContent();
    return (
      <DefaultPageLayout pageHeader="Exercises">
        <ExerciseModal
          title="Add Exercise"
          visible={this.state.addModalVisible}
          onCancel={() => {this.setState({addModalVisible: false})}}
          handleSubmit={this.handleAddExerciseConfirm}
          adding={this.state.adding}
          exerciseTypeOptions={Object.keys(EXERCISE_TYPE_DISPLAY_TO_FLAG_MAP)}
        />
        <ExerciseModal
          title="Edit Exercise"
          visible={this.state.editModalVisible}
          onCancel={() => {this.setState({editModalVisible: false})}}
          handleSubmit={this.handleEditExerciseConfirm}
          adding={this.state.editing}
          instance={this.state.selectedExercise}
          exerciseTypeOptions={Object.keys(EXERCISE_TYPE_DISPLAY_TO_FLAG_MAP)}
        />
        <UploadModal
          title="Upload Exercises"
          visible={this.state.uploadModalVisible}
          onCancel={() => {this.setState({uploadModalVisible: false})}}
          handleSubmit={this.handleUploadFormConfim}
        />
        <LoadablePageContent loading={this.state.loading} loadingError={this.state.loadingError}>
          {pageContent}
        </LoadablePageContent>
      </DefaultPageLayout>
    );
  }
}