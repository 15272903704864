import { apiGetRequest, apiPostRequest } from "./apiRequest";
import { PLANS_URL } from "./conf";


export async function retrievePlan(uuid) {
  return apiGetRequest(`${PLANS_URL}${uuid}`, null);
}


export async function createPlan(data) {
  return apiPostRequest(PLANS_URL, data);
}

export async function listPlans(params) {
  return await apiGetRequest(PLANS_URL, params);
}


export async function retrievePlanStateCounts() {
  return await apiGetRequest(`${PLANS_URL}state_counts/`);
}
