import React from 'react';
import { Message, Card, Descriptions, Button, Pagination, Layout, Spin, Grid, Result, Table} from "@arco-design/web-react";
import { DefaultPageLayout, LoadablePageContent } from '../../common/PageLayout';
import { retrievePerformedSession, updatePerformedSession } from '../../api/performedSessions';
import { valueOrPlaceholder } from '../../utils/dataDisplay';
import { StandardTableOperations } from '../common/StandardTableOperations';
import { PerformedSetModal } from '../modals/PerformedSetModal';
import { listAllExercises } from '../../api/exercises';
import { cleanNonRequiredFields, extractUUIDFromObjectList } from '../../utils/forms';
import { createPerformedSet, deletePerformedSet, listPerformedSets, updatePerformedSet } from '../../api/performedSets';
import { DeleteModal, FileUploadModal } from '../modals/common';
import { PerformedSessionModal } from '../modals/PerformedSessionModal';
import { listAllPlannedSessions } from '../../api/plannedSessions';


export default class PerformedSessionDetailPage extends React.Component {
  constructor(props) {
    super(props);
    let uuid = window.location.pathname.split("/")[2];
    this.state = {
      loading: true,
      loadingError: false,
      sessionUUID: uuid,
      session: null,
      adding: false,

      loadingSets: true,
      loadingSetsError: false,
      performedSets: [],
      selectedSet: null,
      selectedPage: 1,
      totalSets: 0,

      exercises: [],
      exerciseNames: [],
      plannedSessions: [],
      plannedSessionNames: [],
      
      addSetModalVisible: false,
      editSetModalVisible: false,
      deleteSetModalVisible: false,

      editSessionModalVisible: false,
      deleteSessionModalVisible: false,

      uploadSetsModalVisible: false,
    };

    this.getDescriptionData = this.getDescriptionData.bind(this);
    this.renderModals = this.renderModals.bind(this);
    this.renderContent = this.renderContent.bind(this);

    // set actions
    this.handleAddSetSubmit = this.handleAddSetSubmit.bind(this);
    this.handleEditSetSubmit = this.handleEditSetSubmit.bind(this);
    this.confirmDeleteSet = this.confirmDeleteSet.bind(this);
    this.handleDuplicateSet = this.handleDuplicateSet.bind(this);
    this.handleSetsUpload = this.handleSetsUpload.bind(this);

    // session actions
    this.handleEditSessionSubmit = this.handleEditSessionSubmit.bind(this);

    this.handlePageChange = this.handlePageChange.bind(this);
  }


  async componentDidMount() {

    retrievePerformedSession(this.state.sessionUUID).then((response) => {
      if (!response.ok) {
        this.setState({loading: false, loadingError: true});
        return;
      }
      return response.json();
    }).then((json) => {
      this.setState({session: json, loading: false, loadingError: false});
      return json;
    }).then((sessionJson) => {
      // TODO: check client filter
      listAllPlannedSessions({client_slot: sessionJson.client_slot.uuid}).then((allSessionsData) => {
        let sessionDisplayNames = [];
        for (let i = 0; i < allSessionsData.length; i++) {
          let session = allSessionsData[i];
          sessionDisplayNames.push(`${session.plan_name}: ${session.name}`);
        }
        this.setState({plannedSessions: allSessionsData, plannedSessionNames: sessionDisplayNames});
      })
    });

    listPerformedSets({performed_session: this.state.sessionUUID, page: 1}).then((response) => {
      if (!response.ok) {
        Message.error("Could not load performed sets.")
        this.setState({loadingSets: false, loadingSetsError: true});
        return;
      }
      return response.json();
    }).then((json) => {
      this.setState({
        loadingSets: false,
        loadingSetsError: false,
        performedSets: json.results,
        selectedPage: 1,
        totalSets: json.count,
        hasNextPage: json.next !== null,
        hasPreviousPage: json.previous !== null,
      });
    });

    let exercises = await listAllExercises();
    let exerciseNames;
    if (exercises) {
      exerciseNames = exercises.map((exercise) => exercise.name);
    }

    this.setState({
      exercises: exercises,
      exerciseNames: exerciseNames,
    });

  }

  async loadSets(page) {
    this.setState({loadingSetsError: false, loadingSets: true});
    listPerformedSets({performed_session: this.state.sessionUUID, page: page}).then((response) => {
      if (!response.ok) {
        Message.error("Could not load performed sets.")
        this.setState({loadingSets: false, loadingSetsError: true});
        return;
      }
      return response.json();
    }).then((json) => {
      this.setState({
        loadingSets: false,
        loadingSetsError: false,
        performedSets: json.results,
        selectedPage: page,
        totalSets: json.count,
        hasNextPage: json.next !== null,
        hasPreviousPage: json.previous !== null,
      });
    });
  }

  async handleSetsUpload(uploadedFile) {
    console.log(uploadedFile);
  }

  async handlePageChange(page) {
    await this.loadSets(page);
  }

  async handleEditSessionSubmit(formData) {
    if (formData.planned_session) {
      let found;
      for (let i = 0; i < this.state.plannedSessions.length; i++) {
        let session = this.state.plannedSessions[i];
        if (formData.planned_session === `${session.plan_name}: ${session.name}`) {
          formData.planned_session = session.uuid;
          found = true;
          break;
        }
      }
      if (!found) {
        formData.planned_session = null;
      }

    }

    let response = await updatePerformedSession(this.state.session.uuid, formData);
    if (!response.successful) {
      Message.error("Could not update session.");
      return;
    }

    Message.success("Session updated successfully.");
    this.setState({session: response.data, editSessionModalVisible: false});

  }

  async handleAddSetSubmit(formData) {

    let postData = cleanNonRequiredFields(formData);
    let exerciseUUID = extractUUIDFromObjectList(postData.exercise, this.state.exercises, "name");
    postData.exercise = exerciseUUID;
    postData.performed_session = this.state.sessionUUID;

    createPerformedSet(postData).then((response) => {
      if (!response.ok) {
        Message.error("Could not create performed set.");
        this.setState({adding: false, addSetModalVisible: false})
        return;
      }
      return response.json();
    }).then((json) => {
      console.log(json);
      let performedSets = structuredClone(this.state.performedSets);
      performedSets.push(json);
      let session = structuredClone(this.state.session);
      session.volume = json.session_volume;
      session.intensity = json.session_intensity;
      console.log(session);
      // TODO fix volume/intensity update
      this.setState({adding: false, addSetModalVisible: false, performedSets: performedSets});
    });

  }

  async handleDuplicateSet(performedSet) {
    let postData = structuredClone(performedSet);
    delete postData.uuid;
    if (performedSet.exercise === null || performedSet.exercise === undefined) {
      Message.error("Could not duplicate set.");
      return;
    }
    postData.exercise = performedSet.exercise.uuid;
    
    let cleanedData = cleanNonRequiredFields(postData);
    createPerformedSet(cleanedData).then((response) => {
      if (!response.ok) {
        Message.error("Could not duplicate set.");
        this.setState({addSetModalVisible: false});
        return;
      }
      return response.json();
    }).then((json) => {
      Message.success("Duplicated set successfully.");
      let performedSets = structuredClone(this.state.performedSets);
      performedSets.push(json);
      let session = structuredClone(this.state.session);
      session.volume = json.session_volume;
      session.intensity = json.session_intensity;
      this.setState({adding: false, addSetModalVisible: false, performedSets: performedSets});
    });
  }

  async confirmDeleteSet() {

    deletePerformedSet(this.state.selectedSet.uuid).then((response) => {
      if (!response.ok) {
        Message.error("Could not delete set.");
        this.setState({deleteSetModalVisible: false});
        return;
      }
      Message.success("Set deleted successfully.");
      let sets = [];
      for (let i = 0; i < this.state.performedSets.length; i++) {
        let currentSet = this.state.performedSets[i];
        if (currentSet.uuid !== this.state.selectedSet.uuid) {
          sets.push(currentSet);
        }
      }
      this.setState({performedSets: sets, deleteSetModalVisible: false});
    });

  }

  async handleEditSetSubmit(formData) {
    let postData = cleanNonRequiredFields(formData);
    let exerciseUUID = extractUUIDFromObjectList(postData.exercise, this.state.exercises, "name");
    postData.exercise = exerciseUUID;
    postData.performed_session = this.state.sessionUUID;

    updatePerformedSet(this.state.selectedSet.uuid, postData).then((response) => {
      if (!response.ok) {
        Message.error("Could not update set.");
        this.setState({editSetModalVisible: false});
        return;
      }
      return response.json()
    }).then((json) => {
      let sets = [];
      for (let i = 0; i < this.state.performedSets.length; i++) {
        let currentSet = this.state.performedSets[i];
        if (currentSet.uuid === json.uuid) {
          sets.push(json);
        } else {
          sets.push(currentSet);
        }
      }
      let session = structuredClone(this.state.session);
      session.volume = json.session_volume;
      session.intensity = json.session_intensity;
      console.log(session);
      this.setState({editSetModalVisible: false, performedSets: sets, session: session});
    });

  }

  getDescriptionData() {
    if (this.state.session === null) {
      return null;
    }

    let sessionOptions = (
      <StandardTableOperations
        showAdd={false}
        editLabel="Edit Session"
        showEdit={true}
        deleteLabel="Delete Session"
        showDelete={true}
        showUpload={true}
        uploadLabel="Upload Sets"
        onEdit={() => {this.setState({editSessionModalVisible: true})}}
        onUpload={() => {this.setState({uploadSetsModalVisible: true})}}
      />
    );

    let name = 'N/A';
    if (this.state.session.planned_session) {
      name = `${this.state.session.planned_session.plan_name}: ${this.state.session.planned_session.name}`;
    }
    let volume = this.state.session.volume !== null ? parseFloat(this.state.session.volume.toFixed(4)) : null;
    let intensity = this.state.session.intensity !== null ? parseFloat(this.state.session.intensity.toFixed(4)) : null;

    return [
      {label: "Name", value: valueOrPlaceholder(name, "N/A")},
      {label: "Performed At", value: valueOrPlaceholder(this.state.session.performed_at, "N/A")},
      {label: "Completed At", value: valueOrPlaceholder(this.state.session.completed_at, "N/A")},
      {label: "Volume", value: valueOrPlaceholder(volume, "N/A")},
      {label: "Intensity", value: valueOrPlaceholder(intensity, "N/A")},
      {label: "Operations", value: sessionOptions},
    ];
  }

  renderContent() {
    if (this.state.loading) {
      return(
        <Grid.Row style={{justifyContent: "center"}}>
          <Spin dot style={{ marginTop: 100 }}/>
        </Grid.Row>
      )
    }
    if (this.state.loadingError) {
      return (
        <Result
          status='error'
          title='Error'
          subTitle='Something went wrong. Please try again later.'
        />
      );
    }

    return (
      <Layout.Content>
        <Grid.Row>
          <Grid.Col span={24} style={{padding: 10}}>
            <Card>
              <Descriptions title="Overview" colon=": " layout="inline-horizontal" data={this.getDescriptionData()} />
            </Card>
          </Grid.Col>
          <Grid.Col span={24} style={{padding: 10}}>
            <Card
              title="Performed Sets"
              bodyStyle={{padding: 5}}
              extra={<Button type="outline" status="primary" size="mini" onClick={() => {this.setState({addSetModalVisible: true})}}>Add</Button>}
            >
              <Table
                size="small"
                data={this.state.performedSets}
                pagination={false}
                rowKey={(record) => {return record.uuid}}
                columns={[
                  {title: "Exercise", dataIndex: "exercise.name"},
                  {title: "Weight", dataIndex: "weight", render: (_, record) => [null, undefined].includes(record.weight) ? "-" : `${record.weight}kg`},
                  {title: "Reps", dataIndex: "reps", render: (_, record) => [null, undefined].includes(record.reps) ? "-" : record.reps},
                  {title: "Duration", dataIndex: "duration", render: (_, record) => [null, undefined].includes(record.duration) ? "-" : `${record.duration}s`},
                  {title: "Distance", dataIndex: "distance", render: (_, record) => [null, undefined].includes(record.distance) ? "-" : `${record.distance}m`},
                  {title: "Volume", dataIndex: "volume", render: (_, record) => [null, undefined].includes(record.volume) ? "-" : record.volume},
                  {title: "Operations", align: "right", dataIndex: "op", render: (_, record) => (
                    <StandardTableOperations
                      record={record}
                      showAdd={false}
                      editLabel="Edit Set"
                      showEdit={true}
                      deleteLabel="Delete Set"
                      showDelete={true}
                      showCopy={true}
                      copyLabel="Duplicate Set"
                      onCopy={this.handleDuplicateSet}
                      onEdit={(record) => {this.setState({editSetModalVisible: true, selectedSet: record})}}
                      onDelete={(record) => {this.setState({deleteSetModalVisible: true, selectedSet: record})}}
                    />
                  )},
                ]}
              />
              <Grid.Row>
                <Grid.Col span={24} style={{marginTop: 10, marginBottom: 10}}>
                  <Pagination
                    simple={true}
                    total={this.state.totalSets}
                    pageSize={20}
                    current={this.state.selectedPage}
                    onChange={(page) => {this.handlePageChange(page)}}
                    style={{float: 'right'}}
                  />
                </Grid.Col>
              </Grid.Row>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Layout.Content>
    );
  }

  renderModals() {

    return (
      <>
        {/* set modals */}
        <PerformedSetModal
          title="Add Set"
          visible={this.state.addSetModalVisible}
          handleCancel={() => {this.setState({addSetModalVisible: false})}}
          exerciseOptions={this.state.exerciseNames}
          handleSubmit={this.handleAddSetSubmit}
        />
        <PerformedSetModal
          instance={this.state.selectedSet}
          title="Edit Set"
          visible={this.state.editSetModalVisible}
          handleCancel={() => {this.setState({editSetModalVisible: false, selectedSet: null});}}
          exerciseOptions={this.state.exerciseNames}
          handleSubmit={this.handleEditSetSubmit}
        />
        <DeleteModal
          visible={this.state.deleteSetModalVisible}
          title="Delete Set"
          message="Are you sure? This cannot be undone."
          handleConfirm={() => {this.confirmDeleteSet()}}
          onCancel={() => {this.setState({deleteSetModalVisible: false})}}
        />
      
        {/* session modals */}
        <PerformedSessionModal
          title="Edit Session"
          visible={this.state.editSessionModalVisible}
          onCancel={() => {this.setState({editSessionModalVisible: false})}}
          instance={this.state.session}
          plannedSessionOptions={this.state.plannedSessionNames}
          handleSubmit={this.handleEditSessionSubmit}
        />

        {/* sets upload modal */}
        <FileUploadModal
          title="Upload Sets"
          visible={this.state.uploadSetsModalVisible}
          onUpload={this.handleSetsUpload}
          onCancel={() => {this.setState({uploadSetsModalVisible: false})}}
        />
      </>
    );
    
  }

  render() {

    let pageContent = this.renderContent();
    let modals = this.renderModals();

    return <DefaultPageLayout pageHeader="Clients: Performed Session">
      <LoadablePageContent loading={this.state.loading} loadingError={this.state.loadingError}>
        {modals}
        {pageContent}
      </LoadablePageContent>
    </DefaultPageLayout>
  }
}
