import { apiPostRequest, listAllObjects, makeDeleteRequest, makeGetRequest, makePatchRequest, makePostRequest } from "./apiRequest";
import { PLANNED_SESSION_URL } from "./conf";


export async function listPlannedSessionNames() {
  return await makeGetRequest(`${PLANNED_SESSION_URL}names/`);
}

export async function retrievePlannedSession(uuid) {
  return await makeGetRequest(`${PLANNED_SESSION_URL}${uuid}/`);
}

export async function createPlannedSessionOld(data) {
  return await makePostRequest(PLANNED_SESSION_URL, data);
}

export async function deletePlannedSession(uuid) {
  return await makeDeleteRequest(`${PLANNED_SESSION_URL}${uuid}/`);
}

export async function updatePlannedSession(uuid, data) {
  return await makePatchRequest(`${PLANNED_SESSION_URL}${uuid}/`, data);
}

export async function listAllPlannedSessions(params) {
  return listAllObjects(PLANNED_SESSION_URL, params);
}

export async function createPlannedSession(data) {
  return apiPostRequest(PLANNED_SESSION_URL, data);
}