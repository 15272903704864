import React from 'react';
import { Grid, Button, Form, Modal, Input, Select } from '@arco-design/web-react';



export function ExerciseModal(props) {

  const [form] = Form.useForm();

  let instance = props.instance;
  let instanceExists = instance !== null && instance !== undefined;

  let initialValues = {
    name: null,
    exercise_type: null,
  };

  if (instanceExists) {
    initialValues.name = instance.name;
    initialValues.exercise_type = instance.exercise_type_display;
    form.setFieldsValue(initialValues);
  }


  return (
    <Modal
      title={props.title}
      visible={props.visible}
      onCancel={() => {props.onCancel()}}
      footer={null}
    >
      <Form form={form} onSubmit={(formData) => {props.handleSubmit(formData)}}>
        <Form.Item label="Name" field="name" rules={[{required: true}]}>
          <Input placeholder="Please enter exercise name" />
        </Form.Item>
        <Form.Item label="Type" field="exercise_type" rules={[{required: true}]}>
          <Select options={props.exerciseTypeOptions}/>
        </Form.Item>
        <Grid.Row>
          <Grid.Col span={20}>
            <Form.Item><Button type='secondary' onClick={() => {props.onCancel()}}>Cancel</Button></Form.Item>
          </Grid.Col>
          <Grid.Col span={4}>
            <Form.Item><Button loading={props.adding} type='primary' htmlType='submit'>Submit</Button></Form.Item>
          </Grid.Col>
        </Grid.Row>
      </Form>
      
    </Modal>
  );
}