import React from 'react';
import { StandardTableOperations } from '../common/StandardTableOperations';
import { Message, Result, Button, Card, Grid, Descriptions, Empty } from "@arco-design/web-react";
import { DefaultPageLayout, LoadablePageContent } from '../../common/PageLayout';
import { DeleteModal } from '../modals/common';
import { updatePlannedSession, retrievePlannedSession } from '../../api/plannedSessions';
import { valueOrPlaceholder } from '../../utils/dataDisplay';
import { PlannedSetModal } from '../modals/PlannedSetModal';
import { listWeightRanges } from '../../api/weightRanges';
import { listAllExercises } from '../../api/exercises';
import { cleanNonRequiredFields, extractUUIDFromObjectList } from '../../utils/forms';
import { createPlannedSet, deletePlannedSet, updateOrdering, updatePlannedSet } from '../../api/plannedSets';
import { PlannedSessionModal } from '../modals/PlannedSessionModal';
import { createPlannedSuperset, updatePlannedSuperset, deletePlannedSuperset } from '../../api/plannedSupersets';
import { SortablePlannedSetTable } from '../../components/data/SessionTable';



export class PlannedSessionDetailPage extends React.Component {
  constructor(props) {
    let uuid = window.location.pathname.split("/")[2];
    super(props);
    this.state = {
      loading: true,
      loadingError: false,
      sessionUUID: uuid,
      session: null,
      plannedSets: [],
      exercises: [],
      weightRanges: [],
      exerciseNames: [],
      weightRangeNames: [],

      editSessionModalVisible: false,

      uploadPlannedSetsModalVisible: false,
      addPlannedSetModalVisible: false,
      editPlannedSetModalVisible: false,
      deletePlannedSetModalVisible: false,

      addSupersetModalVisible: false,
      editSupersetModalVisible: false,
      deleteSupersetModalVisible: false,

    };

    this.handleAddSetConfirm = this.handleAddSetConfirm.bind(this);
    this.handleEditSetConfirm = this.handleEditSetConfirm.bind(this);
    this.handleDuplicateSet = this.handleDuplicateSet.bind(this);
    this.confirmDeleteSet = this.confirmDeleteSet.bind(this);

    this.handleEditSessionConfirm = this.handleEditSessionConfirm.bind(this);

    this.handleAddSuperset = this.handleAddSuperset.bind(this);
    // this.handleEditSuperset = this.handleEditSuperset.bind(this);
    this.handleOrderChange = this.handleOrderChange.bind(this);

    this.getDescriptionData = this.getDescriptionData.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  
  async componentDidMount() {
    let response = await retrievePlannedSession(this.state.sessionUUID);
    if (!response.successful) {
      this.setState({loading: false, loadingError: true});
      return;
    }

    // TODO: load all exercises better
    let exercises = await listAllExercises();
    let exerciseNames = [];
    for (let i = 0; i < exercises.length; i++) {
      exerciseNames.push(exercises[i].name);
    }

    let weightRangeNames = [];
    let weightRanges = [];
    let weightRangesResponse = await listWeightRanges(null);
    if (weightRangesResponse.successful) {
      weightRanges = weightRangesResponse.data.results;
      for (let i = 0; i < weightRanges.length; i++) {
        weightRangeNames.push(weightRanges[i].name);
      }
    }

    response.data.planned_sets.sort((a, b) => a.order - b.order);

    this.setState({
      loading: false, 
      loadingError: false,
      session: response.data,
      plannedSets: response.data.planned_sets,
      exerciseNames: exerciseNames,
      weightRangeNames: weightRangeNames,
      exercises: exercises,
      weightRanges: weightRanges,
    });
  }

  async handleOrderChange(newData) {
    let orderingArray = [];
    for (let i = 0; i < newData.length; i++) {
      const element = newData[i];
      orderingArray.push({uuid: element.uuid, order: i + 1});
    }

    let response = await updateOrdering({planned_sets: orderingArray})
    if (response.status !== 200) {
      Message.error("Could not save new ordering");
    } else {
      let json = await response.json();
      json.sort((a, b) => a.order > b.order);
      this.setState({plannedSets: json});
    }
  }

  async handleAddSuperset(formData) {
    let postData = cleanNonRequiredFields(formData);
    if (postData.weight_range !== undefined && postData.weightRange !== null) {
      let weightRangeUUID = extractUUIDFromObjectList(formData.weight_range, this.state.weightRanges, "name");
      postData.weight_range = weightRangeUUID;
    }
    
    let exerciseUUID = extractUUIDFromObjectList(formData.exercise, this.state.exercises, "name");
    postData.exercise = exerciseUUID;
    postData.planned_set = this.state.selectedSet.uuid;

    let response = await createPlannedSuperset(postData);
    if (!response.successful) {
      Message.error("Could not create superset");
      this.setState({addSupersetModalVisible: false});
      return;
    }

    let superset = response.data;
    let sets = structuredClone(this.state.plannedSets);
    for (let i = 0; i < sets.length; i++) {
      let set = sets[i];
      if (set.uuid === superset.planned_set) {
        set.supersets.push(superset);
      }
    }
    this.setState({plannedSets: sets, addSupersetModalVisible: false});
  }

  async handleEditSessionConfirm(formData) {
    let response = await updatePlannedSession(this.state.sessionUUID, formData);
    if (!response.successful) {
      Message.error("Could not update session.")
      this.setState({editSessionModalVisible: false});
      return;
    }

    Message.success("Session updated successfully.");
    let session = structuredClone(this.state.session);

    session.name = formData.name;
    session.notes = formData.notes;
    this.setState({editSessionModalVisible: false, session: session});
  }

  async handleAddSetConfirm(formData) {
    let postData = cleanNonRequiredFields(formData);
    let exerciseUUID = extractUUIDFromObjectList(formData.exercise, this.state.exercises, "name");
    
    if (postData.weight_range) {
      let weightRangeUUID = extractUUIDFromObjectList(formData.weight_range, this.state.weightRanges, "name");
      postData.weight_range = weightRangeUUID;
    }
    postData.exercise = exerciseUUID;
    postData.planned_session = this.state.session.uuid;

    let response = await createPlannedSet(postData);
    if (!response.successful) {
      Message.error("Could not create new set.")
      this.setState({addPlannedSetModalVisible: false});
      return;
    }

    Message.success("New set created successfully.")
    let plannedSets = structuredClone(this.state.plannedSets);
    plannedSets.push(response.data);
    this.setState({addPlannedSetModalVisible: false, plannedSets: plannedSets});

  }

  async handleEditSetConfirm(formData) {
    let patchData = cleanNonRequiredFields(formData);
    let exerciseUUID = extractUUIDFromObjectList(formData.exercise, this.state.exercises, "name");
    
    if (patchData.weight_range) {
      let weightRangeUUID = extractUUIDFromObjectList(formData.weight_range, this.state.weightRanges, "name");
      patchData.weight_range = weightRangeUUID;
    }
    patchData.exercise = exerciseUUID;

    let response;
    if (this.state.selectedSet.hasOwnProperty("planned_set")) {
      patchData.planned_set = this.state.selectedSet.planned_set;
      response = await updatePlannedSuperset(this.state.selectedSet.uuid, patchData);
    } else {
      response = await updatePlannedSet(this.state.selectedSet.uuid, patchData);
    }
    if (!response.successful) {
      Message.error("Could not edit set.")
      this.setState({editPlannedSetModalVisible: false});
      return;
    }

    let newSets = [];
    for (let i = 0; i < this.state.plannedSets.length; i++) {
      let set = this.state.plannedSets[i];
      if (set.uuid === response.data.uuid) {
        newSets.push(response.data);
      } else {
        let supersets = [];
        for (let j = 0; j < set.supersets.length; j++) {
          if (set.supersets[j].uuid === response.data.uuid) {
            supersets.push(response.data);
          } else {
            supersets.push(set.supersets[j]);
          }
        }
        set.supersets = supersets;
        newSets.push(set);
      }
    }

    Message.success("Set updated successfully.");
    this.setState({plannedSets: newSets, editPlannedSetModalVisible: false, editSupersetModalVisible: false});

  }

  async handleDuplicateSet(plannedSet) {
    let postData = structuredClone(plannedSet);
    delete postData.uuid;
    if (plannedSet.exercise === null || plannedSet.exercise === undefined) {
      Message.error("Could not duplicate set.");
      return;
    }
    postData.exercise = plannedSet.exercise.uuid;
    postData.weight_range = plannedSet.weight_range !== null ? plannedSet.weight_range.uuid : null;
    console.log("pd", postData);
    let cleanedData = cleanNonRequiredFields(postData);
    let response = await createPlannedSet(cleanedData);
    console.log(response.data);
    if (!response.successful) {
      Message.error("Could not duplicate set.");
      return;
    }
    Message.success("Duplicated set successfully.");
    let plannedSets = structuredClone(this.state.plannedSets);
    let setData = response.data;
    setData.exerise = response.data.exercise.name;
    plannedSets.push(response.data);
    this.setState({plannedSets: plannedSets});
  }

  async confirmDeleteSet() {

    let isSuperset = this.state.selectedSet.hasOwnProperty("planned_set");
    let response;
    if (isSuperset) {
      response = await deletePlannedSuperset(this.state.selectedSet.uuid);
    } else {
      response = await deletePlannedSet(this.state.selectedSet.uuid);
    }
    if (!response.successful) {
      Message.error("Could not delete set.");
      this.setState({deletePlannedSetModalVisible: false});
      return;
    }
    Message.success("Set deleted successfully.");
    
    let newSets = [];
    for (let i = 0; i < this.state.plannedSets.length; i++) {
      let set = this.state.plannedSets[i];
      if (isSuperset) {
        let supersets = [];
        for (let j = 0; j < set.supersets.length; j++) {
          if (set.supersets[j].uuid !== this.state.selectedSet.uuid) {
            supersets.push(set.supersets[j]);
          }
        }
        newSets.push(set);
      } else {
        if (set.uuid !== this.state.selectedSet.uuid) {
          newSets.push(set);
        }
      }
    }
    this.setState({plannedSets: newSets, deletePlannedSetModalVisible: false});

  }

  getDescriptionData() {
    let sessionOptions = (
      <StandardTableOperations
        showAdd={false}
        editLabel="Edit Session"
        showEdit={true}
        deleteLabel="Delete Session"
        showDelete={true}
        showUpload={true}
        uploadLabel="Upload Sets"
        onEdit={() => {this.setState({editSessionModalVisible: true})}}
        onUpload={() => {this.setState({uploadSetsModalVisible: true})}}
      />
    );
    return [
      {label: "Name", value: valueOrPlaceholder(this.state.session.name)},
      {label: "Created At", value: valueOrPlaceholder(this.state.session.created_at)},
      {label: "Notes", value: valueOrPlaceholder(this.state.session.notes)},
      {label: "Operations", value: sessionOptions},
    ];
  }

  renderContent() {
    if (this.state.session === null) {
      return <Empty/>
    }

    return (
      <Grid.Row>
        <Grid.Col span={24} style={{padding: 10}}>
          <Card title="Overview">
            <Descriptions title="Overview" colon=": " layout="inline-horizontal" data={this.getDescriptionData()} />
          </Card>
        </Grid.Col>
        <Grid.Col span={24} style={{padding: 10}}>
          <Card
            title="Session"
            bodyStyle={{padding: 5}}
            extra={
              <Button type="outline" status="primary" size="mini" style={{marginRight: 10}} onClick={() => {this.setState({addPlannedSetModalVisible: true})}}>Add</Button>
            }
          >
            {
              this.state.exercises.length === 0 ? <Result title="No exercises on record" status="warning" extra={<Button href="/exercises" type='default'>Add Exercises</Button>}/> :
              <SortablePlannedSetTable
                plannedSets={this.state.plannedSets}
                onAddPressed={(record) => {this.setState({addSupersetModalVisible: true, selectedSet: record})}}
                onCopyPressed={(record) => {this.handleDuplicateSet(record)}}
                onEditPressed={(record) => {
                  if (record.hasOwnProperty("planned_set")) {
                    this.setState({editSupersetModalVisible: true, selectedSet: record});
                  } else {
                    this.setState({editPlannedSetModalVisible: true, selectedSet: record});
                  }
                }}
                onDeletePressed={(record) => {this.setState({deletePlannedSetModalVisible: true, selectedSet: record})}}
                onSort={(data) => {
                  this.handleOrderChange(data);
                }}
              />
            }
          </Card>
        
        </Grid.Col>
      </Grid.Row>
    );
  }

  render() {
    let pageContent = this.renderContent();
    return (
      <DefaultPageLayout pageHeader="Planned Session Details">
        <LoadablePageContent loading={this.state.loading} loadingError={this.state.loadingError}>
          <PlannedSessionModal
            title="Edit Session"
            visible={this.state.editSessionModalVisible}
            onCancel={() => {this.setState({editSessionModalVisible: false})}}
            instance={this.state.session}
            handleSubmit={this.handleEditSessionConfirm}
          />
          <PlannedSetModal
            title="Add Set"
            visible={this.state.addPlannedSetModalVisible}
            weightRangeOptions={this.state.weightRangeNames}
            exerciseOptions={this.state.exerciseNames}
            exercises={this.state.exercises}
            handleSubmit={this.handleAddSetConfirm}
            onCancel={() => {this.setState({addPlannedSetModalVisible: false})}}
          />
          <PlannedSetModal
            title="Edit Set"
            visible={this.state.editPlannedSetModalVisible}
            instance={this.state.selectedSet}
            weightRangeOptions={this.state.weightRangeNames}
            exerciseOptions={this.state.exerciseNames}
            exercises={this.state.exercises}
            handleSubmit={this.handleEditSetConfirm}
            onCancel={() => {this.setState({editPlannedSetModalVisible: false})}}
          />
          <PlannedSetModal
            title="Add Superset"
            visible={this.state.addSupersetModalVisible}
            onCancel={() => {this.setState({addSupersetModalVisible: false})}}
            weightRangeOptions={this.state.weightRangeNames}
            exerciseOptions={this.state.exerciseNames}
            exercises={this.state.exercises}
            handleSubmit={this.handleAddSuperset}
          />
          <PlannedSetModal
            title="Edit Superset"
            visible={this.state.editSupersetModalVisible}
            onCancel={() => {this.setState({editSupersetModalVisible: false})}}
            weightRangeOptions={this.state.weightRangeNames}
            exerciseOptions={this.state.exerciseNames}
            handleSubmit={this.handleEditSetConfirm}
            instance={this.state.selectedSet}
          />
          <DeleteModal
            visible={this.state.deletePlannedSetModalVisible}
            title="Delete Set"
            message="Are you sure? This cannot be undone."
            handleConfirm={() => {this.confirmDeleteSet()}}
            onCancel={() => {this.setState({deletePlannedSetModalVisible: false})}}
          />
      
          {pageContent}
        </LoadablePageContent>
      </DefaultPageLayout>
    );
  }
}