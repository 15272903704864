import React from 'react';
import { Grid, Result, Spin } from "@arco-design/web-react";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let bodyweight = null;
    let bodyfat = null;

    for (let i = 0; i < payload.length; i++) {
      let currentPayload = payload[i];
      if (currentPayload.dataKey === "bodyweight") {
        bodyweight = currentPayload.value;
        continue;
      }
      if (currentPayload.dataKey === "bodyfat") {
        bodyfat = currentPayload.value;
      }
    }
    let bodyweightDisplay = bodyweight !== null ? `${bodyweight}kg` : "N/A";
    let bodyfatDisplay = bodyfat !== null ? `${bodyfat}%` : "N/A";
    return (
      <div className="custom-tooltip" style={{backgroundColor: "#2e2e30", paddingLeft: 5, paddingRight: 5, color: "#dfdfdf", borderRadius: 5}}>
        <p>{payload[0].payload.recorded_at}</p>
        <p>Bodyweight: {bodyweightDisplay}</p>
        <p>Bodyfat: {bodyfatDisplay}</p>
      </div>
    );
  }
}


export function BodyCompositionGraph(props) {

  if (props.loading) {
    return (
      <Grid.Row style={{justifyContent: "center"}}>
        <Spin dot style={{ marginTop: 100 }}/>
      </Grid.Row>
    );
  }
  if (props.error) {
    return (
      <Result
        status='error'
        title='Error'
        subTitle='Something went wrong. Please try again later.'
      />
    );
  }
  console.log(props.data);
  return(
    <ResponsiveContainer width="100%" height={330}>
      <LineChart
        width={1000}
        height={300}
        data={props.data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke="#484849"/>
        <XAxis dataKey="date" />
        <YAxis
          yAxisId={1}
          orientation="left"
          domain={[0, 160]}
          label={{ value: 'Bodyweight (kg)', angle: -90, position: 'insideLeft', fill: "#F5319D"}}
        />
        <YAxis
          yAxisId={2}
          orientation="right"
          domain={[0, 100]}
          label={{ value: 'Bodyfat (%)', angle: -90, position: "insideRight", dy: -75, fill: "#3491FA"}}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line yAxisId={1} type="monotone" dataKey="bodyweight" stroke="#F5319D" />
        <Line yAxisId={2} type="monotone" dataKey="bodyfat" stroke="#3491FA" activeDot={{ r: 4 }} />
      </LineChart>
    </ResponsiveContainer>
  );
}
