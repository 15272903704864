import React from 'react';
import { IconInfoCircle } from '@arco-design/web-react/icon';
import { Tooltip, Image } from "@arco-design/web-react";


export function ProgressPicture(props) {
  
  const notes = (
    <div>
      <p><b>Client Notes:</b></p>
      <p>{props.progressPicture.client_notes}</p>
      <hr/>
      <p><b>Trainer Notes:</b></p>‰
      <p>{props.progressPicture.trainer_notes}</p>
    </div>
  );
  return(
    <Image
      width={"100%"}
      height={"100%"}
      src={`${process.env.REACT_APP_BASE_API_URL}${props.progressPicture.picture.slice(1)}`}
      style={{width: "100%", height: "100%", cursor: "zoom-in"}}
      previewProps={{
        actions: [
          {
            key: 'info',
            content: <IconInfoCircle />,
            name: 'Info',
            getContainer: (action) => { return <Tooltip content={notes}>{action}</Tooltip>; },
          },
        ]
      }}
    />
  );
}