import React from 'react';
import { Grid, Button, Upload, Modal, Typography } from '@arco-design/web-react';


export function DeleteModal(props) {
  return(
    <Modal title={<Typography.Title heading={4} type="error">{props.title}</Typography.Title>} visible={props.visible} onCancel={() => {props.onCancel();}} footer={null}>
      <Grid.Row>
        <Grid.Col span={24}>
          <Typography.Text type='warning'>{props.message}</Typography.Text>
        </Grid.Col>
      </Grid.Row>
      <br/>
      <Grid.Row>
        <Grid.Col span={20}>
          <Button type='secondary' onClick={() => {props.onCancel();}}>Cancel</Button>
        </Grid.Col>
        <Grid.Col span={4}>
          <Button type="secondary" status="warning" onClick={() => {props.handleConfirm();}}>Submit</Button>
        </Grid.Col>
      </Grid.Row>

    </Modal>
  );
}


export function FileUploadModal(props) {
  const uploadRef = React.useRef();
  const [fileList, setFileList] = React.useState([]);

  const onChange = (files) => {
    setFileList(files);
  };

  const onProgress = (file) => {
    setFileList((files) => {
      return files.map((x) => (x.uid === file.uid ? file : x));
    });
  };

  return (
    <Modal title={props.title} visible={props.visible} onCancel={() => {props.onCancel();}} footer={null}>
            <Grid.Row style={{marginTop: 20}}>
              <Grid.Col span={24}>
                    <Upload
                  autoUpload={false}
                  multiple={false}
                  action={props.uploadURL}
                  onDrop={(e) => {
                    let uploadFile = e.dataTransfer.files[0]
                    console.log("AAAA", uploadFile);
                    props.onUpload(uploadFile);
                  }}
                  tip={props.tip}
                  onChange={onChange}
                  onProgress={onProgress}
                  fileList={fileList}
                />
              </Grid.Col>
              <Grid.Col span={8}>
                <Button type='secondary' onClick={(e) => {e.stopPropagation(); props.onCancel();}}>Cancel</Button>
              </Grid.Col>
              <Grid.Col span={8}>
                <Button type='primary'>Select File</Button>
              </Grid.Col>
              <Grid.Col span={8}>
                <Button
                  type='primary'
                  onClick={(e, isFirst) => {
                    e.stopPropagation();
                    props.onUpload()
                    console.log("FL", fileList);
                    const file = fileList.filter((x) => x.status === "init")[0];
                    let blah = uploadRef.current;
                    console.log("BBB", file);
                    console.log(blah);
                    uploadRef.current.submit(file);
                    }
                  }
                >
                  Submit
                </Button>
              </Grid.Col>
            </Grid.Row>
    </Modal>
  );
}
