import { apiDeleteRequest, apiGetRequest, apiPatchRequest, apiPostRequest, makeGetRequest } from "./apiRequest";
import { PERFORMED_SETS_URL } from "./conf";



export async function listPerformedSets(params) {
  return apiGetRequest(PERFORMED_SETS_URL, params);
}

export async function retrievePerformedSet(uuid) {
  return await makeGetRequest(`${PERFORMED_SETS_URL}${uuid}/`);
}

export async function createPerformedSet(data) {
  return apiPostRequest(PERFORMED_SETS_URL, data);
}

export async function updatePerformedSet(uuid, data) {
  return apiPatchRequest(`${PERFORMED_SETS_URL}${uuid}/`, data);
}

export async function deletePerformedSet(uuid) {
  return await apiDeleteRequest(`${PERFORMED_SETS_URL}${uuid}/`);
}