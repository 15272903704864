import React from 'react';
import { Statistic, Message, Card, Button, Pagination, Layout, Spin, Grid, Result, Table} from "@arco-design/web-react";
import { DefaultPageLayout, LoadablePageContent } from '../../common/PageLayout';
import { NavigationButton } from '../common/Navigation';
import { createClient, listClients } from '../../api/clients';
import { ClientModal } from '../modals/ClientModal';
import { getCurrentUserDetails } from '../../api/users';


export default class ClientListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      loadingCounts: true,
      loadingCountsError: false,
      counts: null,

      hasNextPage: false,
      hasPreviousPage: false,
      adding: false,
      loading: true,
      loadingError: false,
      addClientModalVisible: false,
      totalClients: 0,
      selectedPage: 1,
      tableColumns: [
        {title: "Name", dataIndex: "name"},
        {title: "Email", dataIndex: "email"},
        {
          title: 'Operations',
          align: "right",
          dataIndex: 'op',
          render: (_, record) => (
            <NavigationButton text="View" size="mini" status="primary" url="clients" uuid={record.uuid} />
          ),
        },
      ]
    }
    this.renderContent = this.renderContent.bind(this);
    this.loadClients = this.loadClients.bind(this);
    this.loadCounts = this.loadCounts.bind(this);
    this.renderClientCounts = this.renderClientCounts.bind(this);
    this.handleAddClientConfirm = this.handleAddClientConfirm.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  async componentDidMount() {
    this.loadClients(1);
    this.loadCounts();
  }

  async loadCounts() {
    getCurrentUserDetails().then((response) => {
      if (!response.ok) {
        Message.error("Could not load overview.");
        this.setState({loadingCounts: false, loadingCountsError: true});
        return;
      }
      return response.json();
    }).then((json) => {
      console.log(json);
      this.setState({counts: json, loadingCounts: false, loadingCountsError: false});
      return;
    });
  }

  async loadClients(page) {

    listClients({page: page}).then((response) => {
      if (!response.ok) {
        this.setState({loadingError: true, loading: false});
        return;
      }
      return response.json();
    }).then((json) => {
      console.log(json.results);
      this.setState({
        clients: json.results,
        hasPreviousPage: json.previous !== null,
        hasNextPage: json.next !== null,
        totalClients: json.count,
        loading: false,
        loadingError: false,
        selectedPage: page,
      });
    });

  }

  async handlePageChange(page) {
    this.loadClients(page);
  }

  async handleAddClientConfirm(formData) {

    this.setState({adding: true});

    console.log(formData);

    createClient(formData).then((response) => {
      if (!response.ok) {
        Message.error("Could not create client");
        this.setState({adding: false});
        return;
      } else {
        return response.json();
      }
    }).then((json) => {
      let client = json;
      let clientList = structuredClone(this.state.clients);
      clientList.push(client);
      Message.success(`Client ${formData.first_name} ${formData.last_name} created successfully.`);
      let counts = this.state.counts;
      counts.remaining_clients--;
      counts.number_of_clients++;
      this.setState({clients: clientList, adding: false, addClientModalVisible: false, counts: counts});
    });

  }

  renderClientCounts() {
    if (this.state.loadingCounts) {
      return <Grid.Row style={{justifyContent: "center"}}><Spin dot /></Grid.Row>
    }
    if (this.state.loadingCountsError) {
      return (
        <Result
          status='error'
          title='Error'
          subTitle='Something went wrong. Please try again later.'
        />
      );
    }
    return (
      <Grid.Row>
        <Grid.Col span={8}>
          <Statistic
            title="Clients"
            value={this.state.counts.number_of_clients}
          />
        </Grid.Col>
        <Grid.Col span={8}>
          <Statistic
            title="Remaining Client Spaces"
            value={this.state.counts.remaining_clients}
            suffix={`of ${this.state.counts.max_clients}`}
          />
        </Grid.Col>
        <Grid.Col span={8}>
          <Statistic
            title="Clients on App"
            value={this.state.counts.clients_on_app}
          />
        </Grid.Col>
      </Grid.Row>
    );
  }

  renderContent() {
    if (this.state.loading || this.state.loadingCounts) {
      return(
        <Grid.Row style={{justifyContent: "center"}}>
          <Spin dot style={{ marginTop: 100 }}/>
        </Grid.Row>
      )
    }
    if (this.state.loadingError) {
      return (
        <Result
          status='error'
          title='Error'
          subTitle='Something went wrong. Please try again later.'
        />
      );
    }

    return (

      <Layout.Content>
        <Grid.Row>
          <Grid.Col span={24}>
            <Card title="Clients Overview" style={{margin: 10}}>
              {this.renderClientCounts()}
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={24}>
            <Card
              title="Client List"
              extra={
                <Button
                  disabled={this.state.counts.remaining_clients === 0}
                  type="outline"
                  status="primary"
                  size="mini"
                  onClick={() => {this.setState({addClientModalVisible: true})}}>
                    {this.state.counts.remaining_clients === 0 ? "Increase subscription to add more clients" : "Add"}
                  </Button>
                }
              style={{margin: 10, marginTop: 0}}
              bodyStyle={{padding: 5}}
            >
              <Table
                size="small"
                data={this.state.clients}
                pagination={false}
                columns={[
                  {title: "Name", dataIndex: "name"},
                  {title: "Email", dataIndex: "email"},
                  {title: "Current Plan", dataIndex: "current_plan.name"},
                  {title: "Client Linked", dataIndex: "accepted", render: (_, record) => (record.accepted ? "Yes" : "No")},
                  {
                    title: 'Operations',
                    align: "right",
                    dataIndex: 'op',
                    render: (_, record) => (
                      <NavigationButton text="View" size="mini" status="primary" url="clients" uuid={record.uuid} />
                    ),
                  },
                ]}
                rowKey={(record) => {return record.uuid}}
              />
              <Grid.Row>
                <Grid.Col span={24} style={{marginTop: 10, marginBottom: 10}}>
                  <Pagination
                    simple={true}
                    total={this.state.totalClients}
                    pageSize={20}
                    current={this.state.selectedPage}
                    onChange={(page) => {this.handlePageChange(page)}}
                    style={{float: 'right'}}
                  />
                </Grid.Col>
              </Grid.Row>
            </Card>
          </Grid.Col>
          
        </Grid.Row>
      </Layout.Content>
      
    );
  }

  render() {
    let pageContent = this.renderContent();
    return <DefaultPageLayout pageHeader="Clients" showHelp={true}>
      <LoadablePageContent loading={this.state.loading} loadingError={this.state.loadingError}>
        <ClientModal
          title="Add Client"
          visible={this.state.addClientModalVisible}
          handleSubmit={this.handleAddClientConfirm}
          adding={this.state.adding}
          onCancel={() => {this.setState({addClientModalVisible: false})}}
        />
        {pageContent}
      </LoadablePageContent>
    </DefaultPageLayout>
  }
}
