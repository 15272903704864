import { Button, Table, Result, Descriptions, Grid, Card, Message, Spin } from '@arco-design/web-react';
import { Navigate } from 'react-router-dom';
import React from 'react';
import { logout } from '../../api/auth';
import { getCurrentUserDetails, getInvoices, getPaymentDetails } from '../../api/users';
import { DefaultPageLayout, LoadablePageContent } from '../../common/PageLayout';



export default class AccountPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingError: false,

      loadingUserDetails: true,
      loadingUserDetailsError: true,
      userDetails: null,

      loadingPaymentDetails: true,
      loadingPaymentDetailsError: false,
      paymentDetails: null,

      loadingInvoices: true,
      invoicesError: false,
      invoices: null,

      logoutSuccess: false,

    };

    this.handleLogout = this.handleLogout.bind(this);
    this.renderUserDetails = this.renderUserDetails.bind(this);
    this.renderPaymentDetails = this.renderPaymentDetails.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  async componentDidMount() {
    getCurrentUserDetails().then((response) => {
      if (!response.ok) {
        Message.error("Could not retrieve user details.");
        this.setState({
          loadingUserDetails: false,
          loadingUserDetailsError: true,
        });
        return;
      }
      return response.json();
    }).then((json) => {
      this.setState({
        loadingUserDetails: false,
        loadingUserDetailsError: false,
        userDetails: json,
      });
    });

    getPaymentDetails().then((response) => {
      if (!response.ok) {
        Message.error("Could not retrieve payment details.");
        this.setState({
          loadingPaymentDetails: false,
          loadingPaymentDetailsError: true,
        });
        return;
      }
      return response.json();
    }).then((json) => {
      this.setState({
        loadingPaymentDetails: false,
        loadingPaymentDetailsError: false,
        paymentDetails: json,
      });
    });

    getInvoices().then((response) => {
      if (!response.ok) {
        this.setState({loadingInvoices: false, invoicesError: true});
        return;
      }
      return response.json();
    }).then((json) => {
      console.log(json.invoices);
      this.setState({loadingInvoices: false, invoicesError: false, invoices: json.invoices});
    });
  }


  handleLogout() {
    logout().then((response) => {
      if (!response.ok) {
        Message.error("Could not logout at this time.");
        return;
      }
      localStorage.removeItem("PG_API_TOKEN");
      this.setState({logoutSuccess: true});
    })
  }


  renderUserDetails() {
    if (this.state.loadingUserDetails) {
      return(
        <Grid.Row style={{justifyContent: "center"}}>
          <Spin dot style={{ marginTop: 100 }}/>
        </Grid.Row>
      )
    }
    if (this.state.loadingUserDetailsError) {
      return (
        <Result
          status='error'
          title='Error'
          subTitle='Something went wrong. Please try again later.'
        />
      );
    }

    return (
      <Descriptions
        colon=": "
        layout="inline-horizontal"
        data={[
          {label: "First Name", value: this.state.userDetails.first_name},
          {label: "Last Name", value: this.state.userDetails.last_name},
          {label: "Eamil", value: this.state.userDetails.email},
          {label: "Phone", value: this.state.userDetails.phone_number},
          {label: "DoB", value: this.state.userDetails.date_of_birth},
          {label: "Account Type", value: this.state.userDetails.user_type},
        ]}
      />
    );
  }

  renderPaymentDetails() {
    if (this.state.loadingPaymentDetails) {
      return(
        <Grid.Row style={{justifyContent: "center"}}>
          <Spin dot style={{ marginTop: 100 }}/>
        </Grid.Row>
      )
    }
    if (this.state.loadingPaymentDetailsError) {
      return (
        <Result
          status='error'
          title='Error'
          subTitle='Something went wrong. Please try again later.'
        />
      );
    }

    return (
      <Descriptions
        colon=": "
        layout="inline-horizontal"
        data={[
          {label: "Number", value: `***${this.state.paymentDetails.last4}`},
          {label: "Expiry Month", value: this.state.paymentDetails.exp_month},
          {label: "Expiry Year", value: this.state.paymentDetails.exp_year},
        ]}
      />
    );
  }

  renderContent() {
    return <>
      <Grid.Row>
        <Grid.Col span={12}>
          <Card
            title="User Details"
            style={{margin: 10, marginRight: 5}}
            extra={
              <>
                <Button type="primary" size="mini" style={{marginRight: 10}}>Edit</Button>
                <Button type="primary" size="mini" status="warning" onClick={() => {this.handleLogout()}}>Log Out</Button>
                <Button type="primary" size="mini" status="danger" onClick={() => {this.handleLogout()}} style={{marginLeft: 10}}>Delete Account</Button>
              </>
            }
          >
            {this.renderUserDetails()}
          </Card>
        </Grid.Col>
        <Grid.Col span={12}>
          <Card
            title="Payment Details"
            style={{margin: 10, marginLeft: 5}}
            extra={<Button disabled={this.state.userDetails !== null && this.state.userDetails.is_beta_user} type="primary" size="mini">Edit</Button>}
          >
            {this.renderPaymentDetails()}
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={24}>
          <Card
            title="Payments"
            style={{margin: 10, marginTop: 0}}
            bodyStyle={{padding: 5}}
            extra={<Button disabled={this.state.userDetails !== null && this.state.userDetails.is_beta_user} type="primary" status="danger" size="mini">Cancel Subscription</Button>}
          >
          <Grid.Row>
            <Grid.Col span={16}>
              <Table
                loading={this.state.loadingInvoices}
                size="small"
                data={this.state.invoices}
                pagination={false}
                columns={[
                  {title: "Created", dataIndex: "created_at", render: (_, record) => [null, undefined].includes(record.created_at) ? "-" : new Date(record.created_at * 1000).toLocaleDateString()},
                  {title: "Paid At", dataIndex: "paid_at", render: (_, record) => [null, undefined].includes(record.paid_at) ? "-" : new Date(record.paid_at * 1000).toLocaleDateString()},
                  {title: "Finalized At", dataIndex: "finalized_at", render: (_, record) => [null, undefined].includes(record.finalized_at) ? "-" : new Date(record.finalized_at * 1000).toLocaleDateString()},
                  {title: "Amount Due", dataIndex: "amount_due", render: (_, record) => `£${record.amount_due / 100}`},
                  {title: "Amount Paid", dataIndex: "amount_paid", render: (_, record) => `£${record.amount_paid / 100}`},
                  {title: "Status", dataIndex: "status", render: (_, record) => {
                    switch(record.status) {
                      case "draft":
                        return <Button size="mini" status="default">Draft</Button>
                      case "open":
                        return <Button size="mini" type="outline" status="warning">Pending</Button>
                      case "paid":
                        return <Button size="mini" type="outline" status="success">Paid</Button>
                      case "uncollectible":
                        return <Button size="mini" type="outline" status="warning">Uncollectible</Button>
                      case "void":
                        return <Button size="mini" status="default">Draft</Button>
                      default:
                        return "-"
                    }
                  }},
                ]}
                rowKey={(record) => {return record.created}}
              />
            </Grid.Col>
            <Grid.Col span={8} style={{paddingLeft: 10}}>
              <Descriptions
                colon=": "
                layout="horizontal"
                data={[
                  {label: "Client Slots", value: "-"},
                  {label: "Extra Plans", value: "-"},
                ]}
              />
              <Grid.Row>
                <Grid.Col span={12}>
                  <Button size="small" type="primary">Edit Subscription</Button>
                </Grid.Col>
                <Grid.Col span={12}>
                  <Button>Purchase Extra Plans</Button>
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
          </Card>
        </Grid.Col>
      </Grid.Row>
    </>
  }

  render() {
    if (this.state.logoutSuccess) {
      return <Navigate to="/login" replace={true} />
    }
    let pageContent = this.renderContent();
    return <DefaultPageLayout pageHeader="Account">
      <LoadablePageContent loading={this.state.loading} loadingError={this.state.loadingError}>
        {pageContent}
      </LoadablePageContent>
    </DefaultPageLayout>
  }
}