import { REQUEST_TYPES } from "./conf";
import { APIResponse } from "./response";


async function makeRequest(url, method, requestData, params) {
  let token = localStorage.getItem("PG_API_TOKEN");
  let response = null;
  let finalURL = new URL(url);
  if (method === REQUEST_TYPES.GET && params !== undefined && params !== null) {
    for (let x in params) {
      finalURL.searchParams.append(x, params[x]);
    }
  }


  let requestConf = {
    method: method,
    headers: {
      'Content-Type': method === REQUEST_TYPES.PUT ? 'multipart/form-data' : 'application/json',
      'Authorization': `Token ${token}`,
    },
  }

  if (method === REQUEST_TYPES.POST || method === REQUEST_TYPES.PATCH) {
    requestConf.body = JSON.stringify(requestData);
  } else if (requestData !== null && requestData !== undefined) {
    console.log("RD", requestData);
    let body = new FormData();
    for (let x in requestData) {
      body.append(x, requestData[x]);
    }
    requestConf.body = body;

    console.log(requestConf);

  }

  try {
    response = await fetch(finalURL, requestConf);
  } catch(error) {
    return new APIResponse(500, null);
  }

  if (response.status === 500) {
    return new APIResponse(500, null);
  }

  let responseData = null;
  if (response.status !== 404 && response.status !== 204) {
    responseData = await response.json();
  }

  return new APIResponse(response.status, responseData);
}

export async function makeGetRequest(url, params) {
  return await makeRequest(url, REQUEST_TYPES.GET, null, params);
}

export async function makePostRequest(url, data) {
  return await makeRequest(url, REQUEST_TYPES.POST, data, null);
}

export async function makePatchRequest(url, data) {
  return await makeRequest(url, REQUEST_TYPES.PATCH, data, null)
}

export async function makeDeleteRequest(url) {
  return await makeRequest(url, REQUEST_TYPES.DELETE, null, null);
}

export async function uploadFile(url, data) {
  return await makeRequest(url, REQUEST_TYPES.PUT, data, null);
}


export async function listAllObjects(url, params) {
  let page = 1;
  let error = false;
  let objects = [];
  let hasNexPage = true;

  while (hasNexPage) {
    params.page = page;
    let response = await makeGetRequest(url, params);
    if (!response.successful) {
      error = true;
      break;
    }
    const results = response.data.results;
    if (results) {
      objects.push(...results);
    }
    hasNexPage = response.data.next;
    page++;
  } 
  if (error) {
    return [];
  }
  return objects;
}



async function makeAPIRequest(url, method, requestData, params) {
  let token = localStorage.getItem("PG_API_TOKEN");
  let finalURL = new URL(url);
  if (method === REQUEST_TYPES.GET && params !== undefined && params !== null) {
    for (let x in params) {
      finalURL.searchParams.append(x, params[x]);
    }
  }

  let headers = {'Content-Type': 'application/json'};
  if (token) {
    headers["Authorization"] = `Token ${token}`;
  }
  
  let requestConf = {method: method, headers: headers};
  if (method === REQUEST_TYPES.POST || method === REQUEST_TYPES.PATCH) {
    requestConf.body = JSON.stringify(requestData);
  } else if (requestData !== null && requestData !== undefined) {
    let body = new FormData();
    for (let x in requestData) {
      body.append(x, requestData[x]);
    }
    requestConf.body = body;
  }

  return fetch(finalURL, requestConf);
 
}

export async function apiGetRequest(url, params) {
  return makeAPIRequest(url, REQUEST_TYPES.GET, null, params);
}

export async function apiPostRequest(url, data) {
  return makeAPIRequest(url, REQUEST_TYPES.POST, data);
}

export async function apiPatchRequest(url, data) {
  return makeAPIRequest(url, REQUEST_TYPES.PATCH, data);
}

export async function apiDeleteRequest(url) {
  return makeAPIRequest(url, REQUEST_TYPES.DELETE, null, null);
}