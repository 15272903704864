import React from 'react';
import { Card, Button, Pagination, Spin, Message, Result, Table, Grid } from "@arco-design/web-react";
import { DefaultPageLayout, LoadablePageContent } from '../../common/PageLayout';
import { createPerformedSession, listPerformedSessions } from '../../api/performedSessions';
import { PerformedSessionModal } from '../modals/PerformedSessionModal';
import { NavigationButton } from '../common/Navigation';


export default class ClientSessionListPage extends React.Component {
  constructor(props) {
    super(props);
    let uuid = window.location.pathname.split("/")[2];
    this.state = {
      clientUUID: uuid,
      loading: false,
      loadingError: false,
      sessions: [],
      plannedSessionNames: [],
      totalSessions: 0,
      addSessionModalVisible: false,
      selectedPage: 1,
    };

    this.loadSessions = this.loadSessions.bind(this);
    this.submitCreateSessionForm = this.submitCreateSessionForm.bind(this);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  async loadSessions(page) {
    listPerformedSessions({client_slot: this.state.clientUUID, page: page}).then((response) => {
      if (!response.ok) {
        let error = new Error("PAGE_LOAD_ERROR");
        throw error;
      }
      return response.json();
    }).then((json) => {
      this.setState({
        sessions: json.results,
        loading: false,
        loadingError: false,
        totalSessions: json.count,
        selectedPage: page,
      });
      return;
    }).catch((error) => {
      Message.error("Could not load results.");
      this.setState({loading: false, selectedPage: page});
    });
    
  }

  async handlePageChange(page) {
    this.loadSessions(page);
  }

  async componentDidMount() {
    await this.loadSessions(1);
  }

  async submitCreateSessionForm(formData) {
    formData.client = this.state.clientUUID;
    if (formData.planned_session) {
      for (let i = 0; i < this.state.plannedSessionNames.length; i++) {
        let currentPlannedSession = this.state.plannedSessionNames[i];
        if (`${currentPlannedSession.plan__name}: ${currentPlannedSession.name}` === formData.planned_session) {
          formData.planned_session = currentPlannedSession.uuid;
        }
      }
    }
    let response = await createPerformedSession(formData);
    if (!response.successful) {
      Message.error("Could not create new session");
      return;
    }
    Message.success("New session created successfully");
    let sessions = this.state.sessions;
    sessions.push(response.data);
    this.setState({sessions: sessions, addSessionModalVisible: false});
  }

  renderContent() {
    if (this.state.loading) {
      return(
        <Grid.Row style={{justifyContent: "center"}}>
          <Spin dot style={{ marginTop: 100 }}/>
        </Grid.Row>
      )
    }
    if (this.state.loadingError) {
      return (
        <Result
          status='error'
          title='Error'
          subTitle='Something went wrong. Please try again later.'
        />
      );
    }

    return (
      <Grid.Row>
        <Grid.Col span={24} style={{padding: 10}}>
          <Card
            title="Performed Sessions"
            bodyStyle={{padding: 5}}
            extra={
              <Button type="outline" status="primary" size="mini" onClick={() => {this.setState({addSessionModalVisible: true})}}>
                Add
              </Button>
            }
          >
            <Table
              size="mini"
              columns={[
                {
                  title: "Plan",
                  dataIndex: "planned_session.plan_name",
                  render: (_, record) => {
                    return record.planned_session === undefined ? <p>-</p> : <p>{record.planned_session.plan_name}</p>
                  }
                },
                {
                  title: "Session",
                  dataIndex: "planned_session.name",
                  render: (_, record) => {
                    return record.planned_session === undefined ? <p>Other</p> : <p>{record.planned_session.name}</p>
                  }
                },
                {title: "Date/Time", dataIndex: "performed_at"},
                {title: "Volume", dataIndex: "volume", render: (_, record) => (record.volume !== null ? parseFloat(record.volume).toFixed(2) : "-")},
                {title: "Intensity", dataIndex: "intensity", render: (_, record) => (record.intensity !== null ? parseFloat(record.intensity).toFixed(2) : "-")},
                {
                  title: "Operations",
                  align: "right",
                  dataIndex: "op",
                  render: (_, record) => (
                    <NavigationButton text="View" size="mini" status="primary" url="performedsessions" uuid={record.uuid} />
                  ),
                }
              ]}
              pagination={false}
              data={this.state.sessions}
              rowKey={(record) => {return record.uuid}}
            />
            <Grid.Row>
              <Grid.Col span={24} style={{marginTop: 10, marginBottom: 10}}>
                <Pagination
                  simple={true}
                  total={this.state.totalPlans}
                  pageSize={20}
                  current={this.state.selectedPage}
                  onChange={(page) => {this.handlePageChange(page)}}
                  style={{float: 'right'}}
                />
              </Grid.Col>
            </Grid.Row>
          </Card>
        </Grid.Col>
      </Grid.Row>

    );
  }

  render() {
    let plannedSessionOptions = [];
    for (let i = 0; i < this.state.plannedSessionNames.length; i++) {
      const currentSession = this.state.plannedSessionNames[i];
      plannedSessionOptions.push(`${currentSession.plan__name}: ${currentSession.name}`);
    }
    let pageContent = this.renderContent();
    return <DefaultPageLayout pageHeader="Sessions">
      <LoadablePageContent loading={this.state.loading} loadingError={this.state.loadingError}>
        <PerformedSessionModal
          title="Add Session"
          visible={this.state.addSessionModalVisible}
          plannedSessionOptions={plannedSessionOptions}
          onCancel={() => {this.setState({addSessionModalVisible: false});}}
          handleSubmit={this.submitCreateSessionForm}
        />
        {pageContent}
      </LoadablePageContent>
    </DefaultPageLayout>
  }
}